import { Warning, Check } from '@mui/icons-material';
import { IconButton, Icon, TextField, InputAdornment } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Text, Flex, Spinner, greyPalette } from '@lama/design-system';
import type { DocumentIssue, DocumentWithIssues } from '@lama/document-service-client';
import styled from 'styled-components';
import { useAsyncFn, useToggle } from 'react-use';
import { Tooltip } from '../Tooltip';
import { PaperClipIcon } from '../../icons/PaperClipIcon';
import { DocumentActions } from './DocumentActions';

export interface TurnedInDocumentProps {
  document: DocumentWithIssues;
  onDelete: (e: React.MouseEvent) => void;
  onOpen: (e: React.MouseEvent) => void;
  onDownloadDocument?: (e: React.MouseEvent) => void;
  onChangeDocumentName?: (name: string) => Promise<void>;
}

const StyledDocumentFileNameText = styled(Text)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DocumentIcon: FC<{ issues?: DocumentIssue[] }> = ({ issues }) => (
  <Flex alignItems={'center'} justifyContent={'center'} minWidth={'34px'} minHeight={'34px'} width={'34px'} height={'34px'}>
    {issues?.length ? (
      <Tooltip color={'warning'} title={issues[0]?.description} placement={'top'}>
        <Icon component={Warning} />
      </Tooltip>
    ) : (
      <PaperClipIcon />
    )}
  </Flex>
);

const DocumentDescription: FC<{ description: string }> = ({ description }) => (
  <Tooltip title={description} placement={'top'}>
    <Text variant={'body1'} weight={500} ellipsis>
      {description}
    </Text>
  </Tooltip>
);

const DocumentFileName: FC<{ filename: string; onChangeDocumentName?: (name: string) => Promise<void> }> = ({
  filename,
  onChangeDocumentName,
}) => {
  const [editingFileName, toggleEditingFileName] = useToggle(false);
  const [inputFileName, setInputFileName] = useState(filename);

  const onFileNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputFileName(e.target.value);
  }, []);

  // toggle and prevent click propagation
  const onDocumentNameClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      toggleEditingFileName();
    },
    [toggleEditingFileName],
  );

  const [{ loading: savingFileName }, saveFileName] = useAsyncFn(async () => {
    await onChangeDocumentName?.(inputFileName);
    toggleEditingFileName();
  }, [inputFileName, onChangeDocumentName, toggleEditingFileName]);

  const onFileNameInputClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  if (!onChangeDocumentName) {
    return (
      <Tooltip title={filename} placement={'top'}>
        <Text variant={'body1'} color={'disabled'} ellipsis>
          {filename}
        </Text>
      </Tooltip>
    );
  }

  return editingFileName ? (
    <TextField
      variant={'standard'}
      value={inputFileName}
      onChange={onFileNameChange}
      onClick={onFileNameInputClick}
      disabled={savingFileName}
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'}>
            <IconButton onClick={saveFileName} disableRipple>
              {savingFileName ? <Spinner size={'s'} /> : <Check sx={{ height: '20px' }} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  ) : (
    <StyledDocumentFileNameText variant={'body1'} color={greyPalette[500]} title={'Edit file name'} onClick={onDocumentNameClick} ellipsis>
      {filename}
    </StyledDocumentFileNameText>
  );
};

export const TurnedInDocument: FC<TurnedInDocumentProps> = ({ document, onDelete, onOpen, onDownloadDocument, onChangeDocumentName }) => {
  const { description, filename, issues } = document;

  return (
    <Flex gap={2} alignItems={'center'} width={'100%'} minWidth={0}>
      <DocumentIcon issues={issues} />
      <Flex flexDirection={'column'} minWidth={0}>
        {description ? <DocumentDescription description={description} /> : null}
        <DocumentFileName filename={filename} onChangeDocumentName={onChangeDocumentName} />
      </Flex>
      <Flex justifyContent={'flex-end'} flex={1}>
        <DocumentActions onDelete={onDelete} onOpen={onOpen} onDownloadDocument={onDownloadDocument} />
      </Flex>
    </Flex>
  );
};
