import { LinearProgress, useMediaQuery, useTheme } from '@mui/material';
import { Lock } from '@mui/icons-material';
import type { FC } from 'react';
import React from 'react';
import { Flex, Text } from '@lama/design-system';

export const InProgressDocument: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex gap={4} backgroundColor={'#f5f6f6'} p={4} alignItems={'center'} width={'100%'}>
      <Flex flex={1} alignItems={'center'} gap={4}>
        <Flex alignItems={'center'} justifyContent={'center'} minWidth={'34px'} minHeight={'34px'} width={'34px'} height={'34px'}>
          <Lock />
        </Flex>
        <Text variant={'body1'} weight={700}>
          {'Processing...'}
        </Text>
      </Flex>
      <Flex flex={1} flexDirection={'column'}>
        <LinearProgress sx={{ borderRadius: '8px' }} />
      </Flex>
      {!isMobile ? <Flex flex={1} /> : null}
    </Flex>
  );
};
