import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuthentication } from '../authentication/useAuthentication';
import { userServiceClient } from '../../shared/clients/userServiceClient';

export const useAddPersonToUserMutation = (userId?: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  const updateRequirementMutation = useMutation({
    mutationFn: async (personId: string) => {
      if (!isAuthenticated || !userId) {
        return null;
      }

      await userServiceClient.addPersonIdToUser(userId, personId, token);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['user'],
        }),
      ]);
    },
  });

  return updateRequirementMutation;
};
