/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { captureMessage } from '@sentry/react';
import { GenericPropertiesProvider, LoadingPage, BaseRelationsList } from '@lama/app-components';
import type { Entity } from '@lama/common-types';
import type { Relation } from '@lama/contracts';
import type { BusinessApiModel, PersonApiModel } from '@lama/clients';
import { useFlags } from 'launchdarkly-react-client-sdk';
import pluralize from 'pluralize';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';
import { useAddApplicationRelation } from '../../hooks/react-query/useAddApplicationRelation';
import { useRemoveApplicationRelation } from '../../hooks/react-query/useRemoveApplicationRelation';
import { useCreatePerson } from '../../hooks/react-query/useCreatePerson';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { useInviteToApplication } from '../../hooks/react-query/useInviteToApplication';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { customComponents } from '../shared/genericRequirements/customComponentsMap';
import type { ScreenProps } from '../ScreenProps';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useApplicationRequirementsQuery } from '../../hooks/react-query/useApplicationRequirementsQuery';
import { ErrorScreen } from '../errorScreen/ErrorScreen';
import { getPropertiesAsLarge } from '../shared/genericRequirements/genericFormUtils';
import { BasicScreen } from '../shared/BasicScreen';
import { useUpdateApplication } from '../../hooks/react-query/useUpdateApplication';
import { AdditionalEntitiesToRelationQuestion } from './AdditionalEntitiesToRelationQuestion/AdditionalEntitiesToRelationQuestion';

interface RelationsListProps extends ScreenProps {
  relation: Relation;
  allowedEntityTypes: Entity[];
  modifyPersonPayload?: (values: Partial<PersonApiModel>) => any;
  modifyBusinessPayload?: (values: Partial<BusinessApiModel>) => any;
  title?: string;
  showAdditionalEntitiesQuestion?: boolean;
  additionalEntitiesQuestion?: string;
  showMarkNoEntitiesCheckbox?: boolean;
}

const allowEntitesOverrideByProductName: Record<string, Entity[]> = {
  'consumer-term-loan': ['person'],
  'term-loan': ['business'],
};

export const RelationsList: FC<RelationsListProps> = ({
  flow,
  relation,
  allowedEntityTypes,
  modifyBusinessPayload,
  modifyPersonPayload,
  animate,
  onNextClick,
  onBackClick,
  nextEnabled,
  showAdditionalEntitiesQuestion,
  additionalEntitiesQuestion,
  showMarkNoEntitiesCheckbox,
  ...stepsNavigationProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const requirement = useGetCurrentRequirement();
  const { application, product } = useContext(ApplicationContext);
  const [relationHasAdditionalEntities, setRelationHasAdditionalEntities] = useState<boolean | null>(null);

  const { isFetching: fetchingRequirements } = useApplicationRequirementsQuery(application.id);
  const { mutateAsync: addApplicationRelation, isPending: addingApplicationRelation } = useAddApplicationRelation();
  const { mutateAsync: removeApplicationRelatedEntity, isPending: removingApplicationRelatedEntity } = useRemoveApplicationRelation();
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson({ applicationId: application.id });
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: inviteToApplication } = useInviteToApplication(application.id);
  const { mutateAsync: updateApplication } = useUpdateApplication(application.id);
  const { addExistingEntityEnabled, additionalEntitiesQuestionEnabled } = useFlags();
  const allowedEntityTypesForProduct = useMemo(
    () => allowEntitesOverrideByProductName[product.name] ?? allowedEntityTypes,
    [allowedEntityTypes, product.name],
  );
  const flowRequirement = useMemo(() => {
    if (!requirement) {
      return;
    }

    let { properties } = requirement;

    if (isMobile) {
      properties = getPropertiesAsLarge(properties);
    }

    return {
      ...requirement,
      properties,
    };
  }, [isMobile, requirement]);

  const loading = useMemo(
    () =>
      creatingBusiness ||
      updatingBusiness ||
      addingApplicationRelation ||
      removingApplicationRelatedEntity ||
      creatingPerson ||
      updatingPerson,
    [creatingBusiness, updatingBusiness, addingApplicationRelation, removingApplicationRelatedEntity, creatingPerson, updatingPerson],
  );

  const questionTitle = useMemo(
    () => additionalEntitiesQuestion ?? `Are there any additional ${pluralize(relation)} for this loan?`,
    [additionalEntitiesQuestion, relation],
  );

  const nextClick = useCallback(() => {
    onNextClick({ useOfFunds: application.useOfFunds });
  }, [application.useOfFunds, onNextClick]);

  const backClick = useCallback(() => {
    onBackClick?.({ useOfFunds: application.useOfFunds });
  }, [application.useOfFunds, onBackClick]);

  if (!flowRequirement) {
    if (fetchingRequirements) {
      return <LoadingPage />;
    }

    captureMessage(`RelationsList: requirement is undefined, in flow: ${flow}, application: ${application.id}`);
    return <ErrorScreen />;
  }

  if (flow === 'onboarding' && showAdditionalEntitiesQuestion && !relationHasAdditionalEntities) {
    return (
      <AdditionalEntitiesToRelationQuestion
        additionalEntitiesQuestion={questionTitle}
        application={application}
        relation={relation}
        updateApplication={updateApplication}
        onSelectedAnswer={setRelationHasAdditionalEntities}
        onNextClick={nextClick}
        onBackClick={backClick}
        flow={flow}
        title={flowRequirement.name}
        {...stepsNavigationProps}
      />
    );
  }

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      animate={animate ?? false}
      title={flowRequirement.name}
      subtitle={flowRequirement.description}
      onNextClick={nextClick}
      onBackClick={backClick}
      nextEnabled={nextEnabled}
      nextVisible={flow === 'onboarding'}
      nextLoading={updatingBusiness || updatingPerson}
    >
      <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={product.customOptionsLists ?? {}}>
        <BaseRelationsList
          relation={relation}
          requirementName={flowRequirement.name}
          allowedEntityTypes={allowedEntityTypesForProduct}
          createPerson={createPerson}
          updatePerson={updatePerson}
          createBusiness={createBusiness}
          updateBusiness={updateBusiness}
          inviteToApplication={inviteToApplication}
          addApplicationRelation={addApplicationRelation}
          removeApplicationRelatedEntity={removeApplicationRelatedEntity}
          application={application}
          requirementProperties={flowRequirement.properties}
          loading={loading}
          modifyBusinessPayload={modifyBusinessPayload}
          modifyPersonPayload={modifyPersonPayload}
          addExistingEntityEnabled={addExistingEntityEnabled}
          showAdditionalEntitiesQuestion={!!additionalEntitiesQuestionEnabled && showAdditionalEntitiesQuestion}
          showMarkNoEntitiesCheckbox={showMarkNoEntitiesCheckbox}
          additionalEntitiesQuestion={additionalEntitiesQuestion}
          updateApplication={updateApplication}
        />
      </GenericPropertiesProvider>
    </BasicScreen>
  );
};
