import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { getApplicationEntityByType, getSourcedProperty } from '@lama/properties';
import {
  ModifyItemButton,
  createUpdatePayload,
  getInitialFormValues,
  GenericAddOrEditDialog,
  getBusinessIdByEntity,
  ItemCard,
} from '@lama/app-components';
import { get, merge, isEmpty } from 'lodash-es';
import { useAsyncFn, useToggle } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import type { Entity } from '@lama/common-types';
import type { RequirementProperty } from '@lama/contracts';
import { Flex } from '@lama/design-system';
import { ApplicationContext } from '../../../shared/contexts/ApplicationContext';
import { useUpdateApplication } from '../../../hooks/react-query/useUpdateApplication';
import { useGetCurrentRequirement } from '../../../hooks/useGetCurrentRequirement';
import { useUpdateBusiness } from '../../../hooks/react-query/useUpdateBusiness';
import { useUpdatePerson } from '../../../hooks/react-query/useUpdatePerson';
import { CollateralEmptyState } from './listEmptyStates/CollateralEmptyState';
import { DebtEmptyState } from './listEmptyStates/DebtEmptyState';
import { UseOfFundsBreakdownEmptyState } from './listEmptyStates/UseOfFundsBreakdownEmptyState';
import { requirementToListItemComponent } from './requirementItemToComponent';

export const listRequirementToEmptyStateImage: Record<string, FC> = {
  collateral: () => <CollateralEmptyState />,
  debt: () => <DebtEmptyState />,
  useOfFundsBreakdown: () => <UseOfFundsBreakdownEmptyState />,
  otherIncomes: () => <UseOfFundsBreakdownEmptyState />,
  financialReferences: () => <DebtEmptyState />,
  transactionEstimates: () => <UseOfFundsBreakdownEmptyState />,
};

interface GenericListFormProps {
  fieldName: string;
  noItemsFieldName?: string;
  displayName: string;
  entityType: Entity;
  itemProperties: RequirementProperty[] | undefined;
}

export const GenericListForm: React.FC<GenericListFormProps> = ({
  fieldName,
  noItemsFieldName,
  displayName,
  entityType,
  itemProperties,
}) => {
  const { application } = useContext(ApplicationContext);
  const itemRender = useMemo(() => requirementToListItemComponent[fieldName], [fieldName]);

  const requirement = useGetCurrentRequirement();

  const requirementEntity = useMemo(() => {
    const entities = requirement ? getApplicationEntityByType(application, requirement.entityType, requirement.entityGroups) : [];
    return entities.find(({ id }) => id === requirement?.entityId);
  }, [application, requirement]);

  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplication(application.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);

  const listItems = useMemo<any[]>(() => get(requirementEntity, fieldName) ?? [], [requirementEntity, fieldName]);
  const EmptyState = useMemo(() => listRequirementToEmptyStateImage[fieldName], [fieldName]);
  const markedNoItems = useMemo(
    () => (noItemsFieldName ? Boolean(get(requirementEntity, noItemsFieldName)) : false),
    [requirementEntity, noItemsFieldName],
  );

  const [openDialog, toggleDialog] = useToggle(false);
  const [dialogInitialValues, setDialogInitialValues] = useState<Record<string, any> | null>();

  const onClose = useCallback(() => {
    setDialogInitialValues(null);
    toggleDialog();
  }, [toggleDialog]);

  const [, onSubmit] = useAsyncFn(
    async (values: any) => {
      if (!requirement) {
        return;
      }

      const updatedListItem = [...listItems];
      if (values.id) {
        const originalUpdatedItem = listItems.find((listItem: { id: string }) => listItem.id === values.id);
        updatedListItem[updatedListItem.findIndex((item: { id: any }) => item.id === values.id)] = merge({}, originalUpdatedItem, values);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        updatedListItem.push({ ...values, id: uuidv4() });
      }
      const payload = createUpdatePayload(application, entityType, '', { [fieldName]: updatedListItem });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, requirement.entityType, requirement.entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: requirement.entityId, updatePersonPayload: payload.updatePersonPayload });
      }

      onClose();
    },
    [requirement, listItems, application, entityType, fieldName, onClose, updateApplication, updateBusiness, updatePerson],
  );

  const [, onDelete] = useAsyncFn(
    async (id: string) => {
      if (!requirement) {
        return;
      }

      const updatedList = listItems.filter((listItem: { id: string }) => listItem.id !== id);
      const payload = createUpdatePayload(application, entityType, '', { [fieldName]: updatedList });

      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, requirement.entityType, requirement.entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: requirement.entityId, updatePersonPayload: payload.updatePersonPayload });
      }
    },
    [requirement, listItems, application, entityType, fieldName, updateApplication, updateBusiness, updatePerson],
  );

  const updateDialogInitialValues = useCallback(
    (listItem: any) => {
      const yearsBack = new Date().getUTCFullYear() - application.leadingReferenceYear;

      const propertiesWithDecidedSource = itemProperties?.map((p) => getSourcedProperty(p, listItem, yearsBack)) ?? [];
      const initialValues = getInitialFormValues(propertiesWithDecidedSource, listItem);
      setDialogInitialValues(initialValues);
      toggleDialog();
    },
    [application, itemProperties, toggleDialog],
  );

  const onClickEdit = useCallback(
    (id: string) => {
      const listItem = listItems.find((item: { id: string }) => item.id === id);
      updateDialogInitialValues(listItem);
    },
    [listItems, updateDialogInitialValues],
  );

  const onNoItemsClick = useCallback(async () => {
    if (!requirement) {
      return;
    }

    if (noItemsFieldName) {
      const payload = createUpdatePayload(application, entityType, '', { [noItemsFieldName]: !markedNoItems });
      if (!isEmpty(payload.updateApplicationPayload)) {
        await updateApplication({ updateApplicationPayload: payload.updateApplicationPayload });
      }

      if (!isEmpty(payload.updateBusinessPayload)) {
        const businessEntityId = getBusinessIdByEntity(application, requirement.entityType, requirement.entityId);

        if (businessEntityId) {
          await updateBusiness({ businessId: businessEntityId, updateBusinessPayload: payload.updateBusinessPayload });
        }
      }

      if (!isEmpty(payload.updatePersonPayload)) {
        await updatePerson({ personId: requirement.entityId, updatePersonPayload: payload.updatePersonPayload });
      }
    }
  }, [application, entityType, markedNoItems, noItemsFieldName, requirement, updateApplication, updateBusiness, updatePerson]);

  const onClickAddItem = useCallback(() => {
    updateDialogInitialValues({});
  }, [updateDialogInitialValues]);

  return (
    <Flex flexDirection={'column'} flex={1} alignItems={'center'} gap={8}>
      {listItems.length ? (
        listItems.map((item: { id: string }) => (
          <ItemCard key={item.id} onDelete={onDelete} onEdit={onClickEdit} item={item} itemRender={itemRender} />
        ))
      ) : EmptyState ? (
        <EmptyState />
      ) : null}
      {!listItems.length && noItemsFieldName ? (
        <FormControlLabel
          label={'No items to add'}
          control={<Checkbox checked={markedNoItems} onChange={onNoItemsClick} />}
          sx={{ marginRight: 0 }}
        />
      ) : null}
      <ModifyItemButton onClick={onClickAddItem} text={'Add Item'} disabled={markedNoItems} variant={'text'} />
      <GenericAddOrEditDialog
        entityType={entityType}
        open={openDialog}
        initialValues={dialogInitialValues ?? undefined}
        onClose={onClose}
        onSubmit={onSubmit}
        itemProperties={itemProperties}
        parentFieldName={fieldName}
        parentDisplayName={displayName}
        entity={requirementEntity}
        application={application}
        isLoading={updatingApplication || updatingBusiness || updatingPerson}
      />
    </Flex>
  );
};
