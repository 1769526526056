import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { localPartner } from '../framework/environment';
import { partnerServiceClient } from '../shared/clients/partnerService';
import { partnerState } from '../state/appState';

const getPartnerIdFromUrl = (location: Location) => {
  const [subdomain] = window.location.hostname.split('.');
  if (subdomain.includes('localhost')) {
    return localPartner as string;
  }

  if (location.hostname.includes('netlify')) {
    return 'testing';
  }

  if (subdomain.includes('application')) {
    return 'default';
  }

  return subdomain;
};

export const usePartnerFromSubdomain = () => {
  const [partner, setPartner] = useRecoilState(partnerState);

  useAsync(async () => {
    if (!partner) {
      const partnerId = getPartnerIdFromUrl(window.location);
      const partnerEntityFromBackend = await partnerServiceClient.getPartnerPublicData(partnerId);
      if (partnerEntityFromBackend) {
        setPartner(partnerEntityFromBackend);
      }
    }
  }, [partner, setPartner]);

  return partner;
};
