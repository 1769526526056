/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import React, { useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { ArbitraryDocumentUploadButton, LoadingPage } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import { useUploadDocumentMutation } from '../../../../hooks/react-query/useUploadDocumentMutation';
import { useDocumentsQuery } from '../../../../hooks/react-query/useDocumentsQuery';
import { isDocumentRelatedToRequirement } from '../../../../shared/utils/isDocumentRelatedToRequirement';
import { RequirementDocuments } from './RequirementDocuments';

interface DocumentRequirementProps {
  requirement: EvaluatedApplicationRequirement;
}

export const DocumentRequirement: React.FC<DocumentRequirementProps> = ({ requirement }) => {
  const { id, entityId, entityType, referenceRequirementId, key, applicationId } = requirement;
  const { mutateAsync: uploadDocument } = useUploadDocumentMutation(applicationId);
  const { data: documents, isPending: loadingDocuments } = useDocumentsQuery(applicationId);

  const requirementDocuments = useMemo(
    () => documents?.filter((document) => isDocumentRelatedToRequirement(document, requirement)) ?? [],
    [documents, requirement],
  );

  const [{ loading: isUploadingArbitraryDocument }, uploadArbitraryDocuments] = useAsyncFn(
    async (files: FileList) => {
      await Promise.all(
        [...files].map((file) =>
          uploadDocument({
            file,
            description: '',
            requirementId: id,
            sharedRequirementId: referenceRequirementId,
            requirementKey: key,
            applicationId,
            entityId,
            entityType,
            topic: '',
          }),
        ),
      );
    },
    [applicationId, entityId, entityType, id, key, referenceRequirementId, uploadDocument],
  );

  if (loadingDocuments) {
    return <LoadingPage />;
  }

  return (
    <Flex flexDirection={'column'} gap={4} width={'100%'}>
      <RequirementDocuments requirement={requirement} requirementDocuments={requirementDocuments} />
      <ArbitraryDocumentUploadButton onFilesSelected={uploadArbitraryDocuments} uploading={isUploadingArbitraryDocument} />
    </Flex>
  );
};
