import { getTextLightTheme } from '../components/Text/themes/light.theme';
import { getSpinnerLightTheme } from '../components/Spinner/themes/lightTheme.js';
import { getButtonLightTheme } from '../components/Button/themes/light.theme';
import { getInputLightTheme } from '../components/Input/themes/light.theme';
import { colors, getColorsLightTheme } from './colors.js';
import type { ColorOverrides } from './types';

export const getLightTheme = (overrides: ColorOverrides = colors) => ({
  name: 'light',
  version: 1,
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 68, 72, 76, 80, 84, 88, 92, 96, 100, 104, 108, 112, 116, 120],
  colors: getColorsLightTheme(overrides),
  shadows: {
    none: 'none',
    small: '0 2px 6px 0 rgba(204, 213, 217, 0.8)',
    large: '0 10px 10px rgba(159, 171, 188, 0.3), 0 5px 25px rgba(159, 171, 188, 0.3)',
  },
  fonts: {
    text: 'Ubuntu, sans-serif',
    code: 'Roboto Mono, monospace',
  },
  text: getTextLightTheme(overrides),
  spinner: getSpinnerLightTheme(overrides),
  button: getButtonLightTheme(overrides),
  input: getInputLightTheme(overrides),
});
