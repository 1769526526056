import type { FC } from 'react';
import React from 'react';

import type { StylingProps } from './Styles/Input.styles';
import { StyledInput, StyledNumberInput } from './Styles/Input.styles';

export const InputByType: FC<StylingProps> = ({ type, onValueChange, ...props }) => {
  if (type === 'number') {
    return <StyledNumberInput onValueChange={onValueChange} {...props} />;
  }
  return <StyledInput type={type} onValueChange={onValueChange} {...props} />;
};
