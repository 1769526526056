import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const InvoiceFinancingLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'74'} height={'106'} viewBox={'0 0 74 106'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        d={
          'M66.9179 102.249L56.6477 19.5951C56.3909 17.5348 54.5158 15.8652 52.4576 15.8652L7.20162 20.1736L6.469 16.5941L1.35415 21.1369C1.15298 21.2766 0.971326 21.4389 0.810689 21.6208C0.208677 22.297 -0.0990842 23.2377 0.0300256 24.2776L9.36496 102.249C9.62018 104.309 11.4968 105.979 13.555 105.979H63.6556C65.7139 105.979 67.1746 104.309 66.9179 102.249Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M58.6909 15.8672H8.5903C6.53205 15.8672 5.07131 17.5367 5.32803 19.597L15.5983 102.26C15.855 104.32 17.7301 106 19.7883 106H69.8889C71.9472 106 73.4079 104.32 73.1512 102.26L62.881 19.6015C62.6242 17.5412 60.7491 15.8672 58.6909 15.8672Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M29.1022 71.4554H21.6544C20.0871 71.4554 18.6594 70.2277 18.4642 68.7174L18.278 67.3604C18.0829 65.8502 19.1953 64.7051 20.7626 64.7051H28.2105C29.7778 64.7051 31.2055 65.8487 31.4007 67.3589L31.5868 68.758C31.782 70.2683 30.6696 71.4554 29.1022 71.4554Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M30.4114 81.6472H22.9635C21.3962 81.6472 19.9685 80.3969 19.7733 78.8867L19.5871 77.4741C19.392 75.9638 20.5044 74.7646 22.0718 74.7646H29.5196C31.0869 74.7646 32.5146 75.9518 32.7098 77.4621L32.896 78.9062C33.0911 80.4165 31.9787 81.6472 30.4114 81.6472Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M31.7205 91.8381H24.2726C22.7053 91.8381 21.2776 90.5667 21.0824 89.055L20.8963 87.5883C20.7011 86.078 21.8135 84.8232 23.3809 84.8232H30.8287C32.396 84.8232 33.8238 86.054 34.0189 87.5642L34.2051 89.052C34.4002 90.5622 33.2878 91.8381 31.7205 91.8381Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M44.8236 71.4552H37.3758C35.8084 71.4552 34.3807 70.2275 34.1856 68.7172L33.9994 67.228C33.8042 65.7178 34.9167 64.4404 36.484 64.4404H43.9318C45.4992 64.4404 46.9269 65.7163 47.1221 67.2265L47.3082 68.6917C47.5034 70.202 46.3909 71.4552 44.8236 71.4552Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M46.1327 81.7795H38.6849C37.1176 81.7795 35.6898 80.4631 35.4947 78.9528L35.3085 77.5072C35.1133 75.9969 36.2258 74.7646 37.7931 74.7646H45.241C46.8083 74.7646 48.236 75.9518 48.4312 77.4621L48.6173 78.9723C48.8125 80.4826 47.7001 81.7795 46.1327 81.7795Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M47.4418 91.8381H39.994C38.4267 91.8381 36.999 90.5667 36.8038 89.055L36.6176 87.5883C36.4225 86.078 37.5349 84.8232 39.1022 84.8232H46.5501C48.1174 84.8232 49.5451 86.054 49.7403 87.5642L49.9264 89.052C50.1216 90.5622 49.0092 91.8381 47.4418 91.8381Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M60.545 71.4554H53.0971C51.5298 71.4554 50.1021 70.2277 49.9069 68.7174L49.7208 67.3604C49.5256 65.8502 50.638 64.7051 52.2054 64.7051H59.6532C61.2205 64.7051 62.6483 65.8487 62.8434 67.3589L63.0296 68.758C63.2247 70.2683 62.1123 71.4554 60.545 71.4554Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M61.8541 81.8449H54.4062C52.8389 81.8449 51.4112 80.4954 51.216 78.9852L51.0299 77.5561C50.8347 76.0458 51.9472 74.8301 53.5145 74.8301H60.9623C62.5297 74.8301 63.9574 75.9842 64.1525 77.4944L64.3387 79.0212C64.5339 80.5315 63.4214 81.8449 61.8541 81.8449Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M61.8541 82.0707H54.4062C52.7699 82.0707 51.2055 80.6716 50.9923 79.0141L50.8077 77.585C50.7071 76.8065 50.9233 76.0702 51.4142 75.5097C51.9276 74.9251 52.6738 74.6035 53.516 74.6035H60.9638C62.6708 74.6035 64.1705 75.8598 64.3777 77.4648L64.5639 78.993C64.666 79.785 64.4318 80.5739 63.9198 81.157C63.4034 81.7461 62.6693 82.0692 61.8556 82.0692L61.8541 82.0707ZM53.5145 75.0558C52.8044 75.0558 52.1784 75.3233 51.752 75.8087C51.3466 76.2701 51.1695 76.8802 51.2536 77.5279L51.4382 78.957C51.6244 80.4011 52.983 81.6198 54.4047 81.6198H61.8526C62.5357 81.6198 63.1482 81.3508 63.579 80.8594C64.0054 80.3741 64.2006 79.7144 64.115 79.0486L63.9288 77.5204C63.7502 76.1378 62.4471 75.0543 60.9623 75.0543H53.5145V75.0558Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M63.1632 91.788H55.7154C54.148 91.788 52.7203 90.5408 52.5251 89.0305L52.339 87.5548C52.1438 86.0445 53.2563 84.7822 54.8236 84.7822H62.2714C63.8388 84.7822 65.2665 86.034 65.4616 87.5443L65.6478 89.017C65.843 90.5272 64.7305 91.788 63.1632 91.788Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M63.1632 92.0133H55.7154C54.0429 92.0133 52.5116 90.6878 52.3015 89.0589L52.1153 87.5832C52.0132 86.7927 52.2354 86.0414 52.7398 85.4658C53.2548 84.8797 53.9949 84.5566 54.8236 84.5566H62.2714C63.9439 84.5566 65.4752 85.8836 65.6853 87.5155L65.8715 88.9897C65.9736 89.7787 65.7514 90.5301 65.2485 91.1041C64.7335 91.6902 63.9934 92.0133 63.1632 92.0133ZM54.8236 85.0075C54.127 85.0075 53.507 85.2765 53.0776 85.7634C52.6603 86.2397 52.4771 86.8649 52.5612 87.5261L52.7473 89.0018C52.929 90.4128 54.2606 91.5625 55.7139 91.5625H63.1617C63.8598 91.5625 64.4798 91.2935 64.9077 90.8066C65.325 90.3317 65.5082 89.7066 65.4226 89.0468L65.2365 87.5727C65.0533 86.1586 63.7232 85.0075 62.2699 85.0075H54.8221H54.8236Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M33.2352 101.367H25.7874C24.2201 101.367 22.7924 100.147 22.5972 98.6354L22.411 97.1431C22.2159 95.6329 23.3283 94.3525 24.8957 94.3525H32.3435C33.9108 94.3525 35.3385 95.6344 35.5337 97.1446L35.7199 98.6068C35.915 100.117 34.8026 101.367 33.2352 101.367Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M48.9566 101.375H41.5088C39.9415 101.375 38.5137 100.15 38.3186 98.6399L38.1324 97.2018C37.9372 95.6915 39.0497 94.4668 40.617 94.4668H48.0649C49.6322 94.4668 51.0599 95.6915 51.2551 97.2018L51.4412 98.6399C51.6364 100.15 50.5239 101.375 48.9566 101.375Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M64.6765 101.375H57.2287C55.6613 101.375 54.2336 100.15 54.0384 98.6399L53.8523 97.2018C53.6571 95.6915 54.7696 94.4668 56.3369 94.4668H63.7847C65.3521 94.4668 66.7798 95.6915 66.9749 97.2018L67.1611 98.6399C67.3563 100.15 66.2438 101.375 64.6765 101.375Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M64.6765 101.6H57.2287C55.5547 101.6 54.0234 100.285 53.8147 98.6683L53.6286 97.2302C53.528 96.4487 53.7442 95.7094 54.2381 95.1474C54.7515 94.5628 55.4962 94.2412 56.3354 94.2412H63.7832C65.4571 94.2412 66.9884 95.5561 67.1971 97.1731L67.3833 98.6112C67.4839 99.3926 67.2677 100.132 66.7738 100.694C66.2603 101.279 65.5157 101.6 64.6765 101.6ZM56.3369 94.692C55.6283 94.692 55.0038 94.9595 54.5774 95.4449C54.1706 95.9093 53.9919 96.5224 54.076 97.1731L54.2621 98.6112C54.4423 100.012 55.7739 101.149 57.2287 101.149H64.6765C65.3851 101.149 66.0096 100.882 66.436 100.396C66.8428 99.9321 67.0215 99.319 66.9374 98.6683L66.7512 97.2302C66.5711 95.8296 65.2395 94.692 63.7847 94.692H56.3369Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M58.3411 61.8121H21.0734C19.0587 61.8121 17.2211 60.2658 16.9719 58.3348L12.884 26.8071C12.6332 24.8761 14.064 23.2832 16.0787 23.2832H53.3463C55.3611 23.2832 57.1986 24.8866 57.4478 26.8177L61.5358 58.3182C61.7865 60.2493 60.3558 61.8136 58.3411 61.8136V61.8121Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M58.3411 62.1047L38.2976 62.0371C38.173 62.0371 38.0724 61.9349 38.0724 61.8116C38.0724 61.6869 38.173 61.5862 38.2976 61.5862L58.3396 61.6539C59.2719 61.6539 60.0946 61.3007 60.6576 60.659C61.1905 60.0504 61.4247 59.251 61.3151 58.4034L58.1279 34.0875C58.1114 33.9643 58.1985 33.85 58.3216 33.835C58.4447 33.8185 58.5573 33.9056 58.5738 34.0289L61.761 58.3448C61.8871 59.3216 61.6154 60.2488 60.9954 60.9551C60.3453 61.6944 59.4025 62.1032 58.3381 62.1032L58.3411 62.1047Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M57.4478 27.0714C57.3367 27.0714 57.2392 26.9887 57.2241 26.8745C56.9869 25.0517 55.247 23.57 53.3448 23.57H16.0772C14.4258 23.57 13.1677 24.697 13.0836 26.2479C13.0761 26.3726 12.971 26.4658 12.8464 26.4612C12.7218 26.4537 12.6272 26.3485 12.6332 26.2238C12.7308 24.425 14.1781 23.1191 16.0772 23.1191H53.3448C55.4646 23.1191 57.4058 24.7782 57.67 26.8174C57.6865 26.9406 57.5995 27.0533 57.4764 27.0699C57.4659 27.0699 57.4568 27.0714 57.4463 27.0714H57.4478Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M16.4675 18.7466L51.231 18.709C51.791 18.709 52.2384 19.1748 52.2159 19.7354C52.1949 20.2583 51.7685 20.6731 51.2461 20.6806L16.5005 21.16C15.9676 21.1675 15.5247 20.7482 15.5037 20.2162L15.4857 19.7744C15.4616 19.2154 15.909 18.7481 16.469 18.7481L16.4675 18.7466Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M16.3699 21.008L13.7787 1.1522L16.3399 2.66096L18.9506 1.06354L22.0537 2.56178L25.1569 0.907257L28.1084 2.42804L30.9593 0.818595L33.558 2.26273L36.5366 0.686354L39.5376 2.19511L42.0102 0.575151L45.1013 2.02831L48.1925 0.441406L51.3497 20.7074L16.3699 21.008Z'
        }
        fill={selected ? theme.palette.primary.main : 'white'}
      />
      <path
        d={
          'M45.2155 2.25071L47.9987 0.821888L51.0581 20.4599L16.5892 20.7561L14.0928 1.62741L16.213 2.87637L16.3423 2.95255L16.4704 2.87421L18.9647 1.34798L21.9451 2.78691L22.0594 2.8421L22.1714 2.78238L25.1589 1.18951L27.9939 2.65027L28.1138 2.71206L28.2313 2.64574L30.9603 1.10514L33.4366 2.48126L33.5551 2.54713L33.675 2.4837L36.5395 0.967649L39.4253 2.41847L39.5541 2.4832L39.6746 2.40423L42.0303 0.860852L44.995 2.25455L45.1062 2.30683L45.2155 2.25071Z'
        }
        stroke={'black'}
        strokeOpacity={'0.6'}
        strokeWidth={'0.5'}
      />
      <mask id={'path-24-inside-1_12445_154233'} fill={'white'}>
        <path
          d={
            'M18.799 6.48373C18.6774 6.48373 18.5768 6.38605 18.5738 6.26433C18.5708 6.1396 18.6684 6.03591 18.793 6.0329L45.7394 5.36719H45.7454C45.867 5.36719 45.9676 5.46487 45.9706 5.58659C45.9736 5.71132 45.876 5.81501 45.7514 5.81801L18.805 6.48373H18.799Z'
          }
        />
      </mask>
      <path
        d={
          'M18.799 6.48373C18.6774 6.48373 18.5768 6.38605 18.5738 6.26433C18.5708 6.1396 18.6684 6.03591 18.793 6.0329L45.7394 5.36719H45.7454C45.867 5.36719 45.9676 5.46487 45.9706 5.58659C45.9736 5.71132 45.876 5.81501 45.7514 5.81801L18.805 6.48373H18.799Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M18.5738 6.26433L18.0739 6.27636L18.0739 6.27666L18.5738 6.26433ZM18.793 6.0329L18.805 6.53276L18.8053 6.53275L18.793 6.0329ZM45.7394 5.36719V4.86703L45.727 4.86734L45.7394 5.36719ZM45.9706 5.58659L46.4704 5.57456L46.4704 5.57426L45.9706 5.58659ZM45.7514 5.81801L45.7393 5.31816L45.739 5.31816L45.7514 5.81801ZM18.805 6.48373V6.98388L18.8173 6.98358L18.805 6.48373ZM18.799 5.98373C18.9476 5.98373 19.0699 6.10135 19.0736 6.252L18.0739 6.27666C18.0837 6.67075 18.4072 6.98373 18.799 6.98373V5.98373ZM19.0736 6.25229C19.0772 6.3998 18.9603 6.52901 18.805 6.53276L18.7809 5.53305C18.3765 5.54281 18.0644 5.87939 18.0739 6.27636L19.0736 6.25229ZM18.8053 6.53275L45.7517 5.86703L45.727 4.86734L18.7806 5.53306L18.8053 6.53275ZM45.7394 5.86719H45.7454V4.86719H45.7394V5.86719ZM45.7454 5.86719C45.5968 5.86719 45.4744 5.74957 45.4707 5.59892L46.4704 5.57426C46.4607 5.18016 46.1372 4.86719 45.7454 4.86719V5.86719ZM45.4707 5.59862C45.4672 5.45112 45.5841 5.3219 45.7393 5.31816L45.7634 6.31787C46.1679 6.30811 46.48 5.97152 46.4704 5.57456L45.4707 5.59862ZM45.739 5.31816L18.7926 5.98388L18.8173 6.98358L45.7637 6.31786L45.739 5.31816ZM18.805 5.98373H18.799V6.98373H18.805V5.98373Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
        mask={'url(#path-24-inside-1_12445_154233)'}
      />
      <mask id={'path-26-inside-2_12445_154233'} fill={'white'}>
        <path
          d={
            'M19.1022 8.88119C18.9806 8.88119 18.88 8.78351 18.877 8.66179C18.874 8.53706 18.9716 8.43337 19.0962 8.43037L46.0426 7.76465C46.1702 7.76765 46.2708 7.85932 46.2738 7.98405C46.2768 8.10878 46.1793 8.21247 46.0546 8.21547L19.1082 8.88119H19.1022Z'
          }
        />
      </mask>
      <path
        d={
          'M19.1022 8.88119C18.9806 8.88119 18.88 8.78351 18.877 8.66179C18.874 8.53706 18.9716 8.43337 19.0962 8.43037L46.0426 7.76465C46.1702 7.76765 46.2708 7.85932 46.2738 7.98405C46.2768 8.10878 46.1793 8.21247 46.0546 8.21547L19.1082 8.88119H19.1022Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M18.877 8.66179L18.3772 8.67382L18.3772 8.67412L18.877 8.66179ZM19.0962 8.43037L19.1083 8.93022L19.1086 8.93021L19.0962 8.43037ZM46.0426 7.76465L46.0544 7.26479L46.0424 7.2645L46.0303 7.2648L46.0426 7.76465ZM46.0546 8.21547L46.0426 7.71562L46.0423 7.71562L46.0546 8.21547ZM19.1082 8.88119V9.38134L19.1206 9.38104L19.1082 8.88119ZM19.1022 8.38119C19.2509 8.38119 19.3732 8.49881 19.3769 8.64946L18.3772 8.67412C18.3869 9.06821 18.7104 9.38119 19.1022 9.38119V8.38119ZM19.3769 8.64976C19.3805 8.79726 19.2635 8.92648 19.1083 8.93022L19.0842 7.93051C18.6797 7.94027 18.3676 8.27686 18.3772 8.67382L19.3769 8.64976ZM19.1086 8.93021L46.055 8.2645L46.0303 7.2648L19.0839 7.93052L19.1086 8.93021ZM46.0309 8.26451C45.9775 8.26325 45.9153 8.24253 45.863 8.19346C45.8088 8.1427 45.7758 8.07063 45.774 7.99608L46.7737 7.97202C46.7636 7.55155 46.4189 7.27337 46.0544 7.26479L46.0309 8.26451ZM45.774 7.99608C45.7704 7.84856 45.8874 7.71936 46.0426 7.71562L46.0667 8.71533C46.4712 8.70557 46.7833 8.36899 46.7737 7.97202L45.774 7.99608ZM46.0423 7.71562L19.0959 8.38134L19.1206 9.38104L46.067 8.71532L46.0423 7.71562ZM19.1082 8.38119H19.1022V9.38119H19.1082V8.38119Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
        mask={'url(#path-26-inside-2_12445_154233)'}
      />
      <path
        d={
          'M24.9272 56.6985C24.8867 56.6985 24.8461 56.688 24.8101 56.6654C24.7035 56.6008 24.6705 56.4611 24.7365 56.3559L31.0854 46.014C31.15 45.9073 31.2896 45.8742 31.3947 45.9403C31.5013 46.0049 31.5343 46.1447 31.4683 46.2499L25.1179 56.5918C25.0758 56.6609 25.0008 56.6985 24.9257 56.6985H24.9272Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M34.5264 41.0638C34.4858 41.0638 34.4453 41.0533 34.4093 41.0308C34.3027 40.9662 34.2696 40.8264 34.3357 40.7212L38.811 33.4329C38.8755 33.3262 39.0152 33.2931 39.1203 33.3593C39.2268 33.4239 39.2599 33.5636 39.1938 33.6688L34.7185 40.9572C34.6765 41.0263 34.6014 41.0638 34.5264 41.0638Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M28.6804 57.9005C28.6398 57.9005 28.5993 57.89 28.5633 57.8674C28.4567 57.8028 28.4237 57.663 28.4897 57.5579L31.3887 52.8362C31.4532 52.7295 31.5929 52.6965 31.6979 52.7626C31.8045 52.8272 31.8376 52.967 31.7715 53.0722L28.8725 57.7938C28.8305 57.8629 28.7554 57.9005 28.6804 57.9005Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M35.5968 46.636C35.5562 46.636 35.5157 46.6255 35.4797 46.6029C35.3731 46.5383 35.34 46.3986 35.4061 46.2934L37.7391 42.4944C37.8036 42.3877 37.9433 42.3547 38.0483 42.4208C38.1549 42.4854 38.188 42.6252 38.1219 42.7304L35.7889 46.5293C35.7469 46.5984 35.6718 46.636 35.5968 46.636Z'
        }
        fill={'white'}
      />
      <path
        d={
          'M37.9282 42.8368C37.8877 42.8368 37.8472 42.8263 37.8111 42.8038C37.7045 42.7392 37.6715 42.5994 37.7376 42.4942L43.0206 33.888C43.0851 33.7813 43.2247 33.7482 43.3298 33.8143C43.4364 33.879 43.4694 34.0187 43.4034 34.1239L38.1204 42.7301C38.0784 42.7993 38.0033 42.8368 37.9282 42.8368Z'
        }
        fill={'white'}
      />
    </svg>
  );
};
