import React, { useContext, useMemo } from 'react';
import type { FC } from 'react';
import { sumBy } from 'lodash-es';
import { Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import { ApplicationContext } from '../../../../shared/contexts/ApplicationContext';
import type { RequirementIndicationProps } from '../requirementItemToComponent';
import RequirementIndicationIcon from './assets/RequirementIndication.svg';
import { IndicationContainer } from './IndicationContainer';

export const UseOfFundsBreakdownIndication: FC<RequirementIndicationProps> = () => {
  const { application } = useContext(ApplicationContext);
  const { useOfFundsBreakdown, requestedAmount } = application;

  const remainingFunds = useMemo(() => {
    if (!requestedAmount) {
      return 0;
    }
    if (!useOfFundsBreakdown?.length) {
      return requestedAmount;
    }

    return requestedAmount - sumBy(useOfFundsBreakdown, (a) => a.amount ?? 0);
  }, [useOfFundsBreakdown, requestedAmount]);

  if (remainingFunds <= 0) {
    return null;
  }

  return (
    <IndicationContainer>
      <RequirementIndicationIcon />
      <Text variant={'body2'}> {`Use for ${formatValue(remainingFunds, 'currency')} has not yet been specified.`}</Text>
    </IndicationContainer>
  );
};
