import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import grey from '@mui/material/colors/grey.js';
import { AssignmentRounded, AssignmentLateRounded, InfoOutlined } from '@mui/icons-material';

import type { DismissedSourceReason } from '@lama/contracts';
import { useToggle } from 'react-use';
import { Flex, Text } from '@lama/design-system';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip/Tooltip.js';
import { ActionButtons } from './ActionButtons.js';

interface NoDocumentProps {
  description: string;
  onFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange?: (description: string) => Promise<void>;
  onDismissSource?: (name: string) => void;
  onUndismissSource?: (name: string) => void;
  dismissed?: DismissedSourceReason;
  dismissible?: boolean;
}

export const StyledText = styled(Text)`
  white-space: pre;
`;

export const NoDocument: FC<NoDocumentProps> = ({
  description,
  onFileSelected,
  onDismissSource,
  onUndismissSource,
  onDescriptionChange,
  dismissed,
  dismissible,
}) => {
  const [placeholderEditMode, togglePlaceholderEditMode] = useToggle(false);
  const [placeholder, setPlaceholder] = useState(description);

  const shouldEnableDescriptionEdit = useMemo(() => !dismissed && onDescriptionChange, [dismissed, onDescriptionChange]);
  const updatePlaceholder = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPlaceholder(e.target.value);
    },
    [setPlaceholder],
  );

  const onDescriptionChangeInner = useCallback(async () => {
    await onDescriptionChange?.(placeholder);
    togglePlaceholderEditMode();
  }, [onDescriptionChange, placeholder, togglePlaceholderEditMode]);

  return (
    <Flex gap={2} alignItems={'center'} width={'100%'} minWidth={0}>
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minWidth={'34px'}
        minHeight={'34px'}
        width={'34px'}
        height={'34px'}
        borderRadius={'4px'}
        color={grey[300]}
      >
        {dismissed ? <AssignmentLateRounded sx={{ color: grey[300] }} /> : <AssignmentRounded sx={{ color: grey[300] }} />}
      </Flex>
      <Flex alignItems={'center'} minWidth={0}>
        {shouldEnableDescriptionEdit ? (
          placeholderEditMode ? (
            <TextField variant={'standard'} value={placeholder} onChange={updatePlaceholder} onBlur={onDescriptionChangeInner} />
          ) : (
            <Tooltip onClick={togglePlaceholderEditMode} title={'Edit Name'} placement={'top'}>
              <Text variant={'body1'} color={'secondary'}>
                {description}
              </Text>
            </Tooltip>
          )
        ) : (
          <Text variant={'body1'} color={'secondary'} ellipsis>
            {description}
          </Text>
        )}
        {dismissed ? (
          <Flex gap={2}>
            <StyledText variant={'body1'} color={grey[500]}>
              {' - Dismissed'}
            </StyledText>
            <Tooltip title={dismissed?.description} placement={'top'}>
              <InfoOutlined sx={{ marginRight: 1, color: grey[500] }} />
            </Tooltip>
          </Flex>
        ) : null}
      </Flex>
      <Flex justifyContent={'flex-end'} flex={1}>
        <ActionButtons
          description={description}
          onFileSelected={onFileSelected}
          onDismiss={onDismissSource}
          onUndismiss={onUndismissSource}
          dismissible={dismissible}
          dismissed={dismissed}
        />
      </Flex>
    </Flex>
  );
};
