import type { FC } from 'react';
import React from 'react';
import { Typography } from '@mui/material';
import type { Collateral } from '@lama/contracts';
import { Flex, Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';

interface CollateralItemProps {
  item: Collateral;
}

export const CollateralItem: FC<CollateralItemProps> = ({ item: { type, totalValue, method, description } }) => (
  <>
    <Flex gap={1} flexDirection={'row'} color={'text.secondary'}>
      <Typography variant={'subtitle2'} noWrap>
        {type}
      </Typography>
      {totalValue ? (
        <Typography variant={'subtitle2'} noWrap>
          {` • ${formatValue(totalValue, 'currency')}`}
        </Typography>
      ) : null}
      {method ? (
        <Typography variant={'subtitle2'} noWrap>
          {` • Valuation Method: ${method}`}
        </Typography>
      ) : null}
    </Flex>
    <Flex flexDirection={'column'}>
      <Text variant={'body2'} ellipsis>
        {description}
      </Text>
    </Flex>
  </>
);
