import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { paramCase } from 'change-case';

import { useRecoilValue } from 'recoil';
import type { Screen, ProductPublicData } from '@lama/contracts';
import { relatedBusinessesByRelationsSelector } from '@lama/selectors';
import type { ApplicationApiModel } from '@lama/clients';
import { relationToBusinessState, selectedServicesState } from '../state/applicationState';
import { useScreenNameFromUrl } from './useScreenNameFromUrl';

type NavigationDirection = 'back' | 'next';

export interface NavigationStepOptions {
  direction: NavigationDirection;
  nextScreenName?: Screen;
  previousScreenName?: Screen;
  useOfFunds?: string[];
  businessUnformed?: boolean;
}

export type NextStepOptions = Omit<NavigationStepOptions, 'direction' | 'previousScreenName'>;
export type PreviousStepOptions = Omit<NavigationStepOptions, 'direction' | 'nextScreenName'> | undefined;

export const useStepsUrlNavigation = (product: ProductPublicData, application?: ApplicationApiModel) => {
  const { preFlowSteps, flowSteps, postFlowSteps } = product.screensConfiguration;
  const stepsFlow = [preFlowSteps, flowSteps, postFlowSteps].flat(2);

  const relationToBusiness = useRecoilValue(relationToBusinessState);
  const selectedServices = useRecoilValue(selectedServicesState);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const currentStep = useScreenNameFromUrl();

  const activeStepIndex = useMemo(() => stepsFlow.indexOf(currentStep), [currentStep, stepsFlow]);

  // TODO: This needs to be a generic function
  const getNextStep = useCallback(
    ({ direction, useOfFunds, businessUnformed }: NavigationStepOptions): string | undefined => {
      let nextIndex = activeStepIndex + (direction === 'next' ? 1 : -1);

      // takes care of on behalf of skip and services skip
      if (
        (stepsFlow[nextIndex] === 'personInformation' && relationToBusiness === 'OnBehalfOfOwners') ||
        (stepsFlow[nextIndex] === 'servicesConnection' && !selectedServices.length)
      ) {
        nextIndex += direction === 'next' ? 1 : -1;
      }

      // This is a hack for FIBT
      if (stepsFlow[nextIndex] === 'transactionEstimates' && application) {
        const [borrowingBusiness] = relatedBusinessesByRelationsSelector(application, ['borrower']);

        if (borrowingBusiness?.business.isProcessingOwnPayroll) {
          nextIndex += direction === 'next' ? 1 : -1;
        }
      }

      if (stepsFlow.includes('borrowingEntity')) {
        if (direction === 'next') {
          // takes care of use of funds
          if (activeStepIndex === stepsFlow.indexOf('useOfFunds')) {
            nextIndex =
              useOfFunds?.includes('Startup') || useOfFunds?.includes('Business Acquisition')
                ? stepsFlow.indexOf('borrowingEntity')
                : stepsFlow.indexOf('searchBusiness');
          }

          // takes care of unformed business
          if (activeStepIndex === stepsFlow.indexOf('borrowingEntity')) {
            nextIndex =
              businessUnformed && stepsFlow.includes('tbdBusinessDetails')
                ? stepsFlow.indexOf('tbdBusinessDetails')
                : stepsFlow.indexOf('searchBusiness');
          }

          if (activeStepIndex === stepsFlow.indexOf('tbdBusinessDetails')) {
            nextIndex = stepsFlow.indexOf('businessDetails') + 1; // skip businessDetails
          }
        }

        if (direction === 'back') {
          if (activeStepIndex === stepsFlow.indexOf('searchBusiness') || activeStepIndex === stepsFlow.indexOf('tbdBusinessDetails')) {
            nextIndex =
              useOfFunds?.includes('Startup') || useOfFunds?.includes('Business Acquisition')
                ? stepsFlow.indexOf('borrowingEntity')
                : stepsFlow.indexOf('useOfFunds');
          }

          if (activeStepIndex === stepsFlow.indexOf('businessRelation')) {
            nextIndex = businessUnformed ? stepsFlow.indexOf('tbdBusinessDetails') : stepsFlow.indexOf('businessDetails');
          }
        }
      }

      return stepsFlow[nextIndex];
    },
    [activeStepIndex, application, relationToBusiness, selectedServices.length, stepsFlow],
  );

  const onNavigationClicked = useCallback(
    (params: {
      direction: NavigationDirection;
      nextScreenName?: Screen;
      previousScreenName?: Screen;
      useOfFunds?: string[];
      businessUnformed?: boolean;
    }) => {
      const nextStep = getNextStep(params);
      if (nextStep) {
        navigate(`${pathname}/../${paramCase(nextStep)}${search}`);
      }
    },
    [getNextStep, navigate, pathname, search],
  );

  const navigateNext = useCallback(
    (params?: NextStepOptions) => {
      onNavigationClicked({
        nextScreenName: params?.nextScreenName,
        useOfFunds: params?.useOfFunds,
        direction: 'next',
        businessUnformed: params?.businessUnformed,
      });
    },
    [onNavigationClicked],
  );

  const navigateBack = useCallback(
    (params?: PreviousStepOptions) => {
      onNavigationClicked({
        previousScreenName: params?.previousScreenName,
        useOfFunds: params?.useOfFunds,
        businessUnformed: params?.businessUnformed,
        direction: 'back',
      });
    },
    [onNavigationClicked],
  );

  return { navigateNext, navigateBack, currentStep };
};
