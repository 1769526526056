import React, { useMemo } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import { Flex, Text } from '@lama/design-system';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import type { Flow } from '../ScreenProps';
import type { StepsNavigationProps } from './StepsNavigation';
import { StepsNavigation } from './StepsNavigation';
import { BaseScreenWrapper } from './BaseScreenWrapper';
import { RequirementIndication } from './RequirementIndication';

import './BasicScreen/basicScreen.css';

const StyledSubtitle = styled(Text)`
  white-space: pre-wrap;
`;

interface BasicScreenProps extends StepsNavigationProps {
  preTitleComponent?: ReactNode;
  children?: ReactNode;
  title?: string;
  subtitle?: string;
  animate?: boolean;
  disableIndication?: boolean;
  maxWidth?: string;
  flow: Flow;
}

interface HeaderProps {
  requirement?: EvaluatedApplicationRequirement;
  title?: string;
  subtitle?: string;
  flow: Flow;
  disableIndication?: boolean;
  preTitleComponent?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ requirement, title, subtitle, flow, disableIndication, preTitleComponent }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const showIndication = useMemo(() => !disableIndication && flow === 'management', [disableIndication, flow]);

  return (
    <Flex flexDirection={'column'} gap={4} justifyContent={'center'} alignItems={flow === 'onboarding' ? 'center' : 'left'}>
      {preTitleComponent}
      {isMobile && showIndication && requirement ? <RequirementIndication requirement={requirement} /> : null}
      <Flex gap={4} width={'100%'} justifyContent={flow === 'onboarding' ? 'center' : 'left'} alignItems={'center'}>
        <Text variant={'h4'} textAlign={flow === 'onboarding' ? 'center' : 'left'}>
          {title}
        </Text>
        {!isMobile && showIndication && requirement ? <RequirementIndication requirement={requirement} /> : null}
      </Flex>
      {subtitle ? (
        <StyledSubtitle variant={'body1'} textAlign={flow === 'onboarding' ? 'center' : 'left'} color={'secondary'}>
          <Markdown>{subtitle}</Markdown>
        </StyledSubtitle>
      ) : null}
    </Flex>
  );
};

export const BasicScreen: React.FC<BasicScreenProps> = ({
  children,
  title,
  subtitle,
  animate = true,
  disableIndication = false,
  maxWidth = '768px',
  preTitleComponent,
  flow,
  ...stepsNavigationProps
}) => {
  const currentRequirement = useGetCurrentRequirement();
  const dismissible = flow === 'management' && currentRequirement?.dismissible;

  return (
    <Flex flex={1} flexDirection={'row'} width={'100%'} justifyContent={'center'} maxWidth={maxWidth}>
      <BaseScreenWrapper animate={animate}>
        <Flex flexDirection={'column'} flex={1} gap={8} alignItems={'center'}>
          <Flex flexDirection={'column'} gap={10} width={'100%'}>
            <Header
              title={title}
              subtitle={subtitle}
              flow={flow}
              disableIndication={disableIndication}
              preTitleComponent={preTitleComponent}
              requirement={currentRequirement}
            />
            <Flex flexDirection={'column'} gap={6} width={'100%'}>
              {children}
            </Flex>
          </Flex>
          <Box maxWidth={'md'} mt={'auto'} width={'100%'}>
            <StepsNavigation {...stepsNavigationProps} dismissible={dismissible} />
          </Box>
        </Flex>
      </BaseScreenWrapper>
    </Flex>
  );
};
