import type { InputHTMLAttributes } from 'react';
import React, { forwardRef, useCallback, useState } from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { StyledInputContainer } from './Styles/Input.styles';
import { InputByType } from './InputByType';
import { InputLabel } from './inputLabel';
import { InputHelper } from './InputHelper';

type OverridedInputProps =
  | 'customInput'
  | 'defaultValue'
  | 'fullWidth'
  | 'helperText'
  | 'label'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'onFocus'
  | 'size'
  | 'value';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, OverridedInputProps>,
    Omit<NumericFormatProps, OverridedInputProps | 'type'> {
  name?: string;
  label?: string;

  disabled?: boolean;
  value?: number | string;
  defaultValue?: number | string;
  helper?: React.ReactNode | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  fullWidth?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ type = 'text', name, onBlur, onFocus, label, helper, ...props }, ref) => {
  const [focused, setFocused] = useState(false);

  const internalOnFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus?.(event);
    },
    [onFocus],
  );

  const internalOnBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur?.(event);
    },
    [onBlur],
  );

  return (
    <StyledInputContainer flexDirection={'column'} type={type} label={label} ref={ref} gap={1} {...props}>
      {label ? <InputLabel name={name} label={label} focused={focused} error={props.error} disabled={props.disabled} /> : null}
      <InputByType
        role={'textbox'}
        type={type}
        name={name}
        onFocus={internalOnFocus}
        onBlur={internalOnBlur}
        focused={focused}
        {...props}
      />
      <InputHelper helper={helper} />
    </StyledInputContainer>
  );
});

Input.displayName = 'Input';
