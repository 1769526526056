import React from 'react';
import { FormikEmailInputField, type PropertyComponentProps } from '@lama/app-components';
import { GenericListForm } from './GenericListForm';

export const customComponents: Record<string, React.FC<PropertyComponentProps>> = {
  table: ({ property: { fieldName, childProperties, displayName, noItemsFieldName }, entityType }) => (
    <GenericListForm
      fieldName={fieldName}
      entityType={entityType}
      itemProperties={childProperties}
      displayName={displayName}
      noItemsFieldName={noItemsFieldName}
    />
  ),
  email: ({ property: { fieldName, displayName, optional, helperText }, submitted, initialValue }) => (
    <FormikEmailInputField
      name={fieldName}
      label={displayName}
      highlight={submitted}
      required={!optional}
      helperText={helperText}
      disabled={!!initialValue}
    />
  ),
};
