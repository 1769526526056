/* eslint-disable @typescript-eslint/naming-convention */
import { useMediaQuery, useTheme } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormikMoneyInputField } from '@lama/app-components';
import { Flex } from '@lama/design-system';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { useGetRequiredFields } from '../../hooks/useGetRequiredFields';
import { RequirementInput } from '../shared/RequirmentInput';
import { BasicForm } from '../shared/BasicForm';

const validationSchema = yup.object({
  wages: yup.number(),
  liquidAssets: yup.number(),
  otherIncome: yup.number(),
  longTermAssets: yup.number(),
  incomeFromBusiness: yup.number(),
  liabilities: yup.number(),
  livingExpenses: yup.number(),
  annualDebtPayments: yup.number(),
});

const RequirementInputContainer: FC<{ isMobile: boolean; children: ReactNode }> = ({ isMobile, children }) => (
  <Flex gap={4} flexDirection={isMobile ? 'column' : 'row'}>
    {children}
  </Flex>
);

export const PersonalFinancialStatementsScreen: React.FC<ScreenProps> = ({
  onNextClick,
  onBackClick,
  nextEnabled,
  confirmDiscardChanges,
  ...stepsNavigationProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const requiredFields = useGetRequiredFields();

  return (
    <BasicForm
      initialValues={{}}
      validationSchema={validationSchema}
      submitCallback={onNextClick}
      confirmDiscardChanges={confirmDiscardChanges}
    >
      {({ handleSubmit, dirty, isValid, submitted }) => (
        <BasicScreen
          title={t('personalFinancialStatements.title')}
          subtitle={t('personalFinancialStatements.subtitle')}
          onNextClick={handleSubmit}
          onBackClick={onBackClick}
          nextEnabled={!!nextEnabled || (dirty && isValid)}
          {...stepsNavigationProps}
        >
          <Flex flexDirection={'column'} gap={8}>
            <RequirementInputContainer isMobile={isMobile}>
              <RequirementInput
                name={'wages'}
                label={t('personalFinancialStatements.wages')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.wages')}
                submitted={submitted}
              />
              <RequirementInput
                name={'liquidAssets'}
                label={t('personalFinancialStatements.liquidAssets')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.liquidAssets')}
                submitted={submitted}
              />
            </RequirementInputContainer>
            <RequirementInputContainer isMobile={isMobile}>
              <RequirementInput
                name={'Other Income'}
                label={t('personalFinancialStatements.otherIncome')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.otherIncome')}
                submitted={submitted}
              />
              <RequirementInput
                name={'longTermAssets'}
                label={t('personalFinancialStatements.longTermAssets')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.longTermAssets')}
                submitted={submitted}
              />
            </RequirementInputContainer>
            <RequirementInputContainer isMobile={isMobile}>
              <RequirementInput
                name={'incomeFromBusiness'}
                label={t('personalFinancialStatements.incomeFromBusiness')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.incomeFromBusiness')}
                submitted={submitted}
              />
              <RequirementInput
                name={'liabilities'}
                label={t('personalFinancialStatements.liabilities')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.liabilities')}
                submitted={submitted}
              />
            </RequirementInputContainer>
            <RequirementInputContainer isMobile={isMobile}>
              <RequirementInput
                name={'livingExpenses'}
                label={t('personalFinancialStatements.livingExpenses')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.livingExpenses')}
                submitted={submitted}
              />
              <RequirementInput
                name={'annualDebtPayments'}
                label={t('personalFinancialStatements.annualDebtPayments')}
                Component={FormikMoneyInputField}
                required={requiredFields.includes('personalFinancialStatements.annualDebtPayments')}
                submitted={submitted}
              />
            </RequirementInputContainer>
          </Flex>
        </BasicScreen>
      )}
    </BasicForm>
  );
};
