import { useMutation, useQueryClient } from '@tanstack/react-query';
import { displayToast } from '@lama/app-components';
import type { UpdateBusinessRequest } from '@lama/clients';
import { useAuthentication } from '../authentication/useAuthentication';
import { businessServiceClient } from '../../shared/clients/businessService';

export const useUpdateBusiness = (applicationId: string) => {
  const queryClient = useQueryClient();
  const { token, isAuthenticated } = useAuthentication();

  return useMutation({
    mutationFn: async ({ businessId, updateBusinessPayload }: { businessId: string; updateBusinessPayload: UpdateBusinessRequest }) => {
      if (isAuthenticated) {
        await businessServiceClient.updateBusiness({ businessId, business: updateBusinessPayload, token });
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['applicationRequirements', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['application', applicationId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['applications'],
        }),
      ]);
    },
    onError: () => {
      displayToast('Something went wrong while updating the business. Please contact support.', 'error');
    },
  });
};
