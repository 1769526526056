import React, { useState, useCallback, useRef } from 'react';
import type { FC, RefObject } from 'react';
import { pascalCase } from 'change-case-all';
import type { PopoverOrigin } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown.js';
import { Flex, Text } from '@lama/design-system';
import { Avatar } from '../Avatar/Avatar.js';
import type { AccountAction } from './AccountPopover.js';
import { AccountPopover } from './AccountPopover.js';

interface AccountDetailsProps {
  firstName: string;
  lastName: string;
  anchorRef?: RefObject<HTMLDivElement>;
  email: string;
  logout: () => void;
  minimized?: boolean;
  popoverLocation?: PopoverOrigin;
  actions?: AccountAction[];
  partner?: string;
}

export const AccountDetails: FC<AccountDetailsProps> = ({
  anchorRef,
  firstName = '',
  lastName = '',
  email,
  logout,
  minimized,
  popoverLocation,
  actions,
  partner,
}) => {
  const internalAnchor = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Flex flexDirection={'row'} gap={3} alignItems={'center'} onClick={toggleMenu} ref={minimized ? anchorRef ?? internalAnchor : null}>
        <Avatar firstName={firstName} lastName={lastName} />
        {minimized ? null : (
          <>
            <Text variant={'body2'} py={0.5} textAlign={'center'}>
              {`${pascalCase(firstName)} ${lastName?.[0]?.toUpperCase() ?? ''}.`}
            </Text>
            <Flex ref={anchorRef ?? internalAnchor}>
              <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', opacity: 0.6 }} />
            </Flex>
          </>
        )}
      </Flex>
      <AccountPopover
        popoverLocation={popoverLocation}
        open={open}
        setOpen={setOpen}
        topbarRef={anchorRef ?? internalAnchor}
        firstName={firstName}
        lastName={lastName}
        email={email}
        logout={logout}
        actions={actions}
        partner={partner}
      />
    </>
  );
};
