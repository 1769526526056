import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const CreLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'167'} height={'116'} viewBox={'0 0 167 116'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <g clipPath={'url(#clip0_12445_154489)'}>
        <path
          d={
            'M156.4 114.069H9.56265C8.04861 114.069 6.81953 112.84 6.81953 111.326V37.251C6.81953 34.6568 8.92259 32.5537 11.5169 32.5537H153.532C156.173 32.5537 158.316 34.695 158.316 37.3382V112.153C158.316 113.21 157.459 114.067 156.403 114.067L156.4 114.069Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M156.403 113.567H156.195L156.193 113.569H9.56265C8.32476 113.569 7.31953 112.564 7.31953 111.326V37.251C7.31953 34.9329 9.19873 33.0537 11.5169 33.0537H153.532C155.897 33.0537 157.816 34.9713 157.816 37.3382V112.153C157.816 112.934 157.183 113.567 156.403 113.567Z'
          }
          stroke={'black'}
          strokeOpacity={'0.6'}
        />
        <path
          d={
            'M139.635 75.4551H131.908C131.533 75.4551 131.23 75.7588 131.23 76.1334V87.9884C131.23 88.363 131.533 88.6667 131.908 88.6667H139.635C140.01 88.6667 140.314 88.363 140.314 87.9884V76.1334C140.314 75.7588 140.01 75.4551 139.635 75.4551Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M150.225 75.4551H142.634C142.222 75.4551 141.887 75.7892 141.887 76.2015V87.9203C141.887 88.3325 142.222 88.6667 142.634 88.6667H150.225C150.637 88.6667 150.972 88.3325 150.972 87.9203V76.2015C150.972 75.7892 150.637 75.4551 150.225 75.4551Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M139.829 90.1357H131.714C131.447 90.1357 131.23 90.3528 131.23 90.6206V102.863C131.23 103.13 131.447 103.347 131.714 103.347H139.829C140.097 103.347 140.314 103.13 140.314 102.863V90.6206C140.314 90.3528 140.097 90.1357 139.829 90.1357Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M150.206 90.1357H142.653C142.23 90.1357 141.887 90.4785 141.887 90.9013V102.582C141.887 103.005 142.23 103.347 142.653 103.347H150.206C150.629 103.347 150.972 103.005 150.972 102.582V90.9013C150.972 90.4785 150.629 90.1357 150.206 90.1357Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M66.9853 36.9069L1.25461 36.5773C0.335979 36.573 -0.270059 35.6183 0.119081 34.7847L19.4358 8.45711C19.861 7.87659 20.5415 7.53848 21.2603 7.55337C28.9984 7.70434 74.4321 6.44336 74.4321 6.44336L66.9853 36.9069V36.9069Z'
          }
          fill={'#9E9E9E'}
        />
        <path
          d={
            'M1.25711 36.0773H1.25692C0.717333 36.0748 0.356787 35.5287 0.554895 35.0359L19.8389 8.75289L19.8391 8.7526C20.1686 8.30276 20.6951 8.04177 21.2499 8.05326L21.2505 8.05327C25.1336 8.12903 38.4415 7.85104 50.76 7.55484C56.9234 7.40664 62.8449 7.25372 67.2254 7.13785C69.4157 7.07992 71.2208 7.03125 72.4784 6.99706L73.7908 6.9612L66.5933 36.4049L1.25711 36.0773Z'
          }
          stroke={'black'}
          strokeOpacity={'0.6'}
        />
        <path
          d={
            'M99.2457 36.6813L164.976 36.3517C165.895 36.3474 166.501 35.3927 166.112 34.5591L146.795 8.23152C146.37 7.651 145.689 7.3129 144.971 7.32778C137.233 7.47876 91.7989 6.21777 91.7989 6.21777L99.2457 36.6813V36.6813Z'
          }
          fill={'#9E9E9E'}
        />
        <path
          d={
            'M164.974 35.8517H164.974L99.6377 36.1793L92.4402 6.73561L93.7526 6.77148C95.0102 6.80566 96.8153 6.85434 99.0056 6.91227C103.386 7.02813 109.308 7.18105 115.471 7.32925C127.79 7.62545 141.097 7.90345 144.981 7.82769L144.981 7.82767C145.536 7.81618 146.062 8.07718 146.392 8.52701L146.392 8.5273L165.676 34.8103C165.874 35.3031 165.514 35.8492 164.974 35.8517Z'
          }
          stroke={'black'}
          strokeOpacity={'0.6'}
        />
        <path
          d={
            'M31.2907 75.2295H23.5632C23.1886 75.2295 22.8849 75.5332 22.8849 75.9078V87.7628C22.8849 88.1374 23.1886 88.4411 23.5632 88.4411H31.2907C31.6654 88.4411 31.9691 88.1374 31.9691 87.7628V75.9078C31.9691 75.5332 31.6654 75.2295 31.2907 75.2295Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M20.5649 75.2295H12.9735C12.5613 75.2295 12.2271 75.5637 12.2271 75.9759V87.6947C12.2271 88.107 12.5613 88.4411 12.9735 88.4411H20.5649C20.9771 88.4411 21.3113 88.107 21.3113 87.6947V75.9759C21.3113 75.5637 20.9771 75.2295 20.5649 75.2295Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M31.4842 89.9102H23.3697C23.1019 89.9102 22.8849 90.1272 22.8849 90.395V102.637C22.8849 102.905 23.1019 103.122 23.3697 103.122H31.4842C31.752 103.122 31.9691 102.905 31.9691 102.637V90.395C31.9691 90.1272 31.752 89.9102 31.4842 89.9102Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M20.5458 89.9102H12.9926C12.5698 89.9102 12.2271 90.2529 12.2271 90.6757V102.356C12.2271 102.779 12.5698 103.122 12.9926 103.122H20.5458C20.9686 103.122 21.3113 102.779 21.3113 102.356V90.6757C21.3113 90.2529 20.9686 89.9102 20.5458 89.9102Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M139.423 43.3477H131.695C131.321 43.3477 131.017 43.6514 131.017 44.026V55.881C131.017 56.2556 131.321 56.5593 131.695 56.5593H139.423C139.797 56.5593 140.101 56.2556 140.101 55.881V44.026C140.101 43.6514 139.797 43.3477 139.423 43.3477Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M150.013 43.3477H142.421C142.009 43.3477 141.675 43.6818 141.675 44.094V55.8129C141.675 56.2251 142.009 56.5593 142.421 56.5593H150.013C150.425 56.5593 150.759 56.2251 150.759 55.8129V44.094C150.759 43.6818 150.425 43.3477 150.013 43.3477Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M139.616 58.0264H131.502C131.234 58.0264 131.017 58.2434 131.017 58.5112V70.7532C131.017 71.0209 131.234 71.238 131.502 71.238H139.616C139.884 71.238 140.101 71.0209 140.101 70.7532V58.5112C140.101 58.2434 139.884 58.0264 139.616 58.0264Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M149.993 58.0264H142.44C142.017 58.0264 141.675 58.3691 141.675 58.7919V70.4725C141.675 70.8953 142.017 71.238 142.44 71.238H149.993C150.416 71.238 150.759 70.8953 150.759 70.4725V58.7919C150.759 58.3691 150.416 58.0264 149.993 58.0264Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M31.0781 43.1221H23.3506C22.9759 43.1221 22.6722 43.4258 22.6722 43.8004V55.6554C22.6722 56.03 22.9759 56.3337 23.3506 56.3337H31.0781C31.4527 56.3337 31.7564 56.03 31.7564 55.6554V43.8004C31.7564 43.4258 31.4527 43.1221 31.0781 43.1221Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M20.3523 43.1221H12.7608C12.3486 43.1221 12.0144 43.4562 12.0144 43.8685V55.5873C12.0144 55.9995 12.3486 56.3337 12.7608 56.3337H20.3523C20.7645 56.3337 21.0986 55.9995 21.0986 55.5873V43.8685C21.0986 43.4562 20.7645 43.1221 20.3523 43.1221Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M31.2716 57.8008H23.157C22.8893 57.8008 22.6722 58.0178 22.6722 58.2856V70.5276C22.6722 70.7954 22.8893 71.0124 23.157 71.0124H31.2716C31.5393 71.0124 31.7564 70.7954 31.7564 70.5276V58.2856C31.7564 58.0178 31.5393 57.8008 31.2716 57.8008Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M20.3331 57.8008H12.78C12.3572 57.8008 12.0144 58.1435 12.0144 58.5663V70.2469C12.0144 70.6697 12.3572 71.0124 12.78 71.0124H20.3331C20.7559 71.0124 21.0986 70.6697 21.0986 70.2469V58.5663C21.0986 58.1435 20.7559 57.8008 20.3331 57.8008Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M38.1613 111.509L37.7041 28.456L81.8492 5.96875L125.11 29.2534L125.444 113.189C125.418 114.324 122.301 114.852 121.254 114.841L40.5025 114.075C39.2075 114.063 38.1613 112.919 38.1613 111.509Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M118.526 74.415H110.799C110.424 74.415 110.12 74.7187 110.12 75.0934V86.9292C110.12 87.3038 110.424 87.6075 110.799 87.6075H118.526C118.901 87.6075 119.205 87.3038 119.205 86.9292V75.0934C119.205 74.7187 118.901 74.415 118.526 74.415Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M107.8 74.415H100.209C99.7968 74.415 99.4626 74.7492 99.4626 75.1614V86.8612C99.4626 87.2734 99.7968 87.6075 100.209 87.6075H107.8C108.213 87.6075 108.547 87.2734 108.547 86.8612V75.1614C108.547 74.7492 108.213 74.415 107.8 74.415Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M118.72 89.0723H110.605C110.337 89.0723 110.12 89.2893 110.12 89.5571V101.78C110.12 102.048 110.337 102.265 110.605 102.265H118.72C118.987 102.265 119.205 102.048 119.205 101.78V89.5571C119.205 89.2893 118.987 89.0723 118.72 89.0723Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M107.779 89.0723H100.226C99.8032 89.0723 99.4605 89.415 99.4605 89.8378V101.499C99.4605 101.922 99.8032 102.265 100.226 102.265H107.779C108.202 102.265 108.545 101.922 108.545 101.499V89.8378C108.545 89.415 108.202 89.0723 107.779 89.0723Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M90.3507 24.0117H82.6232C82.2486 24.0117 81.9449 24.3154 81.9449 24.6901V34.0082C81.9449 34.3828 82.2486 34.6865 82.6232 34.6865H90.3507C90.7254 34.6865 91.0291 34.3828 91.0291 34.0082V24.6901C91.0291 24.3154 90.7254 24.0117 90.3507 24.0117Z'
          }
          fill={selected ? theme.palette.primary.main : '#0C2007'}
        />
        <path
          d={
            'M79.6249 24.0117H72.0335C71.6213 24.0117 71.2871 24.3459 71.2871 24.7581V33.9401C71.2871 34.3523 71.6213 34.6865 72.0335 34.6865H79.6249C80.0372 34.6865 80.3713 34.3523 80.3713 33.9401V24.7581C80.3713 24.3459 80.0372 24.0117 79.6249 24.0117Z'
          }
          fill={selected ? theme.palette.primary.main : '#0C2007'}
        />
        <path
          d={
            'M90.5443 35.873H82.4297C82.162 35.873 81.9449 36.0901 81.9449 36.3579V46.063C81.9449 46.3308 82.162 46.5478 82.4297 46.5478H90.5443C90.812 46.5478 91.0291 46.3308 91.0291 46.063V36.3579C91.0291 36.0901 90.812 35.873 90.5443 35.873Z'
          }
          fill={selected ? theme.palette.primary.main : '#0C2007'}
        />
        <path
          d={
            'M79.6037 35.873H72.0505C71.6277 35.873 71.285 36.2158 71.285 36.6386V45.7823C71.285 46.2051 71.6277 46.5478 72.0505 46.5478H79.6037C80.0265 46.5478 80.3692 46.2051 80.3692 45.7823V36.6386C80.3692 36.2158 80.0265 35.873 79.6037 35.873Z'
          }
          fill={selected ? theme.palette.primary.main : '#0C2007'}
        />
        <path
          d={
            'M62.6559 74.373H54.9283C54.5537 74.373 54.25 74.6767 54.25 75.0514V86.9063C54.25 87.281 54.5537 87.5847 54.9283 87.5847H62.6559C63.0305 87.5847 63.3342 87.281 63.3342 86.9063V75.0514C63.3342 74.6767 63.0305 74.373 62.6559 74.373Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M51.93 74.373H44.3386C43.9264 74.373 43.5922 74.7072 43.5922 75.1194V86.8383C43.5922 87.2505 43.9264 87.5847 44.3386 87.5847H51.93C52.3423 87.5847 52.6764 87.2505 52.6764 86.8383V75.1194C52.6764 74.7072 52.3423 74.373 51.93 74.373Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M62.8494 89.0518H54.7348C54.4671 89.0518 54.25 89.2688 54.25 89.5366V101.779C54.25 102.046 54.4671 102.263 54.7348 102.263H62.8494C63.1171 102.263 63.3342 102.046 63.3342 101.779V89.5366C63.3342 89.2688 63.1171 89.0518 62.8494 89.0518Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M51.9109 89.0518H44.3578C43.935 89.0518 43.5922 89.3945 43.5922 89.8173V101.498C43.5922 101.921 43.935 102.263 44.3578 102.263H51.9109C52.3337 102.263 52.6764 101.921 52.6764 101.498V89.8173C52.6764 89.3945 52.3337 89.0518 51.9109 89.0518Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M82.4531 0.510538L128.818 25.256C129.801 25.7813 129.988 26.8466 129.22 27.5547L125.322 31.1484L82.115 8.96956C81.8428 8.82921 81.492 8.82921 81.2176 8.96743L37.1661 31.1952L34.6441 28.1905C33.6064 26.9551 34.0338 25.3028 35.6053 24.4714L80.8795 0.506285C81.3537 0.255364 81.9789 0.255364 82.4531 0.508412V0.510538Z'
          }
          fill={'#9E9E9E'}
        />
        <path
          d={
            'M37.1661 31.5142C37.0747 31.5142 36.9832 31.4738 36.9216 31.3993L34.3996 28.3947C33.868 27.761 33.6575 26.9976 33.8042 26.2448C33.9679 25.407 34.5697 24.6585 35.4543 24.1886L80.7307 0.225584C81.3006 -0.0742459 82.0342 -0.0742459 82.602 0.22771L128.967 24.9732C129.552 25.2837 129.926 25.7876 129.998 26.3512C130.064 26.8721 129.86 27.3974 129.433 27.7886L125.535 31.3823C125.437 31.4738 125.293 31.4929 125.174 31.4312L81.9683 9.25237C81.7875 9.1588 81.543 9.1588 81.3601 9.25237L37.3064 31.4801C37.2618 31.5035 37.2108 31.5142 37.164 31.5142H37.1661ZM81.6642 0.638115C81.4409 0.638115 81.2198 0.68915 81.0284 0.789093L35.7541 24.7521C35.0396 25.1306 34.5569 25.7175 34.4315 26.366C34.323 26.9274 34.4846 27.5016 34.8887 27.9843L37.249 30.7954L81.073 8.68035C81.4388 8.49535 81.8939 8.49535 82.2596 8.6846L125.269 30.7614L129.003 27.3187C129.28 27.0635 129.407 26.7488 129.367 26.432C129.322 26.0747 129.067 25.7494 128.667 25.5367L82.3021 0.791219C82.1108 0.68915 81.8875 0.638115 81.6642 0.638115Z'
          }
          fill={'black'}
          fillOpacity={'0.6'}
        />
        <path
          d={
            'M125.307 104.112C125.133 104.112 124.991 103.971 124.989 103.795L124.559 45.0047C124.559 44.8282 124.699 44.6857 124.876 44.6836H124.878C125.052 44.6836 125.195 44.8239 125.197 45.0004L125.626 103.79C125.626 103.967 125.486 104.109 125.31 104.112H125.307Z'
          }
          fill={'#9E9E9E'}
        />
        <path
          d={
            'M39.8837 59.4107C39.7072 59.4107 39.5647 59.2683 39.5647 59.0918C39.5647 58.9153 39.7072 58.7728 39.8837 58.7728L122.941 58.6367C123.117 58.6367 123.26 58.7792 123.26 58.9557C123.26 59.1322 123.117 59.2747 122.941 59.2747L39.8837 59.4107Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M69.4456 114.469H69.4414L42.8501 114.167C42.6736 114.167 42.5333 114.021 42.5354 113.844C42.5354 113.67 42.68 113.529 42.8544 113.529H42.8586L69.4499 113.831C69.6264 113.831 69.7667 113.978 69.7646 114.154C69.7646 114.329 69.62 114.469 69.4456 114.469Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M115.704 114.826L95.6095 114.805C95.433 114.805 95.2905 114.662 95.2905 114.486C95.2905 114.309 95.433 114.167 95.6095 114.167L115.704 114.188C115.881 114.188 116.023 114.331 116.023 114.507C116.023 114.684 115.881 114.826 115.704 114.826Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M92.2008 64.8164H70.5471C69.8425 64.8164 69.2713 65.3876 69.2713 66.0923V113.45C69.2713 114.155 69.8425 114.726 70.5471 114.726H92.2008C92.9054 114.726 93.4766 114.155 93.4766 113.45V66.0923C93.4766 65.3876 92.9054 64.8164 92.2008 64.8164Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M91.297 66.207H71.3552C70.8854 66.207 70.5046 66.5878 70.5046 67.0576V112.113C70.5046 112.583 70.8854 112.963 71.3552 112.963H91.297C91.7668 112.963 92.1476 112.583 92.1476 112.113V67.0576C92.1476 66.5878 91.7668 66.207 91.297 66.207Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M96.3899 111.328H66.2517C65.9193 111.328 65.6499 111.598 65.6499 111.93V115.079C65.6499 115.412 65.9193 115.681 66.2517 115.681H96.3899C96.7222 115.681 96.9917 115.412 96.9917 115.079V111.93C96.9917 111.598 96.7222 111.328 96.3899 111.328Z'
          }
          fill={'#F3F0FF'}
        />
        <path
          d={
            'M66.2283 116C65.7328 116 65.3309 115.601 65.3309 115.107V111.905C65.3309 111.411 65.7307 111.012 66.224 111.012L85.6726 111.044C85.8491 111.044 85.9915 111.186 85.9915 111.363C85.9915 111.539 85.8491 111.682 85.6726 111.682L66.224 111.65C66.0837 111.65 65.9689 111.764 65.9689 111.905V115.107C65.9689 115.248 66.0837 115.362 66.224 115.362L94.1932 115.101C94.3719 115.109 94.5143 115.241 94.5143 115.418C94.5143 115.594 94.374 115.739 94.1975 115.739L66.2262 116H66.2283Z'
          }
          fill={'#9E9E9E'}
        />
        <path
          d={
            'M70.7598 107.52C70.5833 107.52 70.4408 107.375 70.4429 107.199L70.545 85.894C70.545 85.7175 70.6875 85.5771 70.864 85.5771C71.0405 85.5771 71.1829 85.7217 71.1808 85.8982L71.0787 107.203C71.0787 107.38 70.9363 107.52 70.7598 107.52Z'
          }
          fill={'#9E9E9E'}
        />
        <path
          d={
            'M74.8362 81.3088C74.732 81.3088 74.6278 81.2578 74.5682 81.1621C74.4725 81.0132 74.5151 80.8176 74.6639 80.7219L88.6985 71.7015C88.8474 71.6058 89.0451 71.6483 89.1387 71.7972C89.2344 71.946 89.1918 72.1417 89.043 72.2373L75.0084 81.2578C74.9552 81.2918 74.8957 81.3088 74.8362 81.3088Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M74.2663 87.3289C74.1621 87.3289 74.0579 87.2779 73.9984 87.1822C73.9027 87.0334 73.9452 86.8377 74.094 86.742L87.1483 78.3489C87.2972 78.2533 87.4928 78.2958 87.5885 78.4446C87.6842 78.5935 87.6417 78.7891 87.4928 78.8848L74.4385 87.2779C74.3854 87.3119 74.3258 87.3289 74.2663 87.3289Z'
          }
          fill={'white'}
        />
      </g>
      <defs>
        <clipPath id={'clip0_12445_154489'}>
          <rect width={'166.231'} height={'116'} fill={'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};
