import type { FC } from 'react';
import React from 'react';
import type { DismissedSourceReason } from '@lama/contracts';
import type { DocumentWithIssues } from '@lama/document-service-client';
import { TurnedInDocument } from './TurnedInDocument.js';
import { NoDocument } from './NoDocument.js';
import { DeletedDocument } from './DeletedDocument.js';

interface DocumentProps {
  description?: string;
  document?: DocumentWithIssues;
  onDelete: (e: React.MouseEvent) => void;
  onOpen: (e: React.MouseEvent) => void;
  onFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChange?: (description: string) => Promise<void>;
  onDismissSource?: (name: string) => void;
  onUndismissSource?: (name: string) => void;
  dismissed?: DismissedSourceReason;
  onChangeDocumentName?: (name: string) => Promise<void>;
  onDownloadDocument?: (e: React.MouseEvent) => void;
  dismissible?: boolean;
}

export const DocumentCardContent: FC<DocumentProps> = ({
  onDelete,
  onOpen,
  document,
  onFileSelected,
  description,
  onDismissSource,
  onUndismissSource,
  onChangeDocumentName,
  onDescriptionChange,
  onDownloadDocument,
  dismissed,
  dismissible,
}) => {
  if (document?.status === 'Deleted') {
    return <DeletedDocument document={document} onDownloadDocument={onDownloadDocument} />;
  }

  if (document) {
    return (
      <TurnedInDocument
        onDownloadDocument={onDownloadDocument}
        document={document}
        onDelete={onDelete}
        onOpen={onOpen}
        onChangeDocumentName={onChangeDocumentName}
      />
    );
  }

  if (description) {
    return (
      <NoDocument
        description={description}
        onDescriptionChange={onDescriptionChange}
        onFileSelected={onFileSelected}
        onDismissSource={onDismissSource}
        onUndismissSource={onUndismissSource}
        dismissed={dismissed}
        dismissible={dismissible}
      />
    );
  }

  return null;
};
