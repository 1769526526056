import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { createBusinessServiceClient } from '@lama/clients';
import { businessServiceBaseUrl } from '../../framework/environment';

export const businessServiceClient = createBusinessServiceClient(businessServiceBaseUrl as string);

export const createDataProviderCredentials = async (businessId: string, serviceName: string, token: string): Promise<void> => {
  try {
    const {
      data: { connections },
    } = await axios.patch(
      `${businessServiceBaseUrl}/businesses/${businessId}`,
      {
        dataProviderCredentials: [
          {
            id: uuidv4(),
            credentials: {},
            dataProviderName: serviceName,
            relatedEntityId: businessId,
            relatedEntityType: 'business',
          },
        ],
      },
      {
        headers: { authorization: `Bearer ${token}` },
      },
    );

    return connections;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return;
    }

    throw error;
  }
};
