/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DatePicker as DatePickerInnerComponent } from '@mui/x-date-pickers/DatePicker';
import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import { isValid, setHours } from 'date-fns';
import type { FormikInputProps } from './PropertyFormikInput';
import { HelperText } from './HelperTexts/HelperText';
import { FormikMultiSourceHelperText } from './HelperTexts/FormikMultiSourceHelperText';
import { useSelectedPropertyValue } from './hooks/useSelectedValueSource';

export const FormikDatePicker: FC<FormikInputProps & Partial<DatePickerProps<unknown, Date>> & { disableFuture?: boolean }> = ({
  name,
  label,
  disableFuture = true,
  ...props
}) => {
  // THERE IS A TYPE SAFETY BUG HERE
  // the field's actual initial value type is string.
  // also, the field is used from outside as a string but is actually a date.
  // because the recoil state is saved locally, the serialization of datetime is an ISO string so everything works
  // this is a major type safety bug that should addressed.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ onChange: _onChange, ...restField }, { touched, error }, { setValue: setFormikValue }] = useField<Date | null>(name);
  const [date, setDate] = useState<Date | null>(restField.value ?? null);
  const selectedPropertyValue = useSelectedPropertyValue(props.sourcesValues, date);
  const onChange = useCallback(
    async (pickedDate: Date | null) => {
      setDate(pickedDate);
      // this is a workaround for timezone issues. we only care about the date, not the time.
      if (pickedDate && isValid(pickedDate)) {
        pickedDate = setHours(pickedDate, 12);
      }
      void setFormikValue(pickedDate);
    },
    [setFormikValue],
  );

  const renderInput = useCallback(
    ({ error: _innerError, ...params }: TextFieldProps) => {
      const hasError = touched && !!error;

      const errorInputHelperText = hasError ? error : props.helperText ? <HelperText>{props.helperText}</HelperText> : null;
      const inputHelperText = (
        <HelperText>
          <FormikMultiSourceHelperText selectedPropertyValue={selectedPropertyValue} sourcesValues={props.sourcesValues} />
        </HelperText>
      );
      return (
        <TextField
          {...restField}
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
          }}
          type={'text'}
          error={hasError}
          helperText={errorInputHelperText ?? inputHelperText}
          fullWidth
        />
      );
    },
    [touched, error, props, selectedPropertyValue, restField],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerInnerComponent
        {...props}
        disableFuture={disableFuture}
        label={label}
        value={date}
        onChange={onChange}
        renderInput={renderInput}
      />
    </LocalizationProvider>
  );
};
