import React, { useMemo, useContext, useCallback } from 'react';
import type { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import { useParams } from 'react-router-dom';
import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex } from '@lama/design-system';
import type { ApplicationApiModel } from '@lama/clients';
import { getRequirementsByCategory } from '@lama/app-components';
import { IconBorderBox } from '../../../shared/IconBorderBox';
import { Progress25Icon, Progress50Icon, Progress75Icon } from '../../../../applicationFormComponents/Icons/ProgressIcons';
import { EditIcon } from '../../../../applicationFormComponents/Icons/EditIcon';
import { ApplicationContext } from '../../../../shared/contexts/ApplicationContext';
import { UserDetailsContext } from '../../../../shared/contexts/UserDetailsContext';
import { getUnfulfilledRequirementsCount } from '../../../../shared/utils/requirementUtils';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { SidebarItem } from './SidebarItem';

interface SidebarStep {
  name: string;
  displayName: string;
  icon?: ReactNode;
  disabled?: boolean;
  pendingRequirementsCount?: number;
  route: string;
  requirementIds?: string[];
}

const iconsColor = '#9E9E9E';

const iconsByName: Record<string, ReactNode> = {
  dashboardIcon: <DashboardIcon sx={{ width: 24, height: 24, padding: '1.5px' }} />,
  checkIcon: (
    <IconBorderBox>
      <CheckIcon sx={{ height: '22px', width: '22px', color: iconsColor, padding: '4px' }} />
    </IconBorderBox>
  ),
  progress0Icon: <IconBorderBox> </IconBorderBox>,
  progress25Icon: (
    <IconBorderBox>
      <Box sx={{ margin: '-6px 11px' }}>
        <Progress25Icon />
      </Box>
    </IconBorderBox>
  ),
  progress50Icon: (
    <IconBorderBox>
      <Box sx={{ padding: '2px 11px' }}>
        <Progress50Icon />
      </Box>
    </IconBorderBox>
  ),
  progress75Icon: (
    <IconBorderBox>
      <Box sx={{ padding: '2px 2.5px' }}>
        <Progress75Icon />
      </Box>
    </IconBorderBox>
  ),
  submitIcon: (
    <IconBorderBox>
      <Box sx={{ padding: '0px 5px' }}>
        <EditIcon size={'13'} />
      </Box>
    </IconBorderBox>
  ),
  documentHubIcon: <AssignmentIcon sx={{ width: 24, height: 24, padding: '1.5px' }} />,
};

const getIconForCategory = (requirements: EvaluatedApplicationRequirement[]) => {
  const progress = requirements?.length
    ? (100 * requirements.filter(({ status }) => status === 'Complete').length) / requirements.length
    : 100;

  switch (true) {
    case progress === 0: {
      return iconsByName.progress0Icon;
    }
    case progress < 40: {
      return iconsByName.progress25Icon;
    }
    case progress < 65: {
      return iconsByName.progress50Icon;
    }
    case progress < 100: {
      return iconsByName.progress75Icon;
    }
    case progress === 100: {
      return iconsByName.checkIcon;
    }
    default: {
      return iconsByName.progress0Icon;
    }
  }
};

const getSidebarGroups = ({
  requirements,
  application,
  disableSignAndSubmitForIncompleteApplication,
}: {
  requirements: EvaluatedApplicationRequirement[];
  application: ApplicationApiModel;
  disableSignAndSubmitForIncompleteApplication: boolean;
  inviteeEmail?: string; // TODO: is this really needed?
}): SidebarStep[] => {
  const overviewStep: SidebarStep = {
    name: 'overview',
    displayName: 'Overview',
    icon: iconsByName.dashboardIcon,
    route: 'overview',
  };

  const entitiesSteps: SidebarStep[] = getRequirementsByCategory(requirements, application)
    .filter(({ requirements: categoryRequirements }) => categoryRequirements.length)
    .map(({ categoryLongName, categoryShortName, requirements: categoryRequirements, categoryId }) => ({
      name: categoryShortName,
      displayName: categoryLongName,
      icon: getIconForCategory(categoryRequirements),
      pendingRequirementsCount: getUnfulfilledRequirementsCount(categoryRequirements),
      route: `overview/${categoryId}`,
      requirementIds: categoryRequirements.map(({ id }) => id),
    }));

  const documentHubStep: SidebarStep = {
    name: 'documentHub',
    displayName: 'Document Hub',
    icon: iconsByName.documentHubIcon,
    route: 'documentHub',
  };

  const hasPendingRequirements = requirements.some(({ status }) => status === 'Pending');
  const allRequirementsSubmitted = requirements.every(({ submitted, isInvalidSubmission }) => submitted && !isInvalidSubmission);
  const submitStep: SidebarStep = {
    name: 'signAndSubmit',
    displayName: 'Submit',
    icon: iconsByName.submitIcon,
    disabled: disableSignAndSubmitForIncompleteApplication ? hasPendingRequirements || allRequirementsSubmitted : false,
    route: 'signAndSubmit',
  };

  return [overviewStep, ...entitiesSteps, documentHubStep, submitStep];
};

export const SideNavCategories: FC<{ onCategoryClick?: () => void }> = ({ onCategoryClick }) => {
  const { disableSignAndSubmitForIncompleteApplication } = useFlags();
  const { step, category } = useParams();
  const { requirementId } = useQueryParams(['requirementId']);
  const { requirements, application } = useContext(ApplicationContext);
  const user = useContext(UserDetailsContext);

  const invitee = useMemo(() => application.invitedUsers?.find((email) => email === user?.email), [application.invitedUsers, user?.email]);

  const sidebarGroups = useMemo(
    () =>
      getSidebarGroups({
        requirements,
        application,
        disableSignAndSubmitForIncompleteApplication: Boolean(disableSignAndSubmitForIncompleteApplication),
        inviteeEmail: invitee,
      }),
    [application, disableSignAndSubmitForIncompleteApplication, invitee, requirements],
  );

  const isSidebarItemSelected = useCallback(
    (route: string, requirementIds?: string[]) => {
      const currentRoute = `${step}${category ? `/${category}` : ''}`;
      return currentRoute === route || !!(requirementId && requirementIds?.includes(requirementId));
    },
    [category, requirementId, step],
  );

  return (
    <Flex overflow={'auto'} flex={1} flexDirection={'column'}>
      {sidebarGroups.map(({ name, displayName, icon, disabled, pendingRequirementsCount, route, requirementIds }) => (
        <SidebarItem
          disabled={disabled}
          key={name}
          text={displayName}
          navigateTo={`applications/${application.id}/${route}`}
          selected={isSidebarItemSelected(route, requirementIds)}
          icon={icon}
          count={pendingRequirementsCount}
          onCategoryClick={onCategoryClick}
        />
      ))}
    </Flex>
  );
};
