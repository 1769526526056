import type { EvaluatedApplicationRequirement } from '@lama/contracts';
import type { FC } from 'react';
import { TransactionEstimateItem, DebtItem } from '@lama/app-components';
import { CollateralItem, UseOfFundsBreakdownItem } from './genericListItems';
import { UseOfFundsBreakdownIndication } from './customIndications/UseOfFundsBreakdownIndication';
import { OtherIncomeItem } from './genericListItems/OtherIncomeItem';
import { FinancialReferenceItem } from './genericListItems/FinancialReferenceItem';

export interface RequirementListItemProps {
  item: any;
}

export interface RequirementIndicationProps {
  requirement: EvaluatedApplicationRequirement;
}

export const requirementToIndicationComponent: Record<string, FC<RequirementIndicationProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownIndication,
};

export const requirementToListItemComponent: Record<string, FC<RequirementListItemProps>> = {
  useOfFundsBreakdown: UseOfFundsBreakdownItem,
  debt: DebtItem,
  collateral: CollateralItem,
  otherIncomes: OtherIncomeItem,
  financialReferences: FinancialReferenceItem,
  transactionEstimates: TransactionEstimateItem,
};
