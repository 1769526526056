/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
import { FormikMoneyInputField } from '@lama/app-components';
import { Flex, Text } from '@lama/design-system';
import { CloseRounded, Add } from '@mui/icons-material';
import { Box, Button, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Formik } from 'formik';
import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { ScreenProps } from '../ScreenProps';
import { AuthorizedSignerGroupPicker, signatoryGroups } from '../shared/accountOpening/AuthorizedSignerGroupPicker';
import { BasicScreen } from '../shared/BasicScreen';

const Rule: FC<{ ruleId: string; ruleIndex: number; onRemoveRule: (ruleId: string) => void }> = ({ ruleId, ruleIndex, onRemoveRule }) => {
  const [groupConditions, setGroupConditions] = useState([uuidv4()]);

  const onAddGroupConditionClick = useCallback(() => {
    setGroupConditions((prev) => [...prev, uuidv4()]);
  }, []);

  const onRemoveConditionClick = useCallback((conditionId: string) => {
    setGroupConditions((prev) => prev.filter((condition) => condition !== conditionId));
  }, []);

  const onRemoveRuleClick = useCallback(() => {
    onRemoveRule(ruleId);
  }, [onRemoveRule, ruleId]);

  return (
    <Box
      border={`1px solid ${grey[300]}`}
      borderRadius={'8px'}
      width={'100%'}
      px={2}
      py={4}
      justifyContent={'center'}
      display={'flex'}
      position={'relative'}
    >
      {ruleIndex > 0 ? (
        <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onRemoveRuleClick}>
          <CloseRounded fontSize={'medium'} sx={{ color: grey[300] }} />
        </IconButton>
      ) : null}
      <Flex flexDirection={'column'} gap={8}>
        <Flex flexDirection={'column'} gap={4}>
          <Text variant={'body1'}>{'Transactions amount'}</Text>
          <Flex flexDirection={'row'} gap={4} alignItems={'center'}>
            <FormikMoneyInputField
              label={'From'}
              name={`from_${ruleId}`}
              {...(ruleIndex === 0 ? { value: 0, disabled: true } : undefined)}
            />
            <Text variant={'body1'}>{'-'}</Text>
            <FormikMoneyInputField label={'To'} name={`to_${ruleId}`} placeholder={'Unlimited'} />
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} gap={4}>
          <Text variant={'body1'}>{'Require signatures from'}</Text>
          {groupConditions.map((conditionId, i) => (
            <Flex flexDirection={'row'} gap={4} alignItems={'center'} key={conditionId}>
              <Box sx={{ width: '210px' }}>
                <AuthorizedSignerGroupPicker
                  name={`authorizedSignerGroup_${ruleId}_${conditionId}`}
                  label={'Group'}
                  groups={signatoryGroups}
                  fullWidth
                />
              </Box>
              <Typography variant={'body1'} sx={{ opacity: 0 }}>
                {'-'}
              </Typography>
              <TextField select defaultValue={1} sx={{ minWidth: '56px' }}>
                {Array.from({ length: 9 }).map((__, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
              </TextField>
              <Text variant={'body1'}>{'signatories'}</Text>
              {i > 0 ? (
                <IconButton
                  onClick={() => {
                    onRemoveConditionClick(conditionId);
                  }}
                >
                  <CloseRounded fontSize={'small'} sx={{ color: grey[300] }} />
                </IconButton>
              ) : null}
            </Flex>
          ))}
          <Button variant={'text'} onClick={onAddGroupConditionClick} sx={{ width: '150px', height: '40px' }} startIcon={<Add />}>
            {'Add Condition'}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export const TransactionAuthorizationRulesScreen: FC<ScreenProps> = (props) => {
  const [rules, setRules] = useState([uuidv4()]);

  const onAddRuleClick = useCallback(() => {
    setRules((prev) => [...prev, uuidv4()]);
  }, []);

  const onRemoveRuleClick = useCallback((ruleId: string) => {
    setRules((prev) => prev.filter((rule) => rule !== ruleId));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = useCallback((values: any) => {}, []);

  return (
    <BasicScreen
      {...props}
      title={'Transaction Authorization Rules'}
      subtitle={'Define signatory rules for account transactions'}
      disableIndication
    >
      <Formik initialValues={{}} onSubmit={onSubmit}>
        <Flex flexDirection={'column'} gap={8}>
          {rules.map((ruleId, i) => (
            <Rule key={ruleId} ruleId={ruleId} ruleIndex={i} onRemoveRule={onRemoveRuleClick} />
          ))}
        </Flex>
      </Formik>
      <Box display={'flex'} justifyContent={'center'} pt={6}>
        <Button variant={'outlined'} onClick={onAddRuleClick} sx={{ width: '296px' }} startIcon={<Add />}>
          {'Add Rule'}
        </Button>
      </Box>
    </BasicScreen>
  );
};
