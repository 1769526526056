import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DialogMode } from '@lama/app-components';
import { FormikStatePicker, FormikPicker, BaseDialog, PropertyFormikInput, ModifyItemButton } from '@lama/app-components';
import type { AffiliateApiModel, BusinessApiModel } from '@lama/clients';
import { Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { businessLegalNameValidation, businessTinValidation, zipCodeValidation } from '@lama/yup-validations';
import * as selectors from '@lama/selectors';
import { Flex } from '@lama/design-system';
import type { LabeledValue } from '@lama/contracts';
import { RequirementInput } from '../shared/RequirmentInput';

interface AddOrEditDialogProps {
  open: boolean;
  handleClose: (values: AffiliateApiModel | null) => void;
  initialValues: AffiliateApiModel | null;
  isLoading?: boolean;
  business: BusinessApiModel;
}

interface AffiliateValues {
  legalName: string;
  owner: string;
  tin: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
}

const emptyAffiliate = {
  legalName: '',
  owner: '',
  tin: '',
  address1: '',
  city: '',
  state: '',
  zip: '',
};

export const AddOrEditAffiliateDialog: React.FC<AddOrEditDialogProps> = ({ business, open, handleClose, initialValues, isLoading }) => {
  const { t } = useTranslation();

  const owners = useMemo(() => selectors.ownersSelector(business), [business]);
  const owningBusinesses = useMemo(() => selectors.owningBusinesses(business), [business]);

  const mode: DialogMode = initialValues ? 'edit' : 'add';

  const getOwnerLabel = useCallback(
    (ownerId: string) => {
      if (!ownerId) {
        return '';
      }

      const person = owners.find((principal) => principal.id === ownerId);

      if (person) {
        return selectors.personFullName(person);
      }

      const owner = owningBusinesses.find((b) => b.id === ownerId) ?? (business.id === ownerId ? business : null);

      return owner?.legalName ?? '';
    },
    [business, owningBusinesses, owners],
  );

  const ownersOptions: LabeledValue[] = useMemo(
    () =>
      [...owners.map((principal) => principal.id), business.id, ...owningBusinesses.map((b) => b.id)].map((id) => ({
        value: id,
        label: getOwnerLabel(id),
      })),
    [owners, business.id, owningBusinesses, getOwnerLabel],
  );

  const validationSchema = yup.object({
    legalName: businessLegalNameValidation.required('Legal Name is required'),
    tin: businessTinValidation,
    zip: zipCodeValidation,
  });

  const onSubmitInternal = useCallback(
    (values: AffiliateValues) => {
      const ownerPerson = owners.find((principal) => principal.id === values.owner);
      const ownerBusiness = owningBusinesses.find((b) => b.id === values.owner) ?? business;

      const newValues: AffiliateApiModel = {
        id: initialValues?.id ?? uuidv4(),
        legalName: values.legalName,
        tin: values.tin,
        addresses: [
          {
            address1: values.address1,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: 'US',
          },
        ],
        ...(ownerPerson
          ? { relatedPeople: [{ id: ownerPerson.id, ownershipPercentage: 50 }] }
          : { owningBusinesses: [{ id: ownerBusiness.id, ownershipPercentage: 50 }], relatedPeople: [] }),
      };

      handleClose(newValues);
    },
    [business, handleClose, initialValues?.id, owningBusinesses, owners],
  );

  const initialValuesInner: AffiliateValues = useMemo(
    () =>
      initialValues
        ? {
            id: initialValues.id ?? uuidv4(),
            legalName: initialValues.legalName ?? '',
            tin: initialValues.tin ?? '',
            owner: initialValues.owningBusinesses?.[0].id ?? initialValues.relatedPeople[0].id ?? '',
            ...(initialValues.addresses?.[0] ?? { state: '', country: '', city: '', zip: '', address1: '' }),
          }
        : emptyAffiliate,
    [initialValues],
  );

  return (
    <BaseDialog open={open} onClose={handleClose} title={t(`affiliates.dialog.title.${mode}`)}>
      <Formik validationSchema={validationSchema} initialValues={initialValuesInner} onSubmit={onSubmitInternal}>
        {({ handleSubmit }) => (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'legalName'} label={'Legal Name'} required fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'tin'} label={'Tax ID'} fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikPicker name={'owner'} label={'Owned By'} values={ownersOptions} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <PropertyFormikInput name={'address1'} label={'Address Line'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <PropertyFormikInput name={'city'} label={'City'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <RequirementInput name={'state'} Component={FormikStatePicker} submitted={false} />
            </Grid>
            <Grid item xs={12} md={6}>
              <PropertyFormikInput name={'zip'} label={'Zip Code'} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <Flex flexDirection={'row'} justifyContent={'center'}>
                <ModifyItemButton onClick={handleSubmit} text={t(`principals.dialog.cta.${mode}`)} showIcon={false} loading={isLoading} />
              </Flex>
            </Grid>
          </Grid>
        )}
      </Formik>
    </BaseDialog>
  );
};
