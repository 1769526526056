import * as yup from 'yup';
import { get, set, merge, isObject, isDate, groupBy, isNil, partition, isEmpty } from 'lodash-es';
import type { Entity } from '@lama/common-types';
import { relatedPersonSchema, type Person, type RequirementProperty } from '@lama/contracts';
import { validations } from '@lama/yup-validations';
import flat from 'flat';
import type { SourcedProperty } from '@lama/properties';
import type { AffiliateApiModel, ApplicationApiModel } from '@lama/clients';
import { personRelatedBusinessSelector } from '@lama/selectors';

const arrayPropertyTypes = new Set(['addresses', 'table']);

export const typeToDefaultValidator: Record<string, () => yup.Schema> = {
  number: () => yup.number().typeError('Enter valid number'),
  date: () => yup.date().typeError('Date is not valid'),
};

export const formValuesToEntityPartial = (
  values: Record<string, any>,
  initialValues: Record<string, any>,
  properties: RequirementProperty[],
): Record<string, any> => {
  const flattenedValues = { ...flat(values) } as Record<string, any>;

  return Object.entries(values).reduce((res, [key, value]) => {
    const property = properties.find((p) => p.fieldName === key);

    if (value === get(initialValues, key) || (value === '' && get(initialValues, key) === undefined)) {
      return res;
    }

    if (property?.type === 'object') {
      const childProperties: RequirementProperty[] = property.childProperties ?? [];
      const formattedValue = value
        ? formValuesToEntityPartial(value as Record<string, any>, (initialValues[key] ?? {}) as Record<string, any>, childProperties)
        : undefined;
      set(res, key, formattedValue);
    } else if (isObject(value) && !isDate(value) && !Array.isArray(value)) {
      const relevantValues = Object.fromEntries(Object.entries(flattenedValues).filter(([k]) => k.startsWith(`${key}.`)));
      const formattedObjectValues = formValuesToEntityPartial(relevantValues, initialValues, properties);
      set(res, key, formattedObjectValues[key]);
    } else if (property?.type === 'number' || property?.type === 'currency') {
      set(res, key, value === '' ? null : Number(value));
    } else {
      set(res, key, value);
    }
    return res;
  }, {});
};

export const getInitialFormValues = (itemProperties: SourcedProperty[], entity: any) => {
  const initialValues = itemProperties.reduce((res, property) => {
    let value = property?.selectedPropertyValue?.value ?? property?.initialFormValue;

    if (arrayPropertyTypes.has(property.type) && !value?.length) {
      value = [{}];
    } else if (value === undefined) {
      value = '';
    }
    set(res, property.fieldName, value);
    return res;
  }, {});

  return {
    id: entity?.id,
    ...initialValues,
  };
};

export const getValidationObjectShape = ({
  properties,
  blockOnMissingRequiredFields,
  parentFieldName,
}: {
  properties: RequirementProperty[];
  blockOnMissingRequiredFields?: boolean;
  parentFieldName?: string;
}) => {
  const [propertiesWithParentInFieldName, propertiesWihtouParentInFieldName] = partition(properties, (p) => p.fieldName.includes('.'));

  const groupedByParent = groupBy(propertiesWithParentInFieldName, (p) => p.fieldName.split('.')[0]);

  const parentToChildValidationSchema: Record<string, any> = Object.fromEntries(
    Object.entries(groupedByParent).map(([parent, childProperties]) => [
      parent,
      yup.object(
        getValidationObjectShape({
          properties: childProperties.map((p) => ({ ...p, fieldName: p.fieldName.split('.').slice(1).join('.') })),
          blockOnMissingRequiredFields,
          parentFieldName,
        }),
      ),
    ]),
  );

  const otherFieldsValidationSchema = propertiesWihtouParentInFieldName.reduce<Record<string, any>>(
    (res, { fieldName, type, childProperties, optional, visibilityCondition, matcher }: RequirementProperty) => {
      if (childProperties?.length) {
        const childValidationObject = getValidationObjectShape({
          properties: childProperties,
          blockOnMissingRequiredFields,
          parentFieldName: fieldName,
        });
        if (arrayPropertyTypes.has(type)) {
          set(res, fieldName, yup.array().of(yup.object(childValidationObject)).min(1));
        } else {
          set(res, fieldName, yup.object(childValidationObject));
        }
      } else {
        const fullFieldName = parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName;
        const validatorFn = get(validations, fullFieldName) ?? typeToDefaultValidator[type];
        let validator = validatorFn?.({ required: blockOnMissingRequiredFields });
        if (!optional && blockOnMissingRequiredFields && isNil(visibilityCondition)) {
          validator =
            validator?.required('Required') ??
            (type === 'array'
              ? yup.array().of(yup.string()).min(1, 'At least 1 item is required').required('Required')
              : yup.string().required('Required'));

          if (matcher?.type === 'equals') {
            validator = validator?.oneOf([matcher.value.toString()], 'Must be Checked');
          }
        }

        if (validator) {
          set(res, fieldName, validator);
        }
      }
      return res;
    },
    {},
  );

  return { ...otherFieldsValidationSchema, ...parentToChildValidationSchema };
};

export const getValidationSchema = ({
  properties,
  blockOnMissingRequiredFields,
  parentFieldName,
}: {
  properties: RequirementProperty[];
  blockOnMissingRequiredFields?: boolean;
  parentFieldName?: string;
}) => {
  const validationObject = getValidationObjectShape({ properties, blockOnMissingRequiredFields, parentFieldName });
  return yup.object(validationObject);
};

// TODO: this is a backwards compatibility fix to allow updating related people in the business
const relatedPersonProperties = new Set(Object.keys(relatedPersonSchema.properties).filter((key) => key !== 'id'));

interface UpdatePayload {
  updateOpportunityPayload?: Record<string, any>;
  updateApplicationPayload?: Record<string, any>;
  updateBusinessPayload?: Record<string, any>;
  updatePersonPayload?: Record<string, any>;
}

export const createUpdatePayload = (
  application: ApplicationApiModel,
  entityType: Entity,
  entityId: string,
  updatePayload: Record<string, any>,
): UpdatePayload => {
  switch (entityType) {
    case 'opportunity': {
      return { updateOpportunityPayload: updatePayload };
    }
    case 'application': {
      return { updateApplicationPayload: updatePayload };
    }
    case 'business': {
      return { updateBusinessPayload: updatePayload };
    }
    case 'person': {
      const [relatedPersonUpdate, personUpdate] = partition(Object.entries(updatePayload), ([key]) => relatedPersonProperties.has(key)).map(
        (x) => Object.fromEntries(x),
      );

      let businessUpdate = {};

      if (!isEmpty(relatedPersonUpdate)) {
        const business = personRelatedBusinessSelector(application, entityId)?.business;

        if (business) {
          const updatedPeople = business.people.map((person) =>
            person.id === entityId ? (merge({}, person, relatedPersonUpdate, { id: entityId }) as Person) : person,
          );

          businessUpdate = { people: updatedPeople };
        }
      }

      return { updateBusinessPayload: businessUpdate, updatePersonPayload: personUpdate };
    }
    case 'affiliate': {
      const business = application.relatedBusinesses.find(({ business: { affiliates } }) =>
        affiliates?.some(({ id }) => id === entityId),
      )?.business;

      if (!business?.affiliates) {
        return {};
      }

      const updatedAffiliates = business.affiliates.map((affiliate) =>
        affiliate.id === entityId ? (merge({}, affiliate, updatePayload, { id: entityId }) as AffiliateApiModel) : affiliate,
      );

      return { updateBusinessPayload: { affiliates: updatedAffiliates } };
    }
  }
};

export const getBusinessIdByEntity = (application: ApplicationApiModel, entityType: Entity, entityId: string) => {
  switch (entityType) {
    case 'business': {
      return entityId;
    }
    case 'person': {
      return personRelatedBusinessSelector(application, entityId)?.business.id;
    }
    case 'affiliate': {
      return application.relatedBusinesses.find(({ business: { affiliates } }) =>
        affiliates?.some(({ id: affiliateId }) => affiliateId === entityId),
      )?.business.id;
    }
    default: {
      return;
    }
  }
};
