import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { SendOtpRequestBody } from '@lama/user-service-client';
import { useCallback } from 'react';
import { displayToast } from '@lama/app-components';
import { userServiceClient } from '../../shared/clients/userServiceClient';

interface SendOtpParams {
  sendOtpRequest: SendOtpRequestBody;
}

export const useSendOtpMutation = (options?: UseMutationOptions<void, unknown, SendOtpParams>) => {
  const sendOtpMutation = useCallback(async ({ sendOtpRequest }: SendOtpParams) => userServiceClient.sendOtp(sendOtpRequest), []);

  return useMutation({
    mutationFn: sendOtpMutation,
    onError: () => {
      displayToast('Something went wrong while sending OTP. Please contact support.', 'error');
    },
    ...options,
  });
};
