import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';
import { groupBy, sortBy, get, intersection, set, orderBy, isNil } from 'lodash-es';
import type { FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import { Divider } from '@mui/material';
import type { Entity } from '@lama/common-types';
import { type SourcedProperty } from '@lama/properties';
import { Flex } from '@lama/design-system';
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import * as selectors from '@lama/selectors';
import { PropertyComponent } from './PropertyComponent.js';

interface SourcedPropertyWithVisibilityAndIndex extends SourcedPropertyWithVisibility {
  index: number;
}

const createPredefinedRows = (properties: SourcedPropertyWithVisibilityAndIndex[]): SourcedPropertyWithVisibilityAndIndex[][] => {
  const rows: SourcedPropertyWithVisibilityAndIndex[][] = [];
  const propertiesWithPredefinedRow = properties.filter((p) => p.row);
  const groupedPredefinedRows = groupBy(propertiesWithPredefinedRow, (p) => p.row);
  Object.values(groupedPredefinedRows).forEach((rowGroup) => {
    rows.push(rowGroup);
  });

  return rows;
};

const createDynamicRows = (propertiesWithIndex: SourcedPropertyWithVisibilityAndIndex[]): SourcedPropertyWithVisibilityAndIndex[][] => {
  const propertiesWithoutPredefinedRow = propertiesWithIndex.filter((p) => !p.row);
  return (
    propertiesWithoutPredefinedRow.reduce<SourcedPropertyWithVisibilityAndIndex[][]>((rows, currentProperty) => {
      const lastRow = rows.at(-1);
      if (currentProperty.size === 'large') {
        return [...rows, [currentProperty]];
      } else if (lastRow && lastRow.length < 2 && lastRow[0]?.size !== 'large') {
        lastRow.push(currentProperty);
        return rows;
      }

      return [...rows, [currentProperty]];
    }, []) ?? []
  );
};

const splitPropertiesIntoRows = (properties: SourcedPropertyWithVisibility[]): SourcedPropertyWithVisibility[][] => {
  // we remember the index of each property so we can sort the rows by index later
  const propertiesWithIndex = properties.map((p, index) => ({ ...p, index }));

  const predefinedRows = createPredefinedRows(propertiesWithIndex);
  const dynamicRows = createDynamicRows(propertiesWithIndex);
  const propertyRows = [...predefinedRows, ...dynamicRows];

  return orderBy(propertyRows, (row) => row[0]?.index);
};

interface SourcedPropertyWithVisibility extends SourcedProperty {
  visible: boolean;
}

interface PropertiesRowProps {
  properties: SourcedPropertyWithVisibility[];
  submitted?: boolean;
  entityType: Entity;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
}

const getVisibilityEntity = (
  visibilityCondition: SourcedProperty['visibilityCondition'],
  application: ApplicationApiModel,
  opportunity?: OpportunityApiModel,
  requirementEntity?: Record<string, any>,
) => {
  if (!visibilityCondition?.entityType) {
    return requirementEntity;
  }

  if (visibilityCondition.entityType === 'application') {
    return application;
  }

  if (visibilityCondition.entityType === 'opportunity' && opportunity) {
    return opportunity;
  }

  return requirementEntity;
};

export const shouldShowConditionedProperty = (
  { visibilityCondition }: SourcedProperty,
  entity: Record<string, any>,
  values: Record<string, any>,
  application: ApplicationApiModel,
  opportunity?: OpportunityApiModel,
) => {
  if (!visibilityCondition) {
    return true;
  }

  const relatedPerson = application.relatedPeople.find(({ person }) => person.id === entity.id);
  const relatedBusiness = application.relatedBusinesses.find(({ business }) => business.id === entity.id);

  if (
    relatedPerson &&
    visibilityCondition.entityGroup &&
    relatedPerson.relations.every((relation) => relation !== visibilityCondition.entityGroup)
  ) {
    return false;
  }

  if (
    relatedBusiness &&
    visibilityCondition.entityGroup &&
    relatedBusiness.relations.every((relation) => relation !== visibilityCondition.entityGroup)
  ) {
    return false;
  }

  const visibilityEntity = getVisibilityEntity(visibilityCondition, application, opportunity, entity);

  const valueFromEntityOrForm =
    get(values, visibilityCondition?.fieldName) ??
    get(visibilityEntity, visibilityCondition?.fieldName) ??
    get(selectors, visibilityCondition?.fieldName)?.(visibilityEntity);

  // This is a hack until visibility conditions are updated to "matcher" schema
  if (Array.isArray(visibilityCondition.value) || Array.isArray(valueFromEntityOrForm)) {
    return (
      intersection(
        Array.isArray(valueFromEntityOrForm) ? valueFromEntityOrForm : [valueFromEntityOrForm],
        Array.isArray(visibilityCondition.value) ? visibilityCondition.value : [visibilityCondition.value],
      ).length > 0
    );
  }

  if (visibilityCondition.type === 'greaterThan') {
    return !isNil(visibilityCondition.value) && valueFromEntityOrForm > visibilityCondition.value;
  }

  if (visibilityCondition.type === 'lessThan') {
    return !isNil(visibilityCondition.value) && valueFromEntityOrForm < visibilityCondition.value;
  }

  return valueFromEntityOrForm === visibilityCondition.value;
};

const PropertiesRow: FC<PropertiesRowProps> = ({ properties, submitted, entityType, application }) => (
  <Flex flexDirection={'row'} gap={4}>
    {properties.map((p) => (
      <PropertyComponent key={p.fieldName} property={p} submitted={submitted} entityType={entityType} application={application} />
    ))}
  </Flex>
);

interface PropertyGroupProps {
  name: string;
  properties: SourcedProperty[];
  entityType: Entity;
  entity: Record<string, any>;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  submitted?: boolean;
}

const PropertyGroup: FC<PropertyGroupProps> = ({ name, properties, entityType, entity, application, opportunity, submitted }) => {
  const { values } = useFormikContext<FormikValues>();

  const propertiesWithVisibilityResult = useMemo(
    () => properties.map((p) => ({ ...p, visible: shouldShowConditionedProperty(p, entity, values, application, opportunity) })),
    [entity, properties, values, application, opportunity],
  );

  const visibleProperties = useMemo(() => propertiesWithVisibilityResult.filter((p) => p.visible), [propertiesWithVisibilityResult]);

  const visiblePropertyRows = useMemo(() => splitPropertiesIntoRows(visibleProperties), [visibleProperties]);

  useEffect(() => {
    propertiesWithVisibilityResult.filter((p) => !p.visible).forEach((p) => set(values, p.fieldName, undefined));
  }, [propertiesWithVisibilityResult, values]);

  return (
    <Flex flexDirection={'column'} key={name} gap={4} width={'100%'}>
      {visiblePropertyRows.map((row) => (
        <PropertiesRow
          properties={row}
          entityType={entityType}
          submitted={submitted}
          key={row.map((r) => r.fieldName).join('_')}
          application={application}
          opportunity={opportunity}
        />
      ))}
    </Flex>
  );
};

export const GenericPropertiesGrid: FC<{
  properties: SourcedProperty[];
  submitted?: boolean;
  initialValue?: any;
  entityType: Entity;
  entity: Record<string, any>;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
}> = ({ properties, ...otherProps }) => {
  const sortedGroups = useMemo(() => {
    const groupedProperties = groupBy(properties, (p) => p.group ?? 'z');
    return sortBy(Object.entries(groupedProperties), ([group]) => group);
  }, [properties]);

  return (
    <Flex flexDirection={'column'} gap={6} width={'100%'}>
      {sortedGroups.map(([group, groupProperties], index) => (
        <Flex flexDirection={'column'} key={group} gap={6}>
          <PropertyGroup name={group} properties={groupProperties} {...otherProps} />
          {index !== sortedGroups.length - 1 ? <Divider /> : null}
        </Flex>
      ))}
    </Flex>
  );
};
