import { useCallback, useContext } from 'react';
import type { ModalText } from './ConfirmationModalContext.js';
import { ConfirmationModalContext } from './ConfirmationModalContext.js';

export const useConfirmModal = () => {
  const { startConfirmation, setModalText } = useContext(ConfirmationModalContext);

  const confirm = useCallback(
    (modalText: ModalText) => {
      setModalText(modalText);

      return startConfirmation();
    },
    [setModalText, startConfirmation],
  );

  return {
    confirm,
  };
};
