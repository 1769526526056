import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { StyledInputLabel, StyledLabelCover } from './Styles/InputLabel.styles';
import type { StylingProps } from './Styles/Input.styles';

export const InputLabel: FC<StylingProps> = ({ label, focused, ...props }) => {
  const labelRef = React.useRef<HTMLSpanElement>(null);
  const [coverWidth, setCoverWidth] = useState(0);

  useEffect(() => {
    if (labelRef.current) {
      setCoverWidth(labelRef.current.getBoundingClientRect().width);
    }
  }, [label]);

  return label ? (
    <>
      <StyledInputLabel ref={labelRef} focused={focused} label={label} ellipsis {...props}>
        {label}
      </StyledInputLabel>
      <StyledLabelCover focused={focused} coverWidth={coverWidth} {...props} />
    </>
  ) : null;
};
