import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import { NoDebtImage } from './NoDebtImage';

export const DebtEmptyState: FC = () => (
  <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
    <NoDebtImage />
  </Flex>
);
