import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import React, { useContext } from 'react';
import { Button, Flex } from '@lama/design-system';
import { ConfirmationModalContext } from './ConfirmationModalContext.js';

export const ConfirmationModal: React.FC = () => {
  const { modalOpen, cancel, confirm, modalText } = useContext(ConfirmationModalContext);

  return (
    <Dialog
      open={modalOpen}
      onClose={cancel}
      aria-labelledby={'alert-dialog-title'}
      aria-describedby={'alert-dialog-description'}
      disableEnforceFocus
    >
      <Flex flexDirection={'column'} minWidth={400}>
        <DialogTitle>{modalText.title}</DialogTitle>
        {modalText.message ? (
          <DialogContent>
            <DialogContentText>{modalText.message}</DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button variant={'tertiary'} color={'neutral'} onClick={cancel}>
            {modalText.cancelText}
          </Button>
          <Button variant={'tertiary'} onClick={confirm}>
            {modalText.confirmText}
          </Button>
        </DialogActions>
      </Flex>
    </Dialog>
  );
};
