import type { Connection } from '@lama/contracts';
import axios from 'axios';
import { accountingsServiceBaseUrl } from '../../framework/environment';

export const getConnections = async (businessId: string, token: string): Promise<Connection[]> => {
  try {
    const {
      data: { connections },
    } = await axios.get<{ connections: Connection[] }>(`${accountingsServiceBaseUrl}/accountings/${businessId}/connections`, {
      headers: { authorization: `Bearer ${token}` },
    });

    return connections;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return [];
    }

    throw error;
  }
};

export const disableConnection = async (businessId: string, connectionId: string, token: string): Promise<void> => {
  await axios.delete(`${accountingsServiceBaseUrl}/accountings/${businessId}/connections/${connectionId}`, {
    headers: { authorization: `Bearer ${token}` },
  });
};
