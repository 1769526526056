/* eslint-disable react/jsx-no-useless-fragment */
import type { AffiliateApiModel, BusinessApiModel, PersonApiModel } from '@lama/clients';

import React, { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';

import type { Entity } from '@lama/common-types';
import { Flex } from '@lama/design-system';
import { Toggle } from '../Toggle';
import { SelectOrCreateEntity } from './SelectOrCreateEntity';
import type { GenericAddRelationComponentProps } from './types';
import { EntityForm } from './EntityForm';
import { SearchExistingCustomer } from './SearchExistingCustomers';

type Step = 'form' | 'search' | 'selection';

interface EntityFormContainerProps extends GenericAddRelationComponentProps {
  step: Step;
  onBack: () => void;
}

const EntityFormContainer = (props: EntityFormContainerProps) => {
  const { relation, showEntityTypeToggle, onToggleChange, entityType } = props;
  if (relation === 'property') {
    return <EntityForm {...props} />;
  }

  return (
    <Flex minHeight={'100%'} fullWidth height={'100%'} gap={6} alignItems={'center'} flexDirection={'column'}>
      {showEntityTypeToggle ? (
        <Toggle
          onChange={onToggleChange}
          optionA={{ value: true, text: 'Person' }}
          optionB={{ value: false, text: 'Business' }}
          value={entityType === 'person'}
        />
      ) : null}
      <EntityForm {...props} entityType={entityType} />
    </Flex>
  );
};

export const AddOrCreateRelatedEntity: FC<GenericAddRelationComponentProps> = (props) => {
  const {
    application,
    existingEntityIds,
    submitHandler,
    isLoading,
    relation,
    onClose,
    searchButtonVisible,
    customForm: CustomForm,
    onSearchResultConverted,
  } = props;
  const [step, setStep] = useState<Step>('selection');
  const onSearchSelected = useCallback(() => {
    setStep('search');
  }, []);
  const onBack = useCallback(() => {
    setStep('selection');
  }, []);
  const onEntitySelected = useCallback(
    async (selectedEntity?: AffiliateApiModel | BusinessApiModel | PersonApiModel, entityType?: Entity) => {
      if (selectedEntity && entityType) {
        await submitHandler(selectedEntity, entityType);
      } else {
        setStep('form');
      }
    },
    [submitHandler],
  );

  const CurrentView = useMemo(() => {
    switch (step) {
      case 'form': {
        return CustomForm ? () => <CustomForm onBack={onBack} /> : () => <EntityFormContainer {...props} onBack={onBack} step={step} />;
      }
      case 'search': {
        return () => <SearchExistingCustomer onSearchResultConverted={onSearchResultConverted} onClose={onClose} onBack={onBack} />;
      }
      default: {
        return () => (
          <SelectOrCreateEntity
            onEntitySelected={onEntitySelected}
            excludedEntityIds={existingEntityIds}
            application={application}
            loading={!!isLoading}
            createdRelationName={relation}
            searchEnabled={searchButtonVisible}
            onSearchSelected={onSearchSelected}
          />
        );
      }
    }
  }, [
    step,
    CustomForm,
    props,
    onBack,
    onClose,
    onEntitySelected,
    existingEntityIds,
    application,
    isLoading,
    relation,
    searchButtonVisible,
    onSearchSelected,
    onSearchResultConverted,
  ]);

  return (
    <Flex height={'100%'} overflow={'hidden'} fullWidth flexDirection={'column'}>
      <CurrentView />
    </Flex>
  );
};
