import React, { useCallback } from 'react';
import type { Editor } from '@tiptap/react';
import type { FC } from 'react';
import styled from 'styled-components';
import { Flex } from '../Flex';
import { greyPalette } from '../../theme';
import { FormatBoldIcon } from './assets/FormatBoldIcon.js';
import { FormatItalicIcon } from './assets/FormatItalicIcon.js';
import { FormatListNumberedIcon } from './assets/FormatListNumberedIcon.js';
import { FormatListBulletedIcon } from './assets/FormatListBulletedIcon.js';

interface MenuItemProps {
  isActive: boolean;
}

const MenuItem = styled(Flex)<MenuItemProps>`
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${greyPalette[100]};
  }
  border-radius: 4px;
  width: 28px;
  height: 28px;
  background-color: ${({ isActive }) => (isActive ? greyPalette[100] : 'transparent')};
`;

interface MenuBarProps {
  editor: Editor;
}

export const MenuBar: FC<MenuBarProps> = ({ editor }) => {
  const onBoldClick = useCallback(() => editor.chain().focus().toggleBold().run(), [editor]);
  const onItalicClick = useCallback(() => editor.chain().focus().toggleItalic().run(), [editor]);
  const onBulletListClick = useCallback(() => editor.chain().focus().toggleBulletList().run(), [editor]);
  const onOrderedListClick = useCallback(() => editor.chain().focus().toggleOrderedList().run(), [editor]);

  return (
    <Flex gap={2} flexWrap={'wrap'}>
      <MenuItem onClick={onBoldClick} isActive={editor.isActive('bold')}>
        <FormatBoldIcon />
      </MenuItem>
      <MenuItem onClick={onItalicClick} isActive={editor.isActive('italic')}>
        <FormatItalicIcon />
      </MenuItem>
      <MenuItem onClick={onBulletListClick} isActive={editor.isActive('bulletList')}>
        <FormatListBulletedIcon />
      </MenuItem>
      <MenuItem onClick={onOrderedListClick} isActive={editor.isActive('orderedList')}>
        <FormatListNumberedIcon />
      </MenuItem>
    </Flex>
  );
};
