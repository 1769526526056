import styled, { css } from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { height } from 'styled-system';
import type { InputProps } from '../Input.component';
import { Flex } from '../../Flex';
import type { Theme } from '../../../theme';

export type InputSizes = 'l';

export const inputPadding: Record<InputSizes, string> = {
  l: '14px',
};

const inputFontSize: Record<InputSizes, string> = {
  l: '16px',
};

export type StylingProps = Partial<{ focused: boolean }> & Partial<InputProps> & Partial<Theme>;

const focusedStyles = ({ disabled, error, focused }: StylingProps) =>
  !error &&
  !disabled &&
  focused &&
  css`
    &:focus {
      border: ${({ theme }) => `1px solid ${theme.input.focused.borderColor}`};
      box-shadow: 0px 4px 20px 0px rgba(36, 0, 172, 0.08);
    }
  `;

const disabledStyles = ({ disabled }: StylingProps) =>
  disabled &&
  css`
    background-color: ${({ theme }) => theme.input.disabled.backgroundColor};
    border: ${({ theme }) => `1px solid ${theme.input.disabled.borderColor}`};
    color: ${({ theme }) => theme.input.disabled.textColor};

    ::placeholder {
      color: ${({ theme }) => theme.input.disabled.textColor};
    }

    cursor: not-allowed;
  `;

const errorStyles = ({ error, disabled }: StylingProps) =>
  !disabled &&
  error &&
  css`
    border: ${({ theme }) => `1px solid ${theme.input.error.borderColor}`};
    &:focus {
      box-shadow: 0px 4px 20px 0px rgba(139, 25, 25, 0.08);
    }
  `;

const fullWidthStyles = ({ fullWidth }: StylingProps) =>
  fullWidth &&
  css`
    width: 100%;
  `;

export const StyledInputContainer = styled(Flex)<StylingProps>`
  position: relative;
  border-radius: 8px;

  font-size: ${() => inputFontSize.l};

  ${fullWidthStyles}
  ${height}
`;

const inputStyles = css<StylingProps>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;
  width: auto;
  position: relative;
  border-radius: 8px;
  padding: ${() => inputPadding.l};
  font-size: ${() => inputFontSize.l};
  font-family: 'Poppins';
  border: ${({ theme }) => `1px solid ${theme.input.borderColor}`};
  width: 'auto';
  color: ${({ theme }) => theme.input.textColor};

  ::placeholder {
    font-size: ${() => inputFontSize.l};
    font-family: 'Poppins';
    color: ${({ theme }) => theme.input.textColor};
  }

  outline: none;

  ${fullWidthStyles}
  ${focusedStyles}
  ${errorStyles}
  ${disabledStyles}
  ${height}
`;

export const StyledInput = styled.input<StylingProps>`
  ${inputStyles}
`;

export const StyledNumberInput = styled(NumericFormat)<StylingProps>`
  ${inputStyles}
`;
