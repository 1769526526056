import React, { useContext, useMemo } from 'react';
import { BasePrincipalsScreen, SearchExistingCustomersProvider } from '@lama/app-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useUpdateApplication } from '../../hooks/react-query/useUpdateApplication';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { useInviteToApplication } from '../../hooks/react-query/useInviteToApplication';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { useUpdatePerson } from '../../hooks/react-query/useUpdatePerson';
import { useCreatePerson } from '../../hooks/react-query/useCreatePerson';
import { useCreateBusinessMutation } from '../../hooks/react-query/useCreateBusinessMutation';

interface OwnersScreenProps extends ScreenProps {
  title?: string;
}

export const OwnersScreen: React.FC<OwnersScreenProps> = ({
  flow,
  onNextClick,
  onBackClick,
  nextLoading,
  title,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);
  const requirement = useGetCurrentRequirement();
  const { mutateAsync: updateApplication, isPending: updatingApplication } = useUpdateApplication(application.id);
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const { mutateAsync: createBusiness, isPending: creatingBusiness } = useCreateBusinessMutation();
  const { mutateAsync: updatePerson, isPending: updatingPerson } = useUpdatePerson(application.id);
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson({ applicationId: application.id });
  const { mutateAsync: inviteToApplication } = useInviteToApplication(application.id);
  const { addExistingEntityEnabled } = useFlags();

  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement?.entityId)?.business,
    [application.relatedBusinesses, requirement?.entityId],
  );

  const loading = useMemo(
    () => updatingApplication || updatingBusiness || creatingBusiness || nextLoading || updatingPerson || creatingPerson,
    [creatingPerson, creatingBusiness, nextLoading, updatingApplication, updatingBusiness, updatingPerson],
  );

  if (!business) {
    return null;
  }

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      title={title?.length ? title : requirement?.name}
      subtitle={requirement?.description}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
      nextLoading={loading}
      nextVisible={flow === 'onboarding'}
    >
      <SearchExistingCustomersProvider searchEnabled={false} searchButtonVisible={false}>
        <BasePrincipalsScreen
          loading={loading}
          business={business}
          application={application}
          requirementProperties={requirement?.properties ?? []}
          createPerson={createPerson}
          updatePerson={updatePerson}
          createBusiness={createBusiness}
          updateBusiness={updateBusiness}
          updateApplication={updateApplication}
          inviteToApplication={inviteToApplication}
          addExistingEntityEnabled={addExistingEntityEnabled}
          disableEmailUpdate
        />
      </SearchExistingCustomersProvider>
    </BasicScreen>
  );
};
