import React, { useMemo } from 'react';
import type { FC } from 'react';
import type { FormikValues } from 'formik';
import { useField, useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { YesNoQuestion } from '../YesNoQuestion/YesNoQuestion';

interface FormikYesNoQuestionProps {
  name: string;
  question: string;
  required?: boolean;
}

export const FormikYesNoQuestion: FC<FormikYesNoQuestionProps> = ({ name, question, required }) => {
  const [, , { setValue: setFormikValue }] = useField<boolean | undefined>(name);
  const { values } = useFormikContext<FormikValues>();

  const value = useMemo(() => get(values, name), [name, values]);

  return <YesNoQuestion onChange={setFormikValue} name={name} value={value} question={question} required={required} />;
};
