import React from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Avatar, ItemActions } from '@lama/app-components';
import { Flex, Text, grayPalette } from '@lama/design-system';

interface AffiliateCardProps {
  name: string;
  ownerName: string;
  deleteEnabled: boolean;
  inviteAllowed: boolean;
  onDelete?: (id: string) => void;
  onEdit: (id: string) => void;
  id: string;
}

export const AffiliateCard: React.FC<AffiliateCardProps> = ({ name, ownerName, deleteEnabled, inviteAllowed, onDelete, onEdit, id }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Flex
      gap={4}
      height={'100px'}
      px={isMobile ? 5 : 8}
      py={6}
      border={`1px solid ${grayPalette[300]}`}
      borderRadius={'4px'}
      flexDirection={'row'}
      width={'100%'}
      justifyContent={'space-between'}
    >
      <Flex flexDirection={'row'} alignItems={'center'} gap={4} minWidth={0}>
        <Avatar firstName={name} lastName={''} />
        <Flex flexDirection={'column'} minWidth={0}>
          <Tooltip title={name} placement={'top'}>
            <Text variant={'h6'} ellipsis>
              {name}
            </Text>
          </Tooltip>
          <Text variant={'body2'}>{`Owned by ${ownerName}`}</Text>
        </Flex>
      </Flex>
      <ItemActions
        item={{ id }}
        onDeleteClick={deleteEnabled ? onDelete : undefined}
        onEditClick={onEdit}
        deleteEnabled={deleteEnabled}
        inviteAllowed={inviteAllowed}
        itemType={'business'}
      />
    </Flex>
  );
};
