import type { DocumentWithIssues } from '@lama/document-service-client';
import { Flex, greyPalette } from '@lama/design-system';
import { isNil } from 'lodash-es';
import styled from 'styled-components';

const activeBorderStyles = {
  borderWidth: '2px',
  borderColor: '#0000001F',
  borderStyle: 'solid',
};

const disabledBorderStyles = {
  borderWidth: '2px',
  borderColor: greyPalette[300],
  borderStyle: 'solid',
};

const dashedBoxBorderStyle = {
  backgroundImage:
    'repeating-linear-gradient(-21deg, #eee, #eee 13px, transparent 13px, transparent 26px, #eee 26px), repeating-linear-gradient(69deg, #eee, #eee 13px, transparent 13px, transparent 26px, #eee 26px), repeating-linear-gradient(159deg, #eee, #eee 13px, transparent 13px, transparent 26px, #eee 26px), repeating-linear-gradient(249deg, #eee, #eee 13px, transparent 13px, transparent 26px, #eee 26px)',
  backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundRepeat: 'no-repeat',
};

interface DocumentCardContainerProps {
  isDragActive: boolean;
  document?: DocumentWithIssues;
  documentDeleted?: boolean;
  loading?: boolean;
}

export const DocumentCardContainer = styled(Flex)<DocumentCardContainerProps>`
  align-items: center;
  user-select: none;
  padding: ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[3]}px;
  border-radius: 4px;
  ${({ document, loading }) => {
    if (document?.status === 'Deleted') {
      return disabledBorderStyles;
    }
    return !isNil(document) || loading ? activeBorderStyles : dashedBoxBorderStyle;
  }};
  height: ${({ document, isDragActive }) => (isDragActive && isNil(document) ? '100px' : 'auto')};
  cursor: ${({ document, documentDeleted }) => (document && !documentDeleted ? 'pointer !important' : 'default !important')};
`;
