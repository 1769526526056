/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useBeforeUnload, unstable_useBlocker as useBlocker } from 'react-router-dom';

export const usePrompt = (
  message: string,
  shouldBlock: boolean,
  { beforeUnload, onConfirm }: { beforeUnload?: boolean; onConfirm?: () => void } = {},
) => {
  // @ts-ignore
  if (import.meta?.env?.MODE !== 'test') {
    // eslint-disable-next-line no-alert
    const blocker = useBlocker(React.useCallback(() => (shouldBlock ? !window.confirm(message) : false), [message, shouldBlock]));
    const prevState = React.useRef(blocker.state);
    React.useEffect(() => {
      if (blocker.state === 'blocked') {
        blocker.reset();
        onConfirm?.();
      }
      prevState.current = blocker.state;
    }, [blocker, onConfirm]);

    useBeforeUnload(
      React.useCallback(
        (event) => {
          if (beforeUnload && shouldBlock) {
            event.preventDefault();
            event.returnValue = message;
          }
        },
        [beforeUnload, shouldBlock, message],
      ),
      { capture: true },
    );
  }
};
