import type { FC } from 'react';
import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useSetRecoilState } from 'recoil';
import type { ApplicationStatus, ProductPublicData, Screen } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { t } from 'i18next';
import { Flex } from '@lama/design-system';
import { LoadingPage } from '@lama/app-components';
import { screenMap, managementScreens } from '../screensConfiguration';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import { ErrorScreen } from '../errorScreen/ErrorScreen';
import { useScreenNameFromUrl } from '../../hooks/useScreenNameFromUrl';
import { useApplicationQuery } from '../../hooks/react-query/useApplicationQuery';
import { useApplicationRequirementsQuery } from '../../hooks/react-query/useApplicationRequirementsQuery';
import { ApplicationProvider } from '../../shared/contexts/ApplicationContext';
import { useProductQuery } from '../../hooks/react-query/useProductQuery';
import { ApplicationRejectedScreen } from '../applicationRejected/ApplicationRejectedScreen';
import { currentOnboardingApplicationIdState } from '../../state/appState';
import { useLoadDemoChat } from '../../framework/loadDemoChat';
import { OnboardingIncompleteScreen } from '../onboardingIncomplete/OnboardingIncompleteScreen';
import { InactiveApplicationScreen } from '../inactiveApplication/InactiveApplicationScreen';
import { Layout } from './Layout';

const customComponentForStatus: Partial<Record<ApplicationStatus, FC<{ application: ApplicationApiModel; product: ProductPublicData }>>> = {
  Rejected: ApplicationRejectedScreen,
  ExpresslyWithdrawn: () => <InactiveApplicationScreen title={t('inactiveApplication.withdrawn')} />,
  WithdrawalOfApprovedApplication: () => <InactiveApplicationScreen title={t('inactiveApplication.withdrawn')} />,
  Cancelled: () => <InactiveApplicationScreen title={t('inactiveApplication.cancelled')} />,
  ApprovedNotAccepted: () => <InactiveApplicationScreen title={t('inactiveApplication.cancelled')} />,
  Closed: () => <InactiveApplicationScreen title={t('inactiveApplication.closed')} />,
};

export const ManagementScreenInner: React.FC = () => {
  const { applicationId } = useParams();
  const { pathname } = useLocation();
  useLoadDemoChat();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { data: application, isPending: loadingApplication, error: errorFetchingApplication } = useApplicationQuery(applicationId);
  const {
    data: requirements,
    isPending: loadingRequirements,
    error: errorFetchingRequirements,
  } = useApplicationRequirementsQuery(applicationId);
  const setCurrentOnboardingApplicationId = useSetRecoilState(currentOnboardingApplicationIdState);

  const { data: product, isPending: loadingProduct, error: errorFetchingProduct } = useProductQuery(application?.leadingProductId);

  useEffectOnce(() => {
    setCurrentOnboardingApplicationId(null);
  });

  const currentScreen = useScreenNameFromUrl();

  if (loadingRequirements || loadingApplication || loadingProduct) {
    return <LoadingPage />;
  }

  if (!!errorFetchingApplication || !!errorFetchingRequirements || !!errorFetchingProduct) {
    return <ErrorScreen />;
  }

  if (!product?.screensConfiguration || !application || !requirements || !product) {
    return <Navigate to={'/'} />;
  }

  if (product.requireOnboardingCompletedForManagement && !application.onboardingComplete) {
    return <OnboardingIncompleteScreen product={product} applicationId={application.id} />;
  }

  if (!currentScreen || !managementScreens.includes(currentScreen as Screen) || !screenMap[currentScreen as Screen]) {
    return <Navigate to={`${pathname}/../overview`} />;
  }

  const CustomComponentForStatus = customComponentForStatus[application.status];
  if (CustomComponentForStatus) {
    return <CustomComponentForStatus application={application} product={product} />;
  }

  const Component = screenMap[currentScreen as Screen];

  return (
    <ApplicationProvider application={application} product={product} requirements={requirements}>
      <Layout>
        <Flex
          width={'100%'}
          height={'100%'}
          maxWidth={theme.breakpoints.values.md}
          py={isMobile ? 6 : 4}
          px={4}
          flex={1}
          data-testid={currentScreen}
        >
          <Component
            flow={'management'}
            // eslint-disable-next-line react/jsx-no-bind
            onNextClick={() => {}}
            backVisible={false}
            nextButtonText={'Save'}
            nextIconVisible={false}
            nextLoading={loadingRequirements}
            animate={false}
            confirmDiscardChanges
            {...product.screensConfiguration.screenParams[currentScreen]}
          />
        </Flex>
      </Layout>
    </ApplicationProvider>
  );
};

export const ManagementScreen = () => {
  const { isAuthenticated, isPending } = useAuthentication();

  if (isPending) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    return <Navigate to={'/'} />;
  }

  return <ManagementScreenInner />;
};
