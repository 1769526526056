import { Add } from '@mui/icons-material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Flex } from '@lama/design-system';
import { Button } from '@mui/material';
import { displayToast } from '../../utils/displayToast.js';
import { InProgressDocument } from './InProgressDocument.js';
import { isFileValid } from './services/isFileValid.js';

interface ArbitraryDocumentUploadButtonProps {
  onFilesSelected: (files: FileList) => Promise<void> | void;
  uploading?: boolean;
}

export const ArbitraryDocumentUploadButton: FC<ArbitraryDocumentUploadButtonProps> = ({ onFilesSelected, uploading }) => {
  const onFileSelectedInner = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target;
      if (files?.length) {
        const fileValidations = await Promise.all([...files].map(isFileValid));

        if (fileValidations.some(({ result: isValid }) => !isValid)) {
          const firstInvalidFile = fileValidations.find(({ result: isValid }) => !isValid);
          if (firstInvalidFile?.message) {
            displayToast(firstInvalidFile.message, 'warning');
          }
          return;
        }

        await onFilesSelected(files);
      }
    },
    [onFilesSelected],
  );

  return uploading ? (
    <InProgressDocument />
  ) : (
    <Flex gap={2}>
      <Button component={'label'} startIcon={<Add />}>
        <input hidden type={'file'} multiple onChange={onFileSelectedInner} />
        {'Upload Additional Documents'}
      </Button>
    </Flex>
  );
};
