import React, { useMemo, useCallback, useState, useContext } from 'react';
import type { FC } from 'react';
import { Flex } from '@lama/design-system';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import type { Entity } from '@lama/common-types';
import type { Relation, RequirementProperty } from '@lama/contracts';
import { capitalCase } from 'change-case-all';
import type { AffiliateApiModel, ApplicationApiModel, BusinessApiModel, OpportunityApiModel, PersonApiModel } from '@lama/clients';
import * as yup from 'yup';
import type { DialogMode } from '../BaseDialog';
import { BaseDialog } from '../BaseDialog';
import { formValuesToEntityPartial, GenericPropertiesGrid, getValidationObjectShape } from '../GenericProperties';
import { ModifyItemButton } from '../ModifyItemButton';
import { Toggle } from '../Toggle';
import { ExistingEntitySelection } from '../Principals/ExistingEntitySelection';
import { FormikCheckbox } from '../FormikFields';
import { AddOrCreateRelatedEntity } from './AddOrCreateRelatedEntity';
import { SearchExistingCustomersContext } from './searchExistingCustomersContext';

interface GenericAddOrEditRelationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any, entityType: Entity) => Promise<void>;
  initialValues?: Record<string, any>;
  properties?: RequirementProperty[];
  initialEntityType: Entity;
  entity?: Record<string, any>;
  isLoading?: boolean;
  showEntityTypeToggle?: boolean;
  relation: Relation;
  dialogMode: DialogMode;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  existingEntityIds?: string[];
  addExistingEntityEnabled?: boolean;
}

type GenericEditRelationComponentProps = Pick<
  GenericAddOrEditRelationDialogProps,
  'dialogMode' | 'entity' | 'initialValues' | 'isLoading'
> & {
  entityType: Entity;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  currentEntityProperties: RequirementProperty[];
  submitHandler: (values: Record<string, any>) => Promise<void>;
};

type GenericAddRelationComponentProps = GenericEditRelationComponentProps &
  Pick<
    GenericAddOrEditRelationDialogProps,
    'addExistingEntityEnabled' | 'application' | 'existingEntityIds' | 'onClose' | 'relation' | 'showEntityTypeToggle'
  > & {
    onToggleChange: (value: boolean) => void;
  };

const toggleOptions = [
  { value: true, text: 'person' as const },
  { value: false, text: 'business' as const },
];

const EntityForm: FC<GenericEditRelationComponentProps> = ({
  submitHandler,
  initialValues = {},
  entity = {},
  isLoading,
  dialogMode,
  entityType,
  currentEntityProperties,
  application,
  opportunity,
}) => {
  const actionDisplayName = useMemo(() => (dialogMode === 'add' ? 'Add' : 'Update'), [dialogMode]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        ...getValidationObjectShape({ properties: currentEntityProperties, blockOnMissingRequiredFields: true }),
        inviteToApplication: yup.boolean(),
      }),
    [currentEntityProperties],
  );

  const isCreatingPerson = useMemo(() => dialogMode === 'add', [dialogMode]);

  const onSubmitInternal = useCallback(
    async (values: Record<string, any>, { resetForm }: FormikHelpers<any>) => {
      await submitHandler(values);
      resetForm();
    },
    [submitHandler],
  );

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitInternal} validateOnChange>
      {({ handleSubmit, isValid }) => (
        <Flex flexDirection={'column'} gap={12} height={'100%'} width={'100%'}>
          <Flex gap={4} flexWrap={'wrap'}>
            <GenericPropertiesGrid
              properties={currentEntityProperties}
              entityType={entityType}
              entity={entity}
              application={application}
              opportunity={opportunity}
            />
            {isCreatingPerson && entityType === 'person' ? (
              <FormikCheckbox
                name={'inviteToApplication'}
                label={'Send invite to collaborate on this application'}
                helperText={'You can always invite them later'}
              />
            ) : null}
          </Flex>
          <Flex marginTop={'auto'} justifyContent={'center'}>
            <ModifyItemButton text={actionDisplayName} onClick={handleSubmit} showIcon={false} disabled={!isValid} loading={isLoading} />
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export const AddRelationComponent: FC<GenericAddRelationComponentProps> = (props) => {
  const {
    onToggleChange,
    entityType,
    application,
    existingEntityIds,
    showEntityTypeToggle,
    submitHandler,
    relation,
    addExistingEntityEnabled,
    isLoading,
  } = props;
  const [step, setStep] = useState<'form' | 'selection'>(addExistingEntityEnabled ? 'selection' : 'form');

  const onEntitySelected = useCallback(
    async (selectedEntity?: AffiliateApiModel | BusinessApiModel | PersonApiModel) => {
      if (selectedEntity) {
        await submitHandler(selectedEntity);
      } else {
        setStep('form');
      }
    },
    [submitHandler],
  );

  const onInnerToggleChange = useCallback(
    (value: boolean) => {
      if (addExistingEntityEnabled) {
        setStep('selection');
      }
      onToggleChange(value);
    },
    [addExistingEntityEnabled, onToggleChange],
  );

  return (
    <>
      {showEntityTypeToggle ? (
        <Toggle
          onChange={onInnerToggleChange}
          optionA={{ value: true, text: 'Person' }}
          optionB={{ value: false, text: 'Business' }}
          value={entityType === 'person'}
        />
      ) : null}
      {step === 'form' || relation === 'property' ? (
        <EntityForm {...props} />
      ) : (
        <ExistingEntitySelection
          onEntitySelected={onEntitySelected}
          entityType={entityType === 'person' ? 'person' : 'business'}
          excludedEntityIds={existingEntityIds}
          application={application}
          loading={!!isLoading}
        />
      )}
    </>
  );
};

const EditRelationComponent: FC<GenericEditRelationComponentProps> = (props) => <EntityForm {...props} />;

export const GenericAddOrEditRelationDialog: FC<GenericAddOrEditRelationDialogProps> = ({
  open,
  onClose,
  initialEntityType,
  relation,
  dialogMode,
  properties: itemProperties,
  initialValues,
  onSubmit,
  existingEntityIds,
  showEntityTypeToggle,
  application,
  addExistingEntityEnabled,
  isLoading,
}) => {
  const { searchEnabled, searchButtonVisible } = useContext(SearchExistingCustomersContext);
  const AddOrCreateComponent = useMemo(() => (searchEnabled ? AddOrCreateRelatedEntity : AddRelationComponent), [searchEnabled]);
  const [entityType, setEntityType] = useState<Entity>(initialEntityType);

  const actionDisplayName = useMemo(() => (dialogMode === 'add' ? 'Add' : 'Update'), [dialogMode]);

  const onToggleChange = useCallback((value: boolean) => {
    setEntityType(toggleOptions.find((option) => option.value === value)?.text ?? 'person');
  }, []);

  const currentEntityProperties = useMemo(
    () => itemProperties?.filter((p) => p.entityType === entityType) ?? [],
    [entityType, itemProperties],
  );

  const submitHandler = useCallback(
    async (values: Record<string, any>, entityTypeOverride?: Entity) => {
      const partial = formValuesToEntityPartial(values, initialValues ?? {}, currentEntityProperties);
      await onSubmit(
        { ...partial, id: partial.id ?? initialValues?.id, inviteToApplication: values.inviteToApplication },
        entityTypeOverride ?? entityType,
      );
    },
    [currentEntityProperties, entityType, initialValues, onSubmit],
  );

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={`${actionDisplayName} ${capitalCase(relation)}`}
      data-testid={'add-or-edit-relation-dialog'}
    >
      {dialogMode === 'add' ? (
        <AddOrCreateComponent
          dialogMode={'add'}
          relation={relation}
          application={application}
          showEntityTypeToggle={showEntityTypeToggle}
          existingEntityIds={existingEntityIds}
          initialValues={initialValues}
          entityType={entityType}
          currentEntityProperties={currentEntityProperties}
          onToggleChange={onToggleChange}
          submitHandler={submitHandler}
          addExistingEntityEnabled={addExistingEntityEnabled}
          isLoading={isLoading}
          onClose={onClose}
          searchButtonVisible={searchButtonVisible}
        />
      ) : (
        <EditRelationComponent
          dialogMode={'edit'}
          initialValues={initialValues}
          entityType={entityType}
          application={application}
          currentEntityProperties={currentEntityProperties}
          submitHandler={submitHandler}
          isLoading={isLoading}
        />
      )}
    </BaseDialog>
  );
};
