import type { SearchResultProps, SelectedEntity } from '@lama/clients';
import type { FC } from 'react';
import { useDebounce } from 'react-use';
import React, { createContext, useCallback, useMemo, useState } from 'react';

interface SearchExistingCustomerProps {
  onSearchTermChange?: (searchTerm: string) => void;
  onSearchResultSelect?: (externalIdentifier: string) => Promise<SelectedEntity | undefined>;
  searchResults?: SearchResultProps[];
  searchTerm?: string;
  isSaving?: boolean;
  searchEnabled?: boolean;
  searchButtonVisible?: boolean;
}

export const SearchExistingCustomersContext = createContext<SearchExistingCustomerProps>({
  searchResults: [],
});

export const SearchExistingCustomersProvider: FC<SearchExistingCustomerProps & { children: React.ReactNode }> = ({
  children,
  onSearchTermChange,
  searchResults,
  onSearchResultSelect,
  searchEnabled,
  searchButtonVisible,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const debouncedSearchTerm = useCallback(() => {
    if (!searchTerm) {
      return;
    }
    onSearchTermChange?.(searchTerm);
  }, [searchTerm, onSearchTermChange]);
  const handleSaving = useCallback(
    async (selector: string) => {
      setIsSaving(true);
      const result = await onSearchResultSelect?.(selector);
      setIsSaving(false);
      return result;
    },
    [onSearchResultSelect],
  );
  useDebounce(debouncedSearchTerm, 500, [searchTerm]);
  const value = useMemo(
    () => ({
      onSearchTermChange: (currentSearchTerm: string) => {
        setSearchTerm(currentSearchTerm);
      },
      searchResults,
      onSearchResultSelect: handleSaving,
      searchTerm,
      isSaving,
      searchEnabled,
      searchButtonVisible,
    }),
    [searchResults, handleSaving, searchTerm, isSaving, searchEnabled, searchButtonVisible],
  );
  return <SearchExistingCustomersContext.Provider value={value}>{children}</SearchExistingCustomersContext.Provider>;
};
