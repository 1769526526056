import { Flex, greyPalette } from '@lama/design-system';
import { DeleteOutlined, ArrowCircleDownOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';

export const DocumentActions: FC<{
  onDelete?: (e: React.MouseEvent) => void;
  onOpen?: (e: React.MouseEvent) => void;
  onDownloadDocument?: (e: React.MouseEvent) => void;
}> = ({ onDelete, onOpen, onDownloadDocument }) => {
  const onDeleteInner = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onDelete?.(e);
    },
    [onDelete],
  );

  const onOpenInner = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onOpen?.(e);
    },
    [onOpen],
  );

  const onDownloadDocumentInner = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onDownloadDocument?.(e);
    },
    [onDownloadDocument],
  );

  return (
    <Flex justifyContent={'center'} gap={1}>
      {onDelete ? (
        <Tooltip title={'Delete'} placement={'top'}>
          <IconButton onClick={onDeleteInner} size={'small'}>
            <DeleteOutlined sx={{ color: greyPalette[500] }} />
          </IconButton>
        </Tooltip>
      ) : null}
      {onDownloadDocument ? (
        <Tooltip title={'Download'} placement={'top'}>
          <IconButton onClick={onDownloadDocumentInner} size={'small'}>
            <ArrowCircleDownOutlined sx={{ color: greyPalette[500] }} />
          </IconButton>
        </Tooltip>
      ) : null}
      {onOpen ? (
        <Tooltip title={'Open'} placement={'top'}>
          <IconButton onClick={onOpenInner} size={'small'}>
            <VisibilityOutlined sx={{ color: greyPalette[500] }} />
          </IconButton>
        </Tooltip>
      ) : null}
    </Flex>
  );
};
