import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Typography, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
import { Flex } from '@lama/design-system';

interface YesNoQuestionProps {
  name: string;
  question: string;
  value?: boolean | null;
  required?: boolean;
  onChange: (value: boolean) => void;
}

export const YesNoQuestion: FC<YesNoQuestionProps> = ({ name, question, required, onChange, value }) => {
  const onChangeInner = useCallback(
    async (_: React.ChangeEvent<HTMLInputElement>, pickedValue: string) => {
      onChange(pickedValue === 'true');
    },
    [onChange],
  );

  return (
    <Flex flexDirection={'column'} justifyContent={'space-between'} py={2}>
      <Typography variant={'body1'} color={'text.secondary'}>
        {question}
        {required ? (
          <Typography component={'span'} display={'inline'} color={'#EF5350'}>
            {' *'}
          </Typography>
        ) : null}
      </Typography>
      <Box pl={1}>
        <RadioGroup name={name} onChange={onChangeInner} value={value} aria-label={name}>
          <Flex flexDirection={'row'} gap={2}>
            <FormControlLabel value control={<Radio />} label={'Yes'} />
            <FormControlLabel value={false} control={<Radio />} label={'No'} />
          </Flex>
        </RadioGroup>
      </Box>
    </Flex>
  );
};
