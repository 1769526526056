import * as baseColors from '../../../theme/colors.js';
import type { ColorOverrides } from '../../../theme/types.js';

const { grayPalette } = baseColors;

export const getTextLightTheme = (_colors: ColorOverrides) => ({
  colors: {
    primary: '#0C2007',
    secondary: grayPalette[700],
    disabled: '#C5C5C5',
  },
  variants: {
    h1: {
      fontFamily: 'Ubuntu, sans-serif',
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.1,
    },
    h4: {
      fontFamily: 'Ubuntu, sans-serif',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: 1.2,
      letterSpacing: 0.25,
    },
    h5: {
      fontFamily: 'Ubuntu, sans-serif',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.3,
    },
    h6: {
      fontFamily: 'Ubuntu, sans-serif',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 1.4,
      letterSpacing: 0.15,
    },
    body1: {
      fontFamily: "Poppins, 'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.15,
    },
    body2: {
      fontFamily: "Poppins, 'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.4,
      letterSpacing: 0.17,
    },
    body3: {
      fontFamily: "Poppins, 'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: 0.4,
    },
    body4: {
      fontFamily: "Poppins, 'Open Sans', sans-serif",
      fontWeight: 500,
      fontSize: 10,
      lineHeight: 1.4,
    },
  },
  code: {
    backgroundColor: grayPalette[100],
    borderColor: `1px solid ${grayPalette[400]}`,
  },
});
