import React, { useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';
import { ToastContainer, Slide } from 'react-toastify';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConfirmationModal, ConfirmationModalProvider } from '@lama/app-components';
import type { ColorOverrides } from '@lama/design-system';
import { ThemeProvider as LamaThemeProvider, getLightTheme } from '@lama/design-system';
import { createThemeForPartner } from './theme/theme';
import { router } from './router';
import i18n from './framework/i18n';
import { usePartnerFromSubdomain } from './hooks/usePartnerFromSubdomain';
import 'react-toastify/dist/ReactToastify.css';
import { queryClient } from './framework/queryClient';

export const mergeLamaThemeWithPartner = (partner: PartnerPublicDataApiModel) => {
  const partnerTheme: ColorOverrides = { primary: partner.branding.theme.primary, secondary: partner.branding.theme.secondary };

  return getLightTheme(partnerTheme);
};

function App() {
  const partner = usePartnerFromSubdomain();

  useEffect(() => {
    const favicon = document.querySelector('#favicon')!;
    if (favicon && partner) {
      (favicon as any).href = `https://lama-ai-assets.s3.amazonaws.com/partner-logos/${partner?.id.toLowerCase()}_favicon.svg`;
    }
  }, [partner]);

  const themeForPartner = useMemo(() => {
    if (!partner) {
      return {};
    }

    return createThemeForPartner(partner);
  }, [partner]);

  const themeForDesignSystem = useMemo(() => {
    if (!partner) {
      return getLightTheme();
    }

    return mergeLamaThemeWithPartner(partner);
  }, [partner]);

  if (!partner) {
    return null;
  }

  return (
    <CssBaseline>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeForPartner}>
          <LamaThemeProvider theme={themeForDesignSystem}>
            <I18nextProvider i18n={i18n}>
              <ConfirmationModalProvider>
                <ToastContainer
                  position={'top-center'}
                  autoClose={3000}
                  transition={Slide}
                  theme={'colored'}
                  closeButton={false}
                  hideProgressBar
                />
                <RouterProvider router={router} />
                <ConfirmationModal />
              </ConfirmationModalProvider>
            </I18nextProvider>
          </LamaThemeProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </CssBaseline>
  );
}

export default App;
