import { useTheme } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

export const LineOfCreditLogo: FC<{ selected: boolean }> = ({ selected }) => {
  const theme = useTheme();

  return (
    <svg width={'148'} height={'96'} viewBox={'0 0 148 96'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <g clipPath={'url(#clip0_18976_191058)'}>
        <path
          d={
            'M144.285 38.6253L64.8751 0.685509C62.3243 -0.534691 59.2768 0.6343 58.1763 3.25673L38.3677 50.449C37.3321 52.9178 38.41 55.7706 40.8141 56.9197L120.224 94.8595C122.774 96.0797 125.822 94.9107 126.922 92.2883L146.731 45.096C147.767 42.6272 146.689 39.7744 144.285 38.6253Z'
          }
          fill={'white'}
        />
        <path
          d={
            'M62.7531 7.65103e-05C63.5404 7.65791e-05 64.322 0.176415 65.0557 0.526261L144.465 38.4661C147.081 39.7176 148.246 42.8008 147.12 45.4886L127.312 92.6808C126.733 94.0574 125.653 95.107 124.267 95.6389C122.882 96.1707 121.38 96.111 120.043 95.471L40.6335 57.5312C38.0177 56.2798 36.8524 53.1966 37.9782 50.5087L43.0291 38.4774C43.1194 38.2613 43.3678 38.1589 43.585 38.2499C43.7995 38.3409 43.9011 38.5912 43.8108 38.8102L38.7598 50.8415C37.8089 53.1056 38.7909 55.7081 40.9975 56.7604L120.404 94.7031C121.533 95.2435 122.797 95.2919 123.965 94.8453C125.133 94.3959 126.045 93.5113 126.53 92.3509L146.339 45.1587C147.29 42.8946 146.308 40.2921 144.101 39.2397L64.6917 1.2999C63.563 0.759491 62.2988 0.711144 61.1306 1.15769C59.9624 1.60709 59.051 2.49166 58.5657 3.65213L50.123 23.7612C50.0327 23.9773 49.7844 24.0797 49.5671 23.9887C49.3527 23.8977 49.2511 23.6474 49.3414 23.4284L57.7812 3.31934C58.3597 1.94271 59.4404 0.893181 60.8259 0.361298C61.4523 0.122376 62.1013 0.00291459 62.7475 0.00291464L62.7531 7.65103e-05Z'
          }
          fill={'black'}
          fillOpacity={'0.6'}
        />
        <path
          d={'M140.092 61.1377L51.692 18.9315L56.441 7.61695L144.945 49.5757L140.092 61.1377Z'}
          fill={selected ? theme.palette.primary.main : '#0C2007'}
        />
      </g>
      <path
        d={
          'M38.8092 45.2202C46.5884 44.4867 52.2974 37.6136 51.5607 29.8687C50.8239 22.1238 43.9205 16.4399 36.1414 17.1734C28.3622 17.9069 22.6532 24.7799 23.3899 32.5249C24.1267 40.2698 31.0301 45.9536 38.8092 45.2202Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M49.6441 38.1067C53.7229 31.7446 51.8492 23.2952 45.4591 19.2343C39.0689 15.1734 30.5822 17.0389 26.5033 23.4009C22.4245 29.763 24.2982 38.2124 30.6884 42.2733C37.0785 46.3341 45.5653 44.4687 49.6441 38.1067Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M48.9473 34.5756C51.0596 28.6006 47.9068 22.0521 41.9054 19.9492C35.904 17.8462 29.3266 20.9851 27.2143 26.9601C25.1021 32.9351 28.2549 39.4836 34.2563 41.5865C40.2577 43.6895 46.8351 40.5506 48.9473 34.5756Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M37.1339 38.8737L37.1087 36.9891C35.771 36.946 34.473 36.6086 33.7086 36.1814L34.2747 33.8517C35.1184 34.2968 36.301 34.6988 37.5954 34.6809C38.7312 34.6665 39.5028 34.2178 39.492 33.4245C39.4812 32.6706 38.8358 32.204 37.3539 31.7265C35.2121 31.0409 33.741 30.0681 33.7158 28.144C33.6906 26.3958 34.9093 25.0101 37.0366 24.565L37.0113 22.6804L38.9656 22.6553L38.9908 24.3999C40.3285 24.443 41.2263 24.7086 41.8897 25.0173L41.3416 27.2681C40.8188 27.0563 39.8958 26.6112 38.4608 26.6327C37.1664 26.6507 36.7553 27.2107 36.7625 27.7671C36.7697 28.4204 37.4764 28.8296 39.1783 29.4399C41.5616 30.2404 42.5351 31.3209 42.5567 33.105C42.582 34.8712 41.3453 36.3968 39.0593 36.824L39.0881 38.8486L37.1339 38.8737Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M21.3476 22.392C25.5495 18.6146 25.8801 12.1612 22.0861 7.97779C18.2921 3.79439 11.8101 3.46521 7.60819 7.24255C3.40631 11.0199 3.07567 17.4733 6.8697 21.6567C10.6637 25.8401 17.1457 26.1693 21.3476 22.392Z'
        }
        fill={'black'}
        fillOpacity={'0.6'}
      />
      <path
        d={
          'M21.4611 22.0157C25.6056 18.425 26.0417 12.1691 22.4351 8.04286C18.8285 3.91657 12.545 3.48241 8.40047 7.07313C4.25595 10.6639 3.81987 16.9197 7.42646 21.046C11.0331 25.1723 17.3166 25.6065 21.4611 22.0157Z'
        }
        fill={'#BDBDBD'}
      />
      <path
        d={
          'M21.8757 19.1503C24.4335 15.332 23.3979 10.1722 19.5627 7.6257C15.7274 5.07917 10.5449 6.11019 7.98711 9.92854C5.42933 13.7469 6.4649 18.9067 10.3001 21.4532C14.1354 23.9997 19.3179 22.9687 21.8757 19.1503Z'
        }
        fill={'#F3F0FF'}
      />
      <path
        d={
          'M13.8345 20.3539L13.9102 18.9898C12.9475 18.8929 12.0245 18.5806 11.4945 18.236L12.0209 16.5811C12.6086 16.9437 13.4415 17.2955 14.379 17.3457C15.201 17.3924 15.7815 17.1088 15.814 16.5345C15.8464 15.9888 15.403 15.6191 14.3573 15.1991C12.843 14.596 11.8298 13.817 11.9091 12.4242C11.9812 11.1606 12.9295 10.2201 14.4907 10.0047L14.5665 8.64062L15.9798 8.7196L15.9077 9.98319C16.8704 10.0801 17.5086 10.317 17.9737 10.5755L17.4654 12.1729C17.0976 11.9934 16.4558 11.6273 15.4174 11.5698C14.4799 11.516 14.1554 11.9037 14.1302 12.3057C14.1049 12.7796 14.5917 13.1099 15.7924 13.6375C17.4762 14.3375 18.1216 15.1668 18.0495 16.4591C17.9773 17.737 17.0074 18.778 15.3308 18.9719L15.2479 20.4365L13.8345 20.3575V20.3539Z'
        }
        fill={'#BDBDBD'}
      />
      <defs>
        <clipPath id={'clip0_18976_191058'}>
          <rect width={'110'} height={'96'} fill={'white'} transform={'translate(147.549 96) rotate(-180)'} />
        </clipPath>
      </defs>
    </svg>
  );
};
