import type { FC } from 'react';
import React from 'react';
import type { UseOfFundsBreakdown } from '@lama/contracts';
import { Flex, Text } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';

interface UseOfFundsBreakdownItemProps {
  item: UseOfFundsBreakdown;
}

export const UseOfFundsBreakdownItem: FC<UseOfFundsBreakdownItemProps> = ({ item: { description, type, amount } }) => (
  <>
    <Text variant={'h6'} ellipsis>
      {description}
    </Text>
    <Flex gap={1} flexDirection={'row'}>
      <Text variant={'body2'} ellipsis>
        {type ?? 'Other'}
      </Text>
      <Text variant={'body2'} ellipsis>
        {`${amount ? ` • ${formatValue(amount, 'currency')}` : ''}`}
      </Text>
    </Flex>
  </>
);
