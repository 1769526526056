import React, { useCallback, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { Entity } from '@lama/common-types';
import type { Document } from '@lama/document-service-client';
import type { DismissedSourceReason, EvaluatedApplicationRequirement, FileUploadSource } from '@lama/contracts';
import { DocumentBox as DocumentBoxInner } from '@lama/app-components';
import { useUploadDocumentMutation } from '../../../../../hooks/react-query/useUploadDocumentMutation';
import { ApplicationContext } from '../../../../../shared/contexts/ApplicationContext';
import { useDeleteDocumentMutation } from '../../../../../hooks/react-query/useDeleteDocumentMutation';
import type { DismissWithReasonDialogValues } from '../../../DismissWithReasonDialog';
import { DismissWithReasonDialog } from '../../../DismissWithReasonDialog';
import { useUpdateRequirementMutation } from '../../../../../hooks/react-query/useUpdateRequirementMutation';
import { useConfirmLeaveWhileBusy } from './useConfirmLeaveWhileBusy';

interface DocumentBoxProps {
  requirement: EvaluatedApplicationRequirement;
  description: string;
  topic: string;
  document?: Document;
  sharedRequirementId?: string;
  requirementKey?: string;
  entityId: string;
  entityType: Entity;
  dismissible?: boolean;
  dismissed?: DismissedSourceReason;
}

export const DocumentBox: React.FC<DocumentBoxProps> = ({
  requirement: { id: requirementId, sources },
  description,
  topic,
  entityId,
  entityType,
  document,
  dismissible,
  dismissed,
  sharedRequirementId,
  requirementKey,
}) => {
  const {
    application: { id: applicationId },
  } = useContext(ApplicationContext);
  const { downloadDocumentsEnabled } = useFlags();
  const { mutateAsync: uploadDocument, isPending: uploadingDocument } = useUploadDocumentMutation(applicationId);
  const { mutateAsync: deleteDocument, isPending: deletingDocument } = useDeleteDocumentMutation(applicationId);
  useConfirmLeaveWhileBusy(uploadingDocument || deletingDocument);
  const [dismissingSource, setDismissingSource] = React.useState<FileUploadSource | null>(null);
  const { mutateAsync: updateRequirement, isPending: updatingRequirement } = useUpdateRequirementMutation(applicationId, requirementId);

  const onDismissDocument = useCallback(
    async (name: string) => {
      setDismissingSource(sources.uploadFilesSource?.find((s) => s.name === name) ?? null);
    },
    [sources.uploadFilesSource],
  );

  const handleDismiss = useCallback(
    async (dialogResult: DismissWithReasonDialogValues | null) => {
      if (!dismissingSource || !dialogResult?.reason) {
        setDismissingSource(null);
        return;
      }

      const { description: dismissDescription, reason: dismissReason } = dialogResult;

      const updatedSources = sources.uploadFilesSource?.map((source) => {
        if (source.name === dismissingSource.name) {
          return {
            ...source,
            dismissDataByEntity: {
              ...source.dismissDataByEntity,
              [entityId]: {
                description: dismissDescription,
                reason: dismissReason,
              },
            },
          };
        }

        return source;
      });
      await updateRequirement({
        updateRequirementPayload: {
          sources: {
            uploadFilesSource: updatedSources,
          },
        },
      });

      setDismissingSource(null);
    },
    [dismissingSource, entityId, sources.uploadFilesSource, updateRequirement],
  );

  const onUndismissDocument = useCallback(async () => {
    await updateRequirement({
      updateRequirementPayload: {
        sources: {
          uploadFilesSource: sources.uploadFilesSource?.map((s) =>
            s.name === description
              ? {
                  ...s,
                  dismissDataByEntity: {},
                }
              : s,
          ),
        },
      },
    });
  }, [description, sources.uploadFilesSource, updateRequirement]);

  const onDocumentSelected = useCallback(
    async (file: File) => {
      await uploadDocument({
        file,
        description,
        requirementId,
        sharedRequirementId,
        requirementKey,
        applicationId,
        entityId,
        entityType,
        topic,
      });
    },
    [applicationId, description, entityId, entityType, requirementId, requirementKey, sharedRequirementId, topic, uploadDocument],
  );

  const onDocumentRemoved = useCallback(async () => {
    if (document?.id) {
      await deleteDocument({ documentId: document?.id });
    }
  }, [deleteDocument, document?.id]);

  return (
    <>
      <DocumentBoxInner
        description={description}
        document={document}
        loading={uploadingDocument || deletingDocument || updatingRequirement}
        onDocumentUpload={onDocumentSelected}
        onDocumentRemoved={onDocumentRemoved}
        onDismissSource={onDismissDocument}
        onUndismissSource={onUndismissDocument}
        dismissible={dismissible}
        dismissed={dismissed}
        downloadEnabled={downloadDocumentsEnabled}
      />
      {dismissible && !!dismissingSource ? (
        <DismissWithReasonDialog
          open={!!dismissingSource}
          handleClose={handleDismiss}
          title={'Are you sure you want to dismiss this file requirement'}
        />
      ) : null}
    </>
  );
};
