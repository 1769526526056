import { usePrompt } from '@lama/app-components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export const FormikPromptOnDirtyNavigation = () => {
  const { dirty, resetForm } = useFormikContext();
  const { t } = useTranslation();

  usePrompt(t('management.confirmDiscard'), dirty, {
    onConfirm: () => {
      resetForm();
    },
  });

  return null;
};
