import type { FC } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Entity } from '@lama/common-types';
import type { DataProviderCredentials, RequirementProperty } from '@lama/contracts';
import type {
  AffiliateApiModel,
  ApplicationApiModel,
  BusinessApiModel,
  CreateBusinessModelApi,
  PersonApiModel,
  PersonCreateApiModel,
  PersonUpdateApiModel,
  SelectedEntity,
  UpdateBusinessRequest,
} from '@lama/clients';
import { Flex } from '@lama/design-system';
import { BaseDialog, type DialogMode } from '../BaseDialog';
import { Toggle } from '../Toggle';
import { SearchExistingCustomersContext } from '../RelationsList';
import { AddOrCreateRelatedEntity } from '../RelationsList/AddOrCreateRelatedEntity';
import { GenericPropertiesProvider, customComponents } from '../GenericProperties';
import { BusinessForm } from './BusinessForm';
import { PersonForm } from './PersonForm';
import { ExistingEntitySelection } from './ExistingEntitySelection';
import type { ExistingPrincipal, ExistingPrincipalBusiness, ExistingPrincipalPerson } from './BasePrincipalsScreen';

interface AddOrEditDialogProps {
  open: boolean;
  handleClose: (values: ExistingPrincipal | null | undefined) => Promise<void>;
  createPerson?: ({ person }: { person: PersonCreateApiModel }) => Promise<string | undefined>;
  createBusiness?: ({
    business,
    dataProvidersCredentials,
  }: {
    business: CreateBusinessModelApi;
    dataProvidersCredentials?: DataProviderCredentials[];
  }) => Promise<void>;
  updatePerson?: ({ personId, updatePersonPayload }: { personId: string; updatePersonPayload: PersonUpdateApiModel }) => Promise<void>;
  updateBusiness?: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
  existingPrincipal: ExistingPrincipal | null;
  remainingOwnershipPercentage: number;
  requirementProperties: RequirementProperty[];
  isLoading?: boolean;
  dialogMode: DialogMode;
  dialogType: 'business' | 'person';
  existingEntityIds?: string[];
  application: ApplicationApiModel;
  addExistingEntityEnabled?: boolean;
  disableEmailUpdate?: boolean;
}

const toggleOptions = [
  { value: true, text: 'person' as const },
  { value: false, text: 'business' as const },
];
type PrincipleFormProps = Pick<
  AddOrEditDialogProps,
  | 'dialogMode'
  | 'dialogType'
  | 'disableEmailUpdate'
  | 'existingPrincipal'
  | 'handleClose'
  | 'isLoading'
  | 'remainingOwnershipPercentage'
  | 'requirementProperties'
> & {
  showBackButton?: boolean;
  onBack?: () => void;
};
const PrincipalForm: FC<PrincipleFormProps> = ({
  handleClose,
  remainingOwnershipPercentage,
  existingPrincipal,
  requirementProperties,
  isLoading,
  dialogType,
  dialogMode,
  disableEmailUpdate,
  showBackButton,
  onBack,
}) =>
  dialogType === 'business' ? (
    <BusinessForm
      existingPrincipal={existingPrincipal as ExistingPrincipalBusiness}
      remainingOwnershipPercentage={remainingOwnershipPercentage}
      handleClose={handleClose}
      isLoading={isLoading}
      dialogMode={dialogMode}
      showBackButton={showBackButton}
      onBack={onBack}
    />
  ) : (
    <PersonForm
      existingPrincipal={existingPrincipal as ExistingPrincipalPerson}
      remainingOwnershipPercentage={remainingOwnershipPercentage}
      handleClose={handleClose}
      requirementProperties={requirementProperties}
      isLoading={isLoading}
      dialogMode={dialogMode}
      disableEmailUpdate={disableEmailUpdate}
      showBackButton={showBackButton}
      onBack={onBack}
    />
  );

interface AddPrincipalComponentProps extends AddOrEditDialogProps {
  onToggleChange: (value: boolean) => void;
}

interface CreateFormProps {
  handleClose: (values: ExistingPrincipal | null | undefined) => Promise<void>;
  remainingOwnershipPercentage: number;
  existingPrincipal: ExistingPrincipal | null;
  requirementProperties: RequirementProperty[];
  isLoading?: boolean;
  dialogType: 'business' | 'person';
  disableEmailUpdate?: boolean;
  onToggleChange?: (value: boolean) => void;
  showToggle?: boolean;
  onBack: () => void;
}

const CreateForm = ({
  handleClose,
  remainingOwnershipPercentage,
  existingPrincipal,
  requirementProperties,
  isLoading,
  dialogType,
  disableEmailUpdate,
  onToggleChange,
  showToggle,
  onBack,
}: CreateFormProps) => (
  <Flex flexDirection={'column'} alignItems={'center'} gap={2} justifyContent={'center'}>
    {showToggle && onToggleChange ? (
      <Toggle
        onChange={onToggleChange}
        optionA={{ value: true, text: 'Person' }}
        optionB={{ value: false, text: 'Business' }}
        value={dialogType === 'person'}
      />
    ) : null}
    <PrincipalForm
      handleClose={handleClose}
      remainingOwnershipPercentage={remainingOwnershipPercentage}
      existingPrincipal={existingPrincipal}
      requirementProperties={requirementProperties}
      isLoading={isLoading}
      dialogMode={'add'}
      dialogType={dialogType}
      disableEmailUpdate={disableEmailUpdate}
      showBackButton
      onBack={onBack}
    />
  </Flex>
);

const AddOrSearchPrincipalComponent: FC<AddPrincipalComponentProps> = ({
  existingPrincipal,
  requirementProperties,
  isLoading,
  application,
  addExistingEntityEnabled,
  remainingOwnershipPercentage,
  handleClose,
  existingEntityIds,
  disableEmailUpdate,
  dialogType,
  onToggleChange,
}) => {
  const [step, setStep] = useState<'form' | 'selection'>(addExistingEntityEnabled ? 'selection' : 'form');
  const [existingPrincipalInner, setExistingPrincipalInner] = useState<ExistingPrincipal | null>(existingPrincipal);
  const { searchButtonVisible } = useContext(SearchExistingCustomersContext);

  const submitHandler = useCallback(
    async (values: Record<string, any>, entityType: Entity) => {
      setExistingPrincipalInner({ ...values, id: values.id ?? '' });
      onToggleChange(entityType === 'person');
      setStep('form');
    },
    [onToggleChange],
  );
  const onEntityConverted = useCallback(
    async (entity: SelectedEntity) => {
      if (!entity) {
        return;
      }
      if (entity.business) {
        return submitHandler(entity.business, 'business');
      }
      if (entity.person) {
        return submitHandler(entity.person, 'person');
      }
    },
    [submitHandler],
  );
  const onBack = useCallback(() => {
    setExistingPrincipalInner(null);
    setStep('selection');
  }, []);
  const onClose = useCallback(async () => {
    await handleClose(null);
  }, [handleClose]);
  return (
    <GenericPropertiesProvider customComponents={customComponents} customSourceToValues={{}}>
      {step === 'form' ? (
        <CreateForm
          handleClose={handleClose}
          remainingOwnershipPercentage={remainingOwnershipPercentage}
          existingPrincipal={existingPrincipalInner}
          requirementProperties={requirementProperties}
          isLoading={isLoading}
          dialogType={dialogType}
          disableEmailUpdate={disableEmailUpdate}
          showToggle={false}
          onBack={onBack}
        />
      ) : (
        <AddOrCreateRelatedEntity
          dialogMode={'add'}
          relation={'owner'}
          application={application}
          showEntityTypeToggle
          existingEntityIds={existingEntityIds}
          onSearchResultConverted={onEntityConverted}
          initialValues={existingPrincipalInner ?? undefined}
          entityType={dialogType}
          currentEntityProperties={requirementProperties}
          onToggleChange={onToggleChange}
          submitHandler={submitHandler}
          addExistingEntityEnabled={addExistingEntityEnabled}
          isLoading={isLoading}
          // eslint-disable-next-line react/jsx-no-bind, react/no-unstable-nested-components
          customForm={({ onBack: onBackInner }) => (
            <CreateForm
              handleClose={handleClose}
              remainingOwnershipPercentage={remainingOwnershipPercentage}
              existingPrincipal={existingPrincipalInner}
              requirementProperties={requirementProperties}
              isLoading={isLoading}
              dialogType={dialogType}
              disableEmailUpdate={disableEmailUpdate}
              showToggle
              onToggleChange={onToggleChange}
              onBack={onBackInner ?? onBack}
            />
          )}
          onClose={onClose}
          searchButtonVisible={searchButtonVisible}
        />
      )}
    </GenericPropertiesProvider>
  );
};

const AddPrincipalComponent: FC<AddPrincipalComponentProps> = ({
  handleClose,
  remainingOwnershipPercentage,
  existingPrincipal,
  requirementProperties,
  isLoading,
  dialogType,
  onToggleChange,
  existingEntityIds,
  application,
  addExistingEntityEnabled,
  disableEmailUpdate,
}) => {
  const [step, setStep] = useState<'form' | 'selection'>(addExistingEntityEnabled ? 'selection' : 'form');
  const [existingPrincipalInner, setExistingPrincipalInner] = useState<ExistingPrincipal | null>(existingPrincipal);

  const onEntitySelected = useCallback((entity?: AffiliateApiModel | BusinessApiModel | PersonApiModel) => {
    setStep('form');
    if (entity) {
      setExistingPrincipalInner(entity);
    }
  }, []);

  const onInnerToggleChange = useCallback(
    (value: boolean) => {
      if (addExistingEntityEnabled) {
        setStep('selection');
      }
      onToggleChange(value);
    },
    [addExistingEntityEnabled, onToggleChange],
  );

  return (
    <>
      <Toggle
        onChange={onInnerToggleChange}
        optionA={{ value: true, text: 'Person' }}
        optionB={{ value: false, text: 'Business' }}
        value={dialogType === 'person'}
      />
      {step === 'form' ? (
        <PrincipalForm
          handleClose={handleClose}
          remainingOwnershipPercentage={remainingOwnershipPercentage}
          existingPrincipal={existingPrincipalInner}
          requirementProperties={requirementProperties}
          isLoading={isLoading}
          dialogMode={'add'}
          dialogType={dialogType}
          disableEmailUpdate={disableEmailUpdate}
        />
      ) : (
        <ExistingEntitySelection
          onEntitySelected={onEntitySelected}
          entityType={dialogType}
          excludedEntityIds={existingEntityIds}
          application={application}
          loading={!!isLoading}
        />
      )}
    </>
  );
};

const EditPrincipalComponent: FC<AddOrEditDialogProps> = (props) => <PrincipalForm {...props} />;

export const AddOrEditPrincipalDialog: FC<AddOrEditDialogProps> = (props) => {
  const { searchEnabled } = useContext(SearchExistingCustomersContext);
  const AddOrCreateComponent = useMemo(() => (searchEnabled ? AddOrSearchPrincipalComponent : AddPrincipalComponent), [searchEnabled]);
  const { open, handleClose, dialogMode, dialogType } = props;
  const { t } = useTranslation();
  const [formType, setFormType] = useState<'business' | 'person'>(dialogType);

  const onToggleChange = useCallback((value: boolean) => {
    setFormType(toggleOptions.find((option) => option.value === value)?.text ?? 'person');
  }, []);

  return (
    <BaseDialog
      open={open}
      onClose={handleClose}
      title={t(`owners.dialog.title.${dialogMode}.${formType}`)}
      data-testid={'add-or-edit-principal-dialog'}
    >
      {dialogMode === 'add' ? (
        <AddOrCreateComponent {...props} dialogType={formType} onToggleChange={onToggleChange} />
      ) : (
        <EditPrincipalComponent {...props} dialogType={formType} />
      )}
    </BaseDialog>
  );
};
