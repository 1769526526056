import type { FC, ReactNode } from 'react';
import React, { useCallback } from 'react';
import type { DialogProps } from '@mui/material';
import { Dialog, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Flex, Spinner, Text } from '@lama/design-system';
import { CloseRounded } from '@mui/icons-material';

interface BaseDialogProps extends DialogProps {
  open: boolean;
  onClose: (values: any) => void;
  children: ReactNode;
  title: string;
  subtitle?: string;
  loading?: boolean;
  fullWidth?: boolean;
}

export type DialogMode = 'add' | 'edit';

export const BaseDialog: FC<BaseDialogProps> = ({
  open,
  onClose,
  children,
  title,
  subtitle,
  loading = false,
  fullWidth = true,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const closeDialog = useCallback(() => {
    onClose(null);
  }, [onClose]);
  if (loading) {
    return <Spinner />;
  }
  return (
    <Dialog
      open={open}
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flexEnd', alignItems: 'center', overflow: 'scroll' }}
      maxWidth={'md'}
      onClose={closeDialog}
      fullScreen={isMobile}
      scroll={'paper'}
      fullWidth={fullWidth}
      PaperProps={{
        elevation: 0,
        sx: {
          width: '720px',
          position: 'fixed',
          top: '0',
          alignSelf: 'center',
          paddingBottom: '24px',
          paddingTop: '24px',
        },
      }}
      {...props}
    >
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={closeDialog}>
        <CloseRounded fontSize={'large'} sx={{ color: theme.palette.grey[300] }} />
      </IconButton>
      <Flex height={'100%'} flexDirection={'column'} gap={6} px={isMobile ? 8 : 6} py={0} alignItems={'center'}>
        <Flex flexDirection={'column'} gap={4} alignItems={'center'}>
          <Text variant={'h5'}>{title}</Text>
          {subtitle ? (
            <Text variant={'body1'} textAlign={'center'}>
              {subtitle}
            </Text>
          ) : null}
        </Flex>
        {children}
      </Flex>
    </Dialog>
  );
};
