import React from 'react';
import { toast, type ToastOptions, type TypeOptions } from 'react-toastify';

import { red } from '@mui/material/colors';
import { ErrorOutline } from '@mui/icons-material';

const toastOptionsByType: Record<TypeOptions, ToastOptions> = {
  info: {},
  success: {
    style: {
      backgroundColor: '#FFFFFF',
    },
  },
  warning: {},
  error: {
    style: {
      backgroundColor: '#FDECEA',
      color: red[800],
      border: '1px solid',
    },
    icon: <ErrorOutline />,
  },
  default: {},
};

export const displayToast = (message: string, type: TypeOptions, options: ToastOptions = {}) =>
  toast(message, {
    type,
    ...toastOptionsByType[type],
    ...options,
  });
