import React, { useCallback, useMemo, useRef } from 'react';
import { CancelOutlined, FileUploadOutlined, RestorePageOutlined } from '@mui/icons-material';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Flex, greyPalette, Button } from '@lama/design-system';
import type { DismissedSourceReason } from '@lama/contracts';
import { Tooltip } from '../Tooltip';

interface ActionButtonsProps {
  description: string;
  onFileSelected?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDismiss?: (value: string) => void;
  onUndismiss?: (value: string) => void;
  dismissed?: DismissedSourceReason;
  dismissible?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  description,
  onFileSelected,
  onDismiss,
  onUndismiss,
  dismissed,
  dismissible,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const ref = useRef<HTMLInputElement>(null);

  const handleDismiss = useCallback(() => {
    onDismiss?.(description);
  }, [description, onDismiss]);

  const handleUndismiss = useCallback(() => {
    onUndismiss?.(description);
  }, [description, onUndismiss]);

  const dismissActionsAllowed = useMemo(
    () => dismissible && ((!dismissed && !!onDismiss) || (!!dismissed && !!onUndismiss)),
    [dismissed, dismissible, onDismiss, onUndismiss],
  );

  const onUploadClick = useCallback(() => {
    ref.current?.click();
  }, [ref]);

  const dismissAction = useMemo(() => (dismissed ? handleUndismiss : handleDismiss), [dismissed, handleDismiss, handleUndismiss]);
  const dismissCta = useMemo(() => (dismissed ? 'Restore' : 'Dismiss'), [dismissed]);

  if (isMobile) {
    return (
      <Flex alignItems={'center'} gap={1}>
        {dismissActionsAllowed ? (
          <Tooltip title={dismissCta} placement={'top'}>
            <IconButton size={'small'} onClick={dismissAction}>
              {dismissed ? <RestorePageOutlined sx={{ color: greyPalette[500] }} /> : <CancelOutlined sx={{ color: greyPalette[400] }} />}
            </IconButton>
          </Tooltip>
        ) : null}
        {dismissed ? null : (
          <Tooltip title={'Upload'} placement={'top'}>
            <IconButton size={'small'} component={'label'}>
              <FileUploadOutlined color={'primary'} />
              <input role={'textbox'} type={'file'} hidden onChange={onFileSelected} />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    );
  }

  return (
    <Flex alignItems={'center'} gap={4}>
      {dismissActionsAllowed ? (
        <Button variant={'tertiary'} onClick={dismissAction} color={'neutral'}>
          {dismissCta}
        </Button>
      ) : null}
      {dismissed ? null : (
        <>
          <Button color={'primary'} onClick={onUploadClick}>
            {'Upload'}
          </Button>
          <input role={'textbox'} type={'file'} hidden onChange={onFileSelected} ref={ref} />
        </>
      )}
    </Flex>
  );
};
