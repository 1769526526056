import { atom } from 'recoil';
import { persistAtom } from './localStorageEffect';

const userDataAtom = <T>(name: string, defaultValue: T) =>
  atom<T>({
    key: name,
    default: defaultValue,
    effects: [persistAtom('userData')],
  });

export interface UserState {
  firstName?: string;
  lastName?: string;
  email: string;
  personId?: string;
  type: 'borrower' | 'lender';
}

export interface UserCreationDetails {
  id: string;
  email: string;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  personId: string;
  partner: string;
}

export const accessTokenState = userDataAtom('accessToken', '');

export const userCreationDetailsState = userDataAtom<UserCreationDetails | null>('userCreationDetails', null);
