import type { ReactNode } from 'react';
import React from 'react';
import type { Entity } from '@lama/common-types';
import type { EvaluatedApplicationRequirement, EvaluatedOpportunityRequirement, Person } from '@lama/contracts';
import { keyBy, sortBy } from 'lodash-es';
import {
  businessName as businessNameSelector,
  businessRelationsSelector,
  personFullName,
  personRelationsSelector,
  businessPrimaryRelation,
} from '@lama/selectors';
import type {
  AffiliateApiModel,
  ApplicationApiModel,
  ApplicationRelatedBusinessApiModel,
  ApplicationRelatedPersonApiModel,
  RelatedPersonApiModel,
} from '@lama/clients';
import { capitalCase } from 'change-case-all';
import { BusinessIcon } from '../icons/BusinessIcon';
import { PersonIcon } from '../icons/PersonIcon';

const categoryEntityToPriority: Record<Entity, number> = {
  affiliate: 0,
  person: 1,
  business: 2,
  application: 3,
  opportunity: 4,
};

type EvaluatedRequirement = EvaluatedApplicationRequirement | EvaluatedOpportunityRequirement;

const categoriesSort = <T extends EvaluatedRequirement>(a: RequirementsByCategory<T>, b: RequirementsByCategory<T>) => {
  if (a.categoryShortName === 'Submit') {
    return 1;
  }

  if (b.categoryShortName === 'Submit') {
    return -1;
  }

  const diff = categoryEntityToPriority[b.entityType] - categoryEntityToPriority[a.entityType];
  if (diff === 0) {
    return a.categoryLongName.localeCompare(b.categoryLongName);
  }
  return diff;
};

const ownerCategoryText = ({ firstName, lastName }: Person) => `${firstName} ${lastName[0]}.`;

export interface RequirementsByCategory<T extends EvaluatedRequirement> {
  categoryId: string;
  entityType: Entity;
  entityRelation?: string;
  categoryShortName: string;
  categoryLongName: string;
  categoryIcon?: ReactNode;
  requirements: T[];
}

const getCategoryId = (requirement: EvaluatedRequirement) =>
  requirement.entityType === 'application' ? requirement.category : requirement.entityId;

const getCategoryShortName = (
  requirement: EvaluatedRequirement,
  relatedPeopleById: Record<string, ApplicationRelatedPersonApiModel>,
  businessPeopleById: Record<string, RelatedPersonApiModel>,
  affiliatesById: Record<string, AffiliateApiModel>,
  relatedBusinessesById: Record<string, ApplicationRelatedBusinessApiModel>,
) => {
  if (requirement.entityType === 'person' && (!!relatedPeopleById[requirement.entityId] || !!businessPeopleById[requirement.entityId])) {
    const person = relatedPeopleById[requirement.entityId]?.person ?? businessPeopleById[requirement.entityId];
    return ownerCategoryText(person!) ?? capitalCase(requirement.entityType);
  }

  if (requirement.entityType === 'affiliate' && affiliatesById[requirement.entityId]) {
    return businessNameSelector(affiliatesById[requirement.entityId]!) ?? capitalCase(requirement.entityType);
  }

  if (requirement.entityType === 'business' && relatedBusinessesById[requirement.entityId]) {
    const business = relatedBusinessesById[requirement.entityId]?.business;
    const businessName = business && businessNameSelector(business);

    const businessRelation = businessPrimaryRelation(relatedBusinessesById[requirement.entityId]?.relations ?? []);
    const businessRelationText = businessRelation ? capitalCase(businessRelation) : '';

    return businessName ?? businessRelationText;
  }

  return capitalCase(requirement.category);
};

const getCategoryLongName = (
  requirement: EvaluatedRequirement,
  relatedPeopleById: Record<string, ApplicationRelatedPersonApiModel>,
  businessPeopleById: Record<string, RelatedPersonApiModel>,
  affiliatesById: Record<string, AffiliateApiModel>,
  relatedBusinessesById: Record<string, ApplicationRelatedBusinessApiModel>,
) => {
  if (requirement.entityType === 'person' && (!!relatedPeopleById[requirement.entityId] || !!businessPeopleById[requirement.entityId])) {
    const person = relatedPeopleById[requirement.entityId]?.person ?? businessPeopleById[requirement.entityId];
    return personFullName(person!);
  }

  return getCategoryShortName(requirement, relatedPeopleById, businessPeopleById, affiliatesById, relatedBusinessesById);
};

const getEntityRelation = (requirement: EvaluatedRequirement, application: ApplicationApiModel) => {
  if (requirement.entityType === 'person') {
    return personRelationsSelector(requirement.entityId, application);
  }

  if (requirement.entityType === 'affiliate' || requirement.entityType === 'business') {
    return businessRelationsSelector(requirement.entityId, application);
  }

  return '';
};

const getCategoryIcon = (entityType: Entity) => {
  if (entityType === 'business' || entityType === 'affiliate') {
    return <BusinessIcon />;
  }

  if (entityType === 'person') {
    return <PersonIcon />;
  }

  return null;
};

export const getRequirementsByCategory = <T extends EvaluatedRequirement>(
  requirements: T[],
  application: ApplicationApiModel,
): RequirementsByCategory<T>[] => {
  const allAffiliates = application.relatedBusinesses.flatMap(({ business: { affiliates } }) => affiliates ?? []);

  const businessPeopleById = keyBy(
    application.relatedBusinesses.flatMap(({ business: { people } }) => people),
    ({ id }) => id,
  );
  const relatedPeopleById = keyBy(application.relatedPeople, ({ person: { id } }) => id);
  const affiliatesById = keyBy(allAffiliates, ({ id }) => id);
  const relatedBusinessesById = keyBy(application.relatedBusinesses, ({ business: { id } }) => id);

  return requirements
    .reduce<RequirementsByCategory<T>[]>((acc, req) => {
      const categoryId = getCategoryId(req);

      const existingCategory = acc.find((item) => item.categoryId === categoryId);

      if (existingCategory) {
        existingCategory.requirements.push(req);
      } else {
        const categoryShortName = getCategoryShortName(req, relatedPeopleById, businessPeopleById, affiliatesById, relatedBusinessesById);
        const categoryLongName = getCategoryLongName(req, relatedPeopleById, businessPeopleById, affiliatesById, relatedBusinessesById);
        const entityRelation = getEntityRelation(req, application);
        const categoryIcon = getCategoryIcon(req.entityType);

        acc.push({
          categoryId,
          entityType: req.entityType,
          entityRelation,
          categoryIcon,
          categoryShortName,
          categoryLongName,
          requirements: [req],
        });
      }

      return acc;
    }, [])
    .map((item) => ({
      ...item,
      requirements: sortBy(item.requirements, (requirement) => requirement.viewIndex ?? 1000),
    }))
    .sort(categoriesSort);
};
