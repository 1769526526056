import type { FC } from 'react';
import React, { useMemo, useCallback, useState } from 'react';

export interface ModalText {
  title: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}

const modalDefaultText: ModalText = { title: 'Confirm action', cancelText: 'Cancel', confirmText: 'Confirm' };

export interface ConfirmationModalContextProps {
  confirm: () => void;
  cancel: () => void;
  startConfirmation: () => Promise<boolean>;
  modalOpen: boolean;
  setModalText: (modalText: ModalText) => void;
  modalText: ModalText;
}

export const ConfirmationModalContext = React.createContext<ConfirmationModalContextProps>({
  confirm: () => null,
  cancel: () => null,
  startConfirmation: async () => false,
  modalOpen: false,
  setModalText: () => null,
  modalText: modalDefaultText,
});

interface ConfirmationModalProviderProps {
  children: React.ReactNode;
}

export const ConfirmationModalProvider: FC<ConfirmationModalProviderProps> = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [resolveConfirmation, setResolveConfirmation] = useState<{ resolve: (value: boolean) => void }>();
  const [modalText, setModalText] = useState<ModalText>(modalDefaultText);

  const startConfirmation = useCallback(() => {
    const promise = new Promise<boolean>((resolve) => {
      setResolveConfirmation({ resolve });
    });

    setModalOpen(true);

    return promise;
  }, []);

  const confirm = useCallback(() => {
    setModalOpen(false);

    resolveConfirmation?.resolve(true);
    setResolveConfirmation({ resolve: () => null });

    setModalText(modalDefaultText);
  }, [resolveConfirmation, setModalText]);

  const cancel = useCallback(() => {
    setModalOpen(false);

    resolveConfirmation?.resolve(false);
    setResolveConfirmation({ resolve: () => null });
  }, [resolveConfirmation]);

  const changeModalText = useCallback(
    ({ title, cancelText, confirmText, message }: ModalText) => {
      setModalText({
        title: title || modalDefaultText.title,
        cancelText: cancelText ?? modalDefaultText.cancelText,
        confirmText: confirmText ?? modalDefaultText.confirmText,
        message: message ?? modalDefaultText.message,
      });
    },
    [setModalText],
  );

  const value = useMemo(
    () => ({
      modalOpen,
      startConfirmation,
      confirm,
      cancel,
      setModalText: changeModalText,
      modalText,
    }),
    [cancel, changeModalText, confirm, modalOpen, modalText, startConfirmation],
  );

  return <ConfirmationModalContext.Provider value={value}>{children}</ConfirmationModalContext.Provider>;
};
