import type { FC } from 'react';
import React from 'react';
import { Flex } from '@lama/design-system';
import CollateralEmptyStateImage from './collateralEmptyState.svg';

export const CollateralEmptyState: FC = () => (
  <Flex flexDirection={'column'} alignItems={'center'} gap={4}>
    <CollateralEmptyStateImage />
  </Flex>
);
