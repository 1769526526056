import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import type { ProductPublicData, Screen } from '@lama/contracts';
import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';

import { useAsyncFn } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { displayToast, useConfirmModal } from '@lama/app-components';
import type { PersonCreateApiModel } from '@lama/clients';
import { currentOnboardingApplicationIdState } from '../../state/appState';
import { useStepsUrlNavigation } from '../../hooks/useStepsUrlNavigation';
import { defaultRegistrationScreen, screenMap } from '../screensConfiguration';
import { ErrorScreen } from '../errorScreen/ErrorScreen';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useAuthentication } from '../../hooks/authentication/useAuthentication';
import type { RegistrationScreenProps, UserCreationDetails } from '../ScreenProps';

import { analyticsEvent } from '../../shared/utils/analytics';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { useAddPersonToUserMutation } from '../../hooks/react-query/useAddPersonIdToUserMutation';
import { useCreatePerson } from '../../hooks/react-query/useCreatePerson';
import { useCreateApplicationAndRelatedEntities } from './PreflowScreen/useCreateApplicationAndRelatedEntities';

const skipToRegistrationQueryParam = 'skipToRegistration';

const shouldSkipToRegistrationScreen = (queryParams: Record<string, string | null>) => queryParams[skipToRegistrationQueryParam] === 'true';

const skipToRegistration = (navigate: NavigateFunction, registrationScreen: Screen) => {
  const search = new URLSearchParams(window.location.search);
  search.delete(skipToRegistrationQueryParam);
  navigate({ pathname: `../${registrationScreen}`, search: search.toString() }, { replace: true });
};

const errorMessage = 'There was a problem processing the request. Please try again.';

export const PreFlowScreen: React.FC<{ product: ProductPublicData; partner: PartnerPublicDataApiModel }> = ({ product, partner }) => {
  const {
    screensConfiguration: { preFlowSteps, screenParams, registrationScreen: productRegistrationScreen },
  } = product;
  const registrationScreen = productRegistrationScreen ?? defaultRegistrationScreen;

  const { navigateNext, navigateBack, currentStep } = useStepsUrlNavigation(product);
  const isLastPreFlowStep = useMemo(() => preFlowSteps.indexOf(currentStep) === preFlowSteps.length - 1, [currentStep, preFlowSteps]);
  const { createApplicationAndRelatedEntities, creatingApplicationAndRelatedEntities } = useCreateApplicationAndRelatedEntities(
    partner.id,
    product,
  );
  const queryParams = useQueryParams(['ref', skipToRegistrationQueryParam]);
  const [userCreationDetails, setUserCreationDetails] = useState<(UserCreationDetails & { personId: string }) | null>(null);
  const [shouldNavigateNext, setShouldNavigateNext] = useState(false);
  const { token, registerUser } = useAuthentication();
  const user = useContext(UserDetailsContext);
  const { mutateAsync: addPersonToUser } = useAddPersonToUserMutation(user?.id);
  const [currentOnboardingApplicationId, setCurrentOnboardingApplicationId] = useRecoilState(currentOnboardingApplicationIdState);
  const navigate = useNavigate();
  const { confirm } = useConfirmModal();
  const { mutateAsync: createPerson, isPending: creatingPerson } = useCreatePerson();

  const [{ loading: creatingUser }, createUser] = useAsyncFn(
    async (details: UserCreationDetails) => {
      const { firstName, middleName, lastName, email, phoneNumber } = details;

      if (email.endsWith('@lama.ai') && partner.status === 'production') {
        const confirmed = await confirm({
          title: 'Are you sure?',
          message: 'You are trying to create a production application with a lama.ai email address. Are you sure you want to continue?',
        });

        if (!confirmed) {
          return false;
        }
      }

      if (user) {
        const personId = uuidv4();
        const {
          firstName: existingUserFirstName,
          lastName: existingUserLastName,
          email: existingUserEmail,
          phoneNumber: existingPhoneNumber,
        } = user;
        const personToCreate: PersonCreateApiModel = {
          id: personId,
          firstName: existingUserFirstName,
          lastName: existingUserLastName,
          email: existingUserEmail,
          phoneNumber: existingPhoneNumber,
        };

        await createPerson({ person: personToCreate });
        await addPersonToUser(personId);

        setUserCreationDetails({ ...details, personId });
        return true;
      }

      try {
        const initiatorPersonId = uuidv4();
        const initiatorUserId = uuidv4();
        const referrerId = queryParams?.ref ?? undefined;
        await registerUser({
          id: initiatorUserId,
          email,
          firstName,
          middleName,
          lastName,
          userPersonId: initiatorPersonId,
          phoneNumber,
          referrerId,
        });
        setUserCreationDetails({ ...details, personId: initiatorPersonId });

        return true;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 409) {
          navigate(`/login?email=${encodeURIComponent(email)}&knownUser=true`);
          return false;
        }
        displayToast(errorMessage, 'error');
        return false;
      }
    },
    [addPersonToUser, confirm, createPerson, navigate, partner.status, queryParams?.ref, registerUser, user],
  );

  const onNextClick = useCallback(() => {
    setShouldNavigateNext(true);
  }, []);

  const [{ loading: handlingNavigateNext }, handleNavigateNext] = useAsyncFn(async () => {
    if (isLastPreFlowStep && userCreationDetails && token && !currentOnboardingApplicationId) {
      const applicationId = await createApplicationAndRelatedEntities(userCreationDetails.personId);
      setCurrentOnboardingApplicationId(applicationId);

      analyticsEvent({
        action: 'application_started',
        category: 'conversion',
        params: {
          partnerId: partner.id,
          partnerStatus: partner.status,
          userId: user?.id ?? '',
        },
      });
    }

    navigateNext();
  }, [
    isLastPreFlowStep,
    userCreationDetails,
    token,
    currentOnboardingApplicationId,
    navigateNext,
    createApplicationAndRelatedEntities,
    setCurrentOnboardingApplicationId,
    partner.id,
    partner.status,
    user?.id,
  ]);

  useEffect(() => {
    if (shouldNavigateNext && !creatingApplicationAndRelatedEntities) {
      void handleNavigateNext();
      setShouldNavigateNext(false);
    }
    // can't add handleNavigateNext to the dependencies array because it will cause an infinite loop
    // this is probably a memoization issue in the useAsyncFn hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNavigateNext]);

  useEffect(() => {
    if (shouldSkipToRegistrationScreen(queryParams)) {
      skipToRegistration(navigate, registrationScreen);
    }
  }, [currentStep, navigate, queryParams, registrationScreen]);

  if (!preFlowSteps.includes(registrationScreen)) {
    console.error(
      `Pre-flow screens are missing the registration screen - ${registrationScreen}. Please add it to the preFlowSteps array in the product configuration.`,
    );

    return <ErrorScreen />;
  }

  const Component = screenMap[currentStep as Screen];

  if (currentStep === registrationScreen) {
    const RegistrationComponent = Component as React.FC<RegistrationScreenProps>;

    return (
      <RegistrationComponent
        {...screenParams[currentStep]}
        flow={'onboarding'}
        onNextClick={onNextClick}
        onBackClick={navigateBack}
        nextEnabled
        nextLoading={creatingUser || creatingApplicationAndRelatedEntities || handlingNavigateNext || creatingPerson}
        submitUser={createUser}
      />
    );
  }

  return (
    <Component
      {...screenParams[currentStep]}
      flow={'onboarding'}
      onNextClick={onNextClick}
      onBackClick={navigateBack}
      nextEnabled
      nextLoading={creatingApplicationAndRelatedEntities || handlingNavigateNext}
    />
  );
};
