import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AffiliateApiModel } from '@lama/clients';
import { useToggle } from 'react-use';
import { Flex } from '@lama/design-system';
import { ModifyItemButton } from '@lama/app-components';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useGetCurrentRequirement } from '../../hooks/useGetCurrentRequirement';
import { NoOwnerImage } from '../shared/NoOwnerImage';
import { useUpdateBusiness } from '../../hooks/react-query/useUpdateBusiness';
import { AddOrEditAffiliateDialog } from './AddOrEditAffiliateDialog';
import { AffiliateListItem } from './AffiliateListItem';

interface OwnersScreenProps extends ScreenProps {
  title?: string;
}

export const AffiliatesScreen: React.FC<OwnersScreenProps> = ({
  flow,
  onNextClick,
  onBackClick,
  nextLoading,
  title,
  ...stepsNavigationProps
}) => {
  const { application } = useContext(ApplicationContext);
  const requirement = useGetCurrentRequirement();
  const business = useMemo(
    () => application.relatedBusinesses.find(({ business: { id } }) => id === requirement?.entityId)?.business,
    [application.relatedBusinesses, requirement?.entityId],
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialDialogValues, setInitialDialogValues] = useState<AffiliateApiModel | null>(null);

  const { t } = useTranslation();
  const { mutateAsync: updateBusiness, isPending: updatingBusiness } = useUpdateBusiness(application.id);
  const currentRequirement = useGetCurrentRequirement();
  const [noRelatedCompanies, toggleNoRelatedCompanies] = useToggle(!!business?.markedNoRelatedCompanies);

  const relatedBusinesses = useMemo(
    () => business?.affiliates?.filter(({ ownershipPercentage }) => !ownershipPercentage) ?? [],
    [business?.affiliates],
  );

  const onAddAffiliateClick = useCallback(() => {
    setInitialDialogValues(null);
    setDialogOpen(true);
  }, []);

  const addAffiliate = useCallback(
    async (affiliate: AffiliateApiModel) => {
      if (!business) {
        return;
      }

      const updatedAffiliates = [...(business.affiliates ?? []), affiliate];
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { affiliates: updatedAffiliates } });
    },
    [business, updateBusiness],
  );

  const editAffiliate = useCallback(
    async (updatedAffiliate: AffiliateApiModel) => {
      if (!business) {
        return;
      }

      const updatedAffiliates = business.affiliates?.map((affiliate) =>
        affiliate.id === updatedAffiliate.id ? updatedAffiliate : affiliate,
      );
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { affiliates: updatedAffiliates } });
    },
    [business, updateBusiness],
  );

  const onEditAffiliateCard = useCallback(
    (id: string) => {
      if (!business) {
        return;
      }

      setInitialDialogValues(business.affiliates?.find((affiliate) => affiliate.id === id) ?? null);
      setDialogOpen(true);
    },
    [business],
  );

  const onDeleteAffiliateCard = useCallback(
    async (id: string) => {
      if (!business) {
        return;
      }

      const filteredAffiliates = business.affiliates?.filter((affiliate) => affiliate.id !== id);
      await updateBusiness({ businessId: business.id, updateBusinessPayload: { affiliates: filteredAffiliates } });
    },
    [business, updateBusiness],
  );

  const onNoRelatedCompaniesMarkClick = useCallback(async () => {
    if (!business) {
      return;
    }

    toggleNoRelatedCompanies();
    await updateBusiness({ businessId: business.id, updateBusinessPayload: { markedNoRelatedCompanies: !noRelatedCompanies } });
  }, [business, toggleNoRelatedCompanies, updateBusiness, noRelatedCompanies]);

  const handleClose = useCallback(
    async (affiliate: AffiliateApiModel | null) => {
      if (affiliate && business) {
        await (business.affiliates?.some((s) => s.id === affiliate.id) ? editAffiliate(affiliate) : addAffiliate(affiliate));
      }
      setDialogOpen(false);
    },
    [addAffiliate, business, editAffiliate],
  );

  return (
    <BasicScreen
      {...stepsNavigationProps}
      flow={flow}
      title={title?.length ? title : currentRequirement?.name ?? t('affiliates.title')}
      subtitle={currentRequirement?.description ?? t('affiliates.subtitle')}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
      nextLoading={updatingBusiness || nextLoading}
      nextVisible={flow === 'onboarding'}
    >
      <Flex flexDirection={'column'} alignItems={'center'} flex={1} gap={8}>
        {business
          ? relatedBusinesses?.map((affiliate) => (
              <AffiliateListItem
                key={affiliate.id}
                business={business}
                affiliate={affiliate}
                onDelete={onDeleteAffiliateCard}
                onEdit={onEditAffiliateCard}
              />
            ))
          : null}
        {!relatedBusinesses?.length ? <NoOwnerImage /> : null}
        <ModifyItemButton onClick={onAddAffiliateClick} text={t('affiliates.cta.add')} disabled={noRelatedCompanies} variant={'text'} />
        {!relatedBusinesses?.length ? (
          <FormControlLabel
            label={'No related companies'}
            control={<Checkbox checked={noRelatedCompanies} onChange={onNoRelatedCompaniesMarkClick} />}
          />
        ) : null}
      </Flex>
      {business ? (
        <AddOrEditAffiliateDialog
          business={business}
          open={dialogOpen}
          handleClose={handleClose}
          initialValues={initialDialogValues}
          isLoading={updatingBusiness}
        />
      ) : null}
    </BasicScreen>
  );
};
