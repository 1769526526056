import type { FC } from 'react';
import React from 'react';

import { DeleteForever } from '@mui/icons-material';

import type { DocumentWithIssues } from '@lama/document-service-client';

import { Flex, Text, greyPalette } from '@lama/design-system';
import { StyledText } from './NoDocument.js';
import { DocumentActions } from './DocumentActions.js';

interface DeletedDocumentProps {
  document: DocumentWithIssues;
  onDownloadDocument?: (e: React.MouseEvent) => void;
}

export const DeletedDocument: FC<DeletedDocumentProps> = ({ document, onDownloadDocument }) => (
  <Flex gap={2} alignItems={'center'} width={'100%'} minWidth={0}>
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      minWidth={'34px'}
      minHeight={'34px'}
      width={'34px'}
      height={'34px'}
      borderRadius={'4px'}
    >
      <DeleteForever sx={{ color: greyPalette[300] }} />
    </Flex>
    <Flex alignItems={'center'} gap={1} minWidth={0}>
      <Text variant={'body1'} color={greyPalette[500]} ellipsis>
        {document.filename}
      </Text>
      <Text variant={'body1'} color={greyPalette[500]} ellipsis>
        {' - '}
      </Text>
      {document.description ? (
        <>
          <Text variant={'body1'} color={greyPalette[500]} ellipsis>
            {document.description}
          </Text>
          <Text variant={'body1'} color={greyPalette[500]} ellipsis>
            {' - '}
          </Text>
        </>
      ) : null}
      <StyledText variant={'body1'} color={greyPalette[400]}>
        {'Deleted'}
      </StyledText>
    </Flex>
    <Flex justifyContent={'flex-end'} flex={1}>
      {onDownloadDocument ? <DocumentActions onDownloadDocument={onDownloadDocument} /> : null}
    </Flex>
  </Flex>
);
