import { ArrowForwardRounded } from '@mui/icons-material';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@lama/design-system';
import { personFullName } from '@lama/selectors';
import { useTheme as useStyledComponentsTheme } from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicationSummaryBar } from '../shared/ApplicationSummaryBar';
import { GradientProgress } from '../shared/GradientProgress';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { UserDetailsContext } from '../../shared/contexts/UserDetailsContext';
import { getUnfulfilledRequirementsCount } from '../../shared/utils/requirementUtils';
import { RequirementsToggle } from './RequirementsToggle';

export interface OverviewHeaderProps {
  progress: number;
  showFulfilled: boolean;
  toggleTaskView: (event: React.MouseEvent<HTMLElement>, value: string | null) => void;
}

const Title: FC<Pick<OverviewHeaderProps, 'progress'>> = ({ progress }) => {
  const { enableSubmitBanner } = useFlags();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userDetails = useContext(UserDetailsContext);
  const fullName = useMemo(() => {
    if (!userDetails?.firstName || !userDetails?.lastName) {
      return '';
    }

    const { firstName, lastName } = userDetails;

    return personFullName({ firstName, lastName });
  }, [userDetails]);

  const status = progress === 100 ? 'completed' : 'inProgress';

  const oldTitle = (
    <Text
      {...(status === 'completed' ? { variant: isMobile ? 'h5' : 'h4' } : { variant: 'h6', color: 'text.secondary' })}
      textAlign={isMobile ? 'center' : 'left'}
    >
      {t(`overview.${status}.oldTitle`, { name: fullName })}
    </Text>
  );

  const newTitle = (
    <>
      <Text variant={'h6'} color={'text.secondary'} textAlign={isMobile ? 'center' : 'left'}>
        {t('overview.title', { name: fullName })}
      </Text>
      {progress === 100 ? (
        <Text variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
          {t('overview.completed.title')}
        </Text>
      ) : null}
    </>
  );

  return enableSubmitBanner ? newTitle : oldTitle;
};

const Subtitle: FC = () => {
  const { t } = useTranslation();
  const { requirements } = useContext(ApplicationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const unfulfilledRequirementsCount = useMemo(() => getUnfulfilledRequirementsCount(requirements), [requirements]);

  return (
    <Flex justifyContent={isMobile ? 'center' : 'space-between'} gap={12}>
      <Text variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
        {'You have '}
        <Text variant={isMobile ? 'h5' : 'h4'} color={'primary.main'}>
          {t('overview.inProgress.task', { count: unfulfilledRequirementsCount })}
        </Text>
        {` ${t('overview.inProgress.subtitle')}`}
      </Text>
    </Flex>
  );
};

const SimplifiedApplicationSummaryBar: FC = () => (
  <Flex justifyContent={'center'}>
    <ApplicationSummaryBar showBusinessName={false} />
  </Flex>
);

const Progress: FC<Pick<OverviewHeaderProps, 'progress'>> = ({ progress }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const status = progress === 100 ? 'completed' : 'inProgress';

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-between'} alignItems={'center'} gap={2} width={'100%'}>
      <GradientProgress progress={progress} />
      {isMobile ? (
        <Flex justifyContent={'space-between'} width={'100%'}>
          <Text variant={'body3'}>{'0%'}</Text>
          <Text variant={'body3'} color={'primary'}>{`${progress}%`}</Text>
        </Flex>
      ) : (
        <Text variant={'body1'} noWrap>
          {t(`overview.${status}.progress`, { progress })}
        </Text>
      )}
    </Flex>
  );
};

const SubmitNote: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const styledComponentsTheme = useStyledComponentsTheme();
  const navigate = useNavigate();

  const navigateToSubmit = useCallback(() => {
    navigate('../sign-and-submit');
  }, [navigate]);

  return (
    <Flex
      justifyContent={'space-between'}
      gap={2}
      alignItems={'center'}
      border={'1px solid'}
      borderColor={styledComponentsTheme.colors.primary.main}
      borderRadius={'4px'}
      px={4}
      py={2}
    >
      <Text variant={'body2'} textAlign={isMobile ? 'center' : 'left'}>
        {t('overview.completed.submitNote')}
      </Text>
      <Button variant={'text'} onClick={navigateToSubmit} endIcon={<ArrowForwardRounded />}>
        {t('overview.completed.submit')}
      </Button>
    </Flex>
  );
};

const ToggleButton: FC<Pick<OverviewHeaderProps, 'showFulfilled' | 'toggleTaskView'>> = ({ showFulfilled, toggleTaskView }) => (
  <Flex flexDirection={'column'}>
    <RequirementsToggle showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
  </Flex>
);

const OverviewHeaderMobileLayout: FC<OverviewHeaderProps> = ({ progress, showFulfilled, toggleTaskView }) => (
  <>
    <SimplifiedApplicationSummaryBar />
    <Title progress={progress} />
    {progress < 100 ? (
      <>
        <Subtitle />
        <Progress progress={progress} />
        <ToggleButton showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
      </>
    ) : (
      <SubmitNote />
    )}
  </>
);

const NewOverviewHeaderMobileLayout: FC<OverviewHeaderProps> = ({ progress, showFulfilled, toggleTaskView }) => (
  <>
    <SimplifiedApplicationSummaryBar />
    <Title progress={progress} />
    {progress < 100 ? (
      <>
        <Subtitle />
        <Progress progress={progress} />
        <ToggleButton showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
      </>
    ) : (
      <Progress progress={progress} />
    )}
  </>
);

const OverviewHeaderDesktopLayout: FC<OverviewHeaderProps> = ({ progress, showFulfilled, toggleTaskView }) => (
  <>
    <Title progress={progress} />
    {progress < 100 ? (
      <>
        <Flex justifyContent={'space-between'} gap={2}>
          <Subtitle />
          <ToggleButton showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
        </Flex>
        <Progress progress={progress} />
      </>
    ) : (
      <SubmitNote />
    )}
  </>
);

const NewOverviewHeaderDesktopLayout: FC<OverviewHeaderProps> = ({ progress, showFulfilled, toggleTaskView }) => (
  <>
    <Title progress={progress} />
    {progress < 100 ? (
      <Flex justifyContent={'space-between'} gap={2}>
        <Subtitle />
        <ToggleButton showFulfilled={showFulfilled} toggleTaskView={toggleTaskView} />
      </Flex>
    ) : null}
    <Progress progress={progress} />
  </>
);

export const OverviewHeader: FC<OverviewHeaderProps> = (props) => {
  const { enableSubmitBanner } = useFlags();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  let layout;
  if (enableSubmitBanner) {
    layout = isMobile ? <NewOverviewHeaderMobileLayout {...props} /> : <NewOverviewHeaderDesktopLayout {...props} />;
  } else {
    layout = isMobile ? <OverviewHeaderMobileLayout {...props} /> : <OverviewHeaderDesktopLayout {...props} />;
  }

  return (
    <Flex flexDirection={'column'} gap={4} width={'100%'}>
      {layout}
    </Flex>
  );
};
