/* eslint-disable react/no-array-index-key */
import React, { useCallback, useContext, useMemo } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import * as selectors from '@lama/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flex } from '@lama/design-system';
import { formatValue } from '@lama/data-formatters';
import { useTranslation } from 'react-i18next';
import type { ScreenProps } from '../ScreenProps';
import { BasicScreen } from '../shared/BasicScreen';
import { analyticsEvent } from '../../shared/utils/analytics';
import { ApplicationContext } from '../../shared/contexts/ApplicationContext';
import { useSubmitApplicationMutation } from '../../hooks/react-query/useSubmitApplicationMutation';
import { SummaryCard } from './SummaryCard';
import { DollarIcon } from './DollarIcon';
import { LoanTypeIcon } from './LoanTypeIcon';
import { BusinessIcon } from './BusinessIcon';

type ApplicationSubmissionScreenProps = ScreenProps & {
  cards?: string[];
};

const sendDemoTwilioFlow = async (targetPhoneNumber: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  myHeaders.append('Authorization', 'Basic QUM0NTI5N2VhZWRiMjEzZWIxMTgyNWJkY2UyMTUyOWU2ZDo3OTBlOWZkOTNiMTNhNDVhNTUzZGEwZTIwMzUzMGEwOA==');

  const urlencoded = new URLSearchParams();
  urlencoded.append('To', targetPhoneNumber);
  urlencoded.append('From', '+13158733245');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow' as const,
  };

  try {
    await fetch('https://studio.twilio.com/v2/Flows/FW7393ccfe15f8224d60799d0f629e7514/Executions', requestOptions);
  } catch (error) {
    console.error(error);
  }
};

export const ApplicationSubmissionScreen: React.FC<ApplicationSubmissionScreenProps> = ({
  cards = ['borrowers', 'loanType', 'loanAmount'],
  onNextClick = () => {},
  onBackClick,
  nextLoading,
  ...stepsNavigationProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { application, product } = useContext(ApplicationContext);
  const { mutate: submitApplication, isPending: submittingApplication } = useSubmitApplicationMutation(application.id);
  const { enableAutoMoveToApplication, enableHiddenTwilioFlow, targetPhoneNumber } = useFlags();
  const { t } = useTranslation();

  const submit = useCallback(async () => {
    analyticsEvent({
      action: 'onboarding_completed',
      category: 'conversion',
    });
    if (!enableAutoMoveToApplication) {
      submitApplication();
    }
    onNextClick();
  }, [enableAutoMoveToApplication, onNextClick, submitApplication]);

  const borrowersCard = useMemo(
    () => ({ Icon: BusinessIcon, text1: selectors.loanDisplayNameSelector(application) || 'N/A', text2: 'Borrower(s)' }),
    [application],
  );
  const loanTypeCard = useMemo(
    () => ({
      Icon: LoanTypeIcon,
      text1: product?.prettyName ?? '',
      text2: 'Loan Type',
      onClick: enableHiddenTwilioFlow
        ? () => {
            void sendDemoTwilioFlow(String(targetPhoneNumber));
          }
        : undefined,
    }),
    [product?.prettyName, enableHiddenTwilioFlow, targetPhoneNumber],
  );
  const loanAmountCard = useMemo(
    () => ({ Icon: DollarIcon, text1: formatValue(application.requestedAmount ?? 0, 'currencyCompact'), text2: 'Loan Amount' }),
    [application.requestedAmount],
  );

  const summaryCards: { Icon: React.FC; text1: string; text2: string; onClick?: () => void }[] = useMemo(
    () => [
      ...(cards.includes('borrowers') ? [borrowersCard] : []),
      ...(cards.includes('loanType') ? [loanTypeCard] : []),
      ...(cards.includes('loanAmount') ? [loanAmountCard] : []),
    ],
    [borrowersCard, cards, loanAmountCard, loanTypeCard],
  );

  return (
    <BasicScreen
      {...stepsNavigationProps}
      title={t('applicationSubmission.title')}
      subtitle={t('applicationSubmission.subtitle')}
      onNextClick={submit}
      nextButtonText={t('applicationSubmission.cta')}
      nextLoading={submittingApplication || nextLoading}
      onBackClick={onBackClick}
    >
      <Flex flexDirection={isMobile ? 'column' : 'row'} gap={6} justifyContent={'center'}>
        {summaryCards.map(({ Icon, text1, text2, onClick }, index) => (
          <SummaryCard isMobile={isMobile} Icon={Icon} text1={text1} text2={text2} key={`summary_card_${index}`} onClick={onClick} />
        ))}
      </Flex>
    </BasicScreen>
  );
};
