import React from 'react';
import type { ProductPublicData, Screen } from '@lama/contracts';
import type { ApplicationApiModel } from '@lama/clients';
import { Navigate } from 'react-router-dom';
import { LoadingPage } from '@lama/app-components';
import { useStepsUrlNavigation } from '../../hooks/useStepsUrlNavigation';
import { screenMap } from '../screensConfiguration';
import { ApplicationProvider } from '../../shared/contexts/ApplicationContext';
import { useApplicationRequirementsQuery } from '../../hooks/react-query/useApplicationRequirementsQuery';
import { isOrAfterApplicationStatus } from '../../shared/utils/isAfterApplicationStatus';
import { ErrorScreen } from '../errorScreen/ErrorScreen';

export const FlowScreen: React.FC<{ application: ApplicationApiModel; product: ProductPublicData }> = ({ application, product }) => {
  const {
    screensConfiguration: { screenParams },
    onboardingRequirements,
  } = product;

  const { data: requirements, isPending: loadingRequirements } = useApplicationRequirementsQuery(application.id);
  const { navigateNext, navigateBack, currentStep } = useStepsUrlNavigation(product, application);

  const currentScreenName: Screen = screenMap[currentStep as Screen]
    ? (currentStep as Screen)
    : onboardingRequirements?.some((r) => r.screen === currentStep)
    ? 'genericForm'
    : 'getStarted';

  const Component = screenMap[currentScreenName];

  if (loadingRequirements) {
    return <LoadingPage />;
  }

  if (isOrAfterApplicationStatus(application.status, 'InReview')) {
    return <Navigate to={`/applications/${application.id}/overview`} />;
  }

  if (!requirements) {
    return <ErrorScreen />;
  }

  return (
    <ApplicationProvider application={application} product={product} requirements={requirements}>
      <Component {...screenParams[currentStep]} flow={'onboarding'} onNextClick={navigateNext} onBackClick={navigateBack} nextEnabled />
    </ApplicationProvider>
  );
};
